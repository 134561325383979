export class SelectOption {
    /**
     * 
     * @param {string} code 
     * @param {string} description 
     */
    constructor(code='', description='') {
        /** @type {string} */ this.code = code;
        /** @type {string} */ this.description = description;
    }
}
export class SelectProps {
    constructor() {
        /** @type {SelectOption} */ this.selectedOption = new SelectOption();
        /** @type {SelectOption[]} */ this.selectOptions = [];
        /** @type {boolean} */ this.isShow = false;
        /** @type {(s:string)=>void} */  this.onSelect = null;
    }
}