import React from 'react';
import texts from './HomeTexts';
import pStyles from './Home.module.css';
import gStyles from '../../App.module.css';
import '../../resources/sprites.css';
import * as icon from '../../util/IconConstants';

export const Section = {
    CREATE: 'create',
    SEARCH: 'search',
    VIEW: 'view',
    SETUP: 'setup',
    INFO: 'info'
}

// Home Page View - must be stateless

/**
 * @typedef {Object} Props
 * @property { import('./HomeState').Expanded } expanded
 * @property { ()=>void} onClickCreate
 * @property { ()=>void} onClickSearch
 * @property { ()=>void} onClickByCategory
 * @property { ()=>void} onClickByExpertise
 * @property { ()=>void} onClickByImportance
 * @property { ()=>void} onClickByLabel
 * @property { ()=>void} onClickGotoApps 
 * @property { ()=>void} onClickSetupCategories
 * @property { ()=>void} onClickSetupLabels
 * @property { ()=>void} onClickSettings
 * @property { ()=>void} onClickAbout
 * @property { ()=>void} onClickHowToUse
 * @property { ()=>void} onClickTipsTricks
 * @property { (section:string)=>void} onToggleSection
 */

/**@param {Props} props */
export const HomeView = (props) => (
    <main className={[gStyles.colFlex, pStyles.main].join(' ')}>
        {/* create */}
        <div>
            <header className={[gStyles.rowNowrapFlex, pStyles.header].join(' ')} onClick={() => props.onToggleSection(Section.CREATE)}>
                <button className={[icon.HOME_NEW, gStyles.btnImg].join(' ')}></button>
                <span style={{ marginLeft: '15px' }}> {texts.createHeader} </span>
                <button className={[props.expanded.create ? icon.COLLAPSE : icon.EXPAND, gStyles.btnIcon, gStyles.splitRight].join(' ')}></button>
            </header>
            <hr />
            <section className={pStyles.list} style={{ display: props.expanded.create ? 'block' : 'none' }}>
                <div className={pStyles.listItem} onClick={props.onClickCreate}>{texts.iWantTo} {' '} <span className={gStyles.link} >{texts.createInfo}</span> </div>
            </section>
        </div>

        {/* search */}
        <div>
            <header className={[gStyles.rowNowrapFlex, pStyles.header].join(' ')} onClick={() => props.onToggleSection(Section.SEARCH)}>
                <button className={[icon.HOME_SEARCH, gStyles.btnImg].join(' ')}></button>
                <span style={{ marginLeft: '15px' }}> {texts.searchHeader} </span>
                <button className={[props.expanded.search ? icon.COLLAPSE : icon.EXPAND, gStyles.btnIcon, gStyles.splitRight].join(' ')}></button>
            </header>
            <hr />
            <section className={pStyles.list} style={{ display: props.expanded.search ? 'block' : 'none' }}>
                <div className={pStyles.listItem} onClick={props.onClickSearch}>{texts.iWantTo} {' '} <span className={gStyles.link} >{texts.searchInfo}</span> </div>
            </section>
        </div>

        {/* views */}
        <div>
        <header className={[gStyles.rowNowrapFlex, pStyles.header].join(' ')} onClick={()=>props.onToggleSection(Section.VIEW)}>
            <button className={[icon.HOME_VIEW,gStyles.btnImg].join(' ')}></button>
            <span style={{marginLeft:'15px'}}> {texts.viewHeader} </span>
            <button className={[props.expanded.view?icon.COLLAPSE:icon.EXPAND, gStyles.btnIcon, gStyles.splitRight].join(' ')}></button>
        </header>
        <hr />
        <section className={pStyles.list} style={{ display:  props.expanded.view ? 'block' : 'none' }}>

            <div onClick={props.onClickByCategory}   
                className={pStyles.listItem}>{texts.iWantToView} {' '} <span className={gStyles.link} >{texts.byCategory}</span> </div>
            
            <div onClick={props.onClickByImportance} 
                className={pStyles.listItem}>{texts.iWantToView} {' '} <span className={gStyles.link} >{texts.byImportance}</span> 
            </div>

            {/* todo: change to view by expertise */}
            <div onClick={props.onClickByExpertise}  
                className={pStyles.listItem}>{texts.iWantToView} {' '} <span className={gStyles.link} >{texts.byExpertise}</span> </div>
            
            <div  onClick={props.onClickByLabel} 
                className={pStyles.listItem}>{texts.iWantToView} {' '} <span className={gStyles.link} >{texts.byLabel}</span> </div>
                
        </section>
        </div>

        {/* switch app */}
        <div>
            <header className={[gStyles.rowNowrapFlex, pStyles.header].join(' ')} onClick={() => props.onToggleSection(Section.APPS)}>
                <button className={[icon.HOME_APPS, gStyles.btnImg].join(' ')}></button>
                <span style={{ marginLeft: '15px' }}> {texts.appsHeader} </span>
                <button className={[props.expanded.apps ? icon.COLLAPSE : icon.EXPAND, gStyles.btnIcon, gStyles.splitRight].join(' ')}></button>
            </header>
            <hr />
            <section className={pStyles.list} style={{ display: props.expanded.apps ? 'block' : 'none' }}>
                <div className={pStyles.listItem} onClick={props.onClickGotoApps}>{texts.iWantTo} {' '} <span className={gStyles.link} >{texts.gotoApps}</span> </div>
            </section>
        </div>



        {/* setup */}
        <div>
        <header className={[gStyles.rowNowrapFlex, pStyles.header].join(' ')} onClick={()=>props.onToggleSection(Section.SETUP)}>
            <button className={[icon.HOME_SETTINGS,gStyles.btnImg].join(' ')}></button>
            <span  style={{marginLeft:'15px'}}> {texts.setupHeader} </span>
            <button className={[props.expanded.setup?icon.COLLAPSE:icon.EXPAND, gStyles.btnIcon, gStyles.splitRight].join(' ')}></button>
        </header>
        <hr />
        <section className={pStyles.list} style={{ display:  props.expanded.setup ? 'block' : 'none' }}>
            <div onClick={props.onClickSetupCategories} className={pStyles.listItem}>{texts.iWantToSetup} {' '} <span className={gStyles.link} >{texts.infoCategories}</span> </div>
            <div onClick={props.onClickSetupLabels} className={pStyles.listItem}>{texts.iWantToSetup} {' '} <span className={gStyles.link} >{texts.infoLabels}</span> </div>
            <div onClick={props.onClickSettings} className={pStyles.listItem}>{texts.iWantToSetup} {' '} <span className={gStyles.link} >{texts.systemSettings}</span> </div>
        </section>
        </div>

        {/* info */}
        <div>
        <header className={[gStyles.rowNowrapFlex, pStyles.header].join(' ')} onClick={()=>props.onToggleSection(Section.INFO)}>
            <button className={[icon.HOME_INFO,gStyles.btnImg].join(' ')}></button>
            <span style={{marginLeft:'15px'}}> {texts.infoHeader} </span>
            <button className={[props.expanded.info?icon.COLLAPSE:icon.EXPAND, gStyles.btnIcon, gStyles.splitRight].join(' ')}></button>
        </header>
        <hr />
        <section className={pStyles.list} style={{ display:  props.expanded.info ? 'block' : 'none' }}>
            <div onClick={props.onClickAbout} className={pStyles.listItem}>{texts.iWantToKnow} {' '} <span className={gStyles.link} >{texts.about}</span> </div>
            <div onClick={props.onClickHowToUse} className={pStyles.listItem}>{texts.iWantToKnow} {' '} <span className={gStyles.link} >{texts.howToUse}</span> </div>
            <div onClick={props.onClickTipsTricks} className={pStyles.listItem}>{texts.iWantToKnow} {' '} <span className={gStyles.link} >{texts.tipsTricks}</span> </div>
        </section>
        </div>

    </main>
);


