import React from 'react'
import gStyles from '../../App.module.css';
import styles from './Info.module.css';
import * as size from '../../util/SizeConstants';
import * as icon from '../../util/IconConstants';
import texts from './AboutTexts'
import { goBack } from '../../navigation/PageManager'

export default class HowTo extends React.Component {

    onBack = () => {
        goBack()
    }

    render() {
        return (
            <>
                <nav className={[gStyles.colFlex, gStyles.navBar].join(' ')}>

                    {/* horizontal menu */}
                    <div className={[gStyles.rowNowrapFlex].join(' ')}>

                        {/* back button */}
                        <span style={{ paddingLeft: size.NORMAL_SPACER }}>
                            <button onClick={this.onBack} className={[icon.BACK_WHITE, gStyles.btnIcon].join(' ')}></button>
                        </span>

                        {/* title */}
                        <span style={{ paddingLeft: size.NORMAL_SPACER, whiteSpace: 'nowrap' }}>{texts.howToTitle}</span>


                    </div>

                </nav>
                <main className={[styles.mainContent].join(' ')}
                    dangerouslySetInnerHTML={{__html: 
                        `
                        <p>
                        <span style='font-weight:600'>MyBrainMate Info</span> is a tool to build your personal knowledge database
                        <p>                        
                        It helps you store and organize information that are important to you
                        <p style='font-weight:600; font-size:18px'>                         
                        What info to store
                        </p>
                        <div style='padding-left:20px'>
                        <p>-	Lessons in life
                        <p>-	Favorites
                        <p>-	Instructions
                        <p>-	Lists
                        <p>-	People
                        <p>-	General Knowledge
                        <p>-	Personal info
                        <p>-	Health info
                        <p>-	Passwords
                        <p>-	Jokes
                        <p>-	Quotes
                        </div>
                        You’re only limited by your imagination on what to store to <span style='font-weight:600'>MyBrainMate Info</span>
                        <p style='font-weight:600; font-size:18px'>                                 
                        How to Use
                        </p>
                        <p> 
                        Generally, there are two ways to use <span style='font-weight:600'>MyBrainMate Info</span>
                        <div style='padding-left:20px'>
                        <p>1.	Create info
                        <p>2.	View info
                        </div>
                        <p style='font-weight:600; font-size:18px'>                         
                        Create Info
                        </p>
                        <p>
                        When you create info, you can specify the following attributes
                        <div style='padding-left:20px'>
                        <p>-	Name
                        <p>-	Category
                        <p>-	Importance
                        <p>-	Expertise
                        <p>-	Start/End Dates
                        <p>-	Labels
                        </div> 
                        <p>
                        These attributes add value and meaning to your info
                        <p style='font-weight:600; font-size:18px'>                            
                        View Info
                        <p> 
                        A view shows your info in a specific manner. You can think of views as different angles of looking at the same info. Each angle gives you a different perspective of your info
                        <p> 
                        There are several ways of viewing your info
                        <div style='padding-left:20px'> 
                        <p>1.	View by Category
                         
                        <p>show info in hierarchy giving you an overview of your knowledge database
                         
                        <p>2.	View by Importance
                         
                        <p>show info based on their importance to you
                         
                        <p>3.	View by Expertise
                         
                        <p>show info according to your level of expertise
                         
                        <p>4.	View by Label
                         
                        <p>show info by the tags you set them, it gives you cross-sectional view of your info
                        </div>                                                                                     
                        `
                    }}
                >
                </main>
            </>




        )
    }

}