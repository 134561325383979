
import * as InfoConstants from '../../util/InfoConstants'

export class Fields {
    /**@type {string} */ cellType = '';
    /**@type {string} */ cellId = '';
    /**@type {string} */ name = '';
    /**@type {string} */ categoryId = '';
    /**@type {string} */ importance = InfoConstants.Importance.NORMAL;
    /**@type {string} */ expertise = InfoConstants.Expertise.NOT_APPLICABLE;
    /**@type {string} */ startDate = '';
    /**@type {string} */ endDate = '';
    /**@type {string} */ notes = '';
    /**@type {string[]} */ labels = [];


}

export class DateSelector {
    /**@type {string} */ startDate = InfoConstants.DateSelector.NONE;
    /**@type {string} */ endDate = InfoConstants.DateSelector.NONE;
}

export class Category {
    /**@type {string} */ id = '';
    /**@type {string} */ name = '';
}

export class Label {
    /**@type {string} */ id = '';
    /**@type {string} */ name = '';
    /**@type {boolean} */ isChecked = false;
}

export class Errors {
    /**@type {string} */ name = '';
    /**@type {string} */ category = '';
    /**@type {string} */ startDate = '';
    /**@type {string} */ endDate = '';
    /**@type {string} */ title = '';
    /**@type {string} */ content = '';
}

export class Expanded {
    /**@type {boolean} */ description = true;
    /**@type {boolean} */ details = true;
    /**@type {boolean} */ dueDates = true;
    /**@type {boolean} */ labels = true;
}

export class ShowDropdown {
    /**@type {boolean} */ category = false;
    /**@type {boolean} */ expertise = false;
    /**@type {boolean} */ importance = false;
    /**@type {boolean} */ startDate = false;
    /**@type {boolean} */ endDate = false;
}

export class ShowInfo {
    /**@type {boolean} */ category = false;
    /**@type {boolean} */ expertise = false;
    /**@type {boolean} */ importance = false;
    /**@type {boolean} */ startDate = false;
    /**@type {boolean} */ endDate = false;
    /**@type {boolean} */ label = false;
}

/**
 * alias for the State object to use as type for the component
 */
export class State {
    /**@type {Expanded}*/       expanded = new Expanded();
    /**@type {ShowDropdown}*/   showDropdown = new ShowDropdown();
    /**@type {ShowInfo}*/       showInfo = new ShowInfo();
    /**@type {Fields}*/         fields = new Fields();
    /**@type {DateSelector}*/   dateSelector = new DateSelector();
    /**@type {Category[]}*/     categoryList = [];
    /**@type {Label[]}*/        labelList = [];
    /**@type {Errors}*/         errors = new Errors();
    /**@type {boolean}*/        isNew = true;
    /**@type {string} */        categoriesLastUpdated = '';
    /**@type {string} */        labelsLastUpdated = '';
}

