import React from 'react';

import { UserImage } from '../../util/ImageConstants'
import gStyles from '../../App.module.css';     // global styles
import pStyles from './ImageSearch.module.css';    // page-specific styles
import texts from './ImageSearchTexts';
import '../../resources/sprites.css'

UserImage.toString(); // suppress warning
/* 
    Presentation component for Search Results
    - should be stateless
*/


/**
 * @typedef {Object} Props
 * @property {UserImage[]} matchImages
 * @property {UserImage[]} relatedImages
 * @property {string} searchText
 * @property {(id:string)=>void} onSelect
 */

/** @param {Props} props */
export const SearchResult = (props) => {
    return (
        <main className={[gStyles.colFlex, pStyles.searchForm].join(' ')}>

            {/* search results */}
            <section className={[gStyles.rowFlex, pStyles.resultSection].join(' ')}>
                {
                    props.matchImages.map((img, i) => (
                        <div key={i} className={[gStyles.colFlex, pStyles.imageBox].join(' ')}
                            onClick={() => props.onSelect(img.id)}>
                            <button className={[img.id, gStyles.btnIcon].join(' ')} ></button>
                            {highlight(img.name, props.searchText)}
                        </div>
                    ))
                }
            </section>

            {/* no exact match */}
            {(props.matchImages.length <= 0 && props.relatedImages.length>0) && (
                <section className={[gStyles.colFlex, pStyles.totalLine].join(' ')}>
                    <span style={{marginBottom:'10px', fontSize:'14px'}}>{texts.noExactMatch}</span>
                    <span>{texts.noExactMatchSubtexts}</span>
                </section>
            )}

            {/* related images */}
            {props.relatedImages.length > 0 && (
                <div className={[gStyles.rowFlex, pStyles.relatedImagesSection].join(' ')}>{texts.relatedImages}</div>
            )}

            <section className={[gStyles.rowFlex, pStyles.resultSection].join(' ')}>
                {
                    props.relatedImages.map((img, i) => (
                        <div key={i} className={[gStyles.colFlex, pStyles.imageBox].join(' ')}
                            onClick={() => props.onSelect(img.id)}>
                            <button className={[img.id, gStyles.btnIcon].join(' ')} ></button>
                            {highlight(img.name, props.searchText)}
                        </div>
                    ))
                }
            </section>


            {/* no results */}
            {(props.matchImages.length + props.relatedImages.length) <= 0 && (
                <section className={[gStyles.colFlex, pStyles.totalLine].join(' ')}>
                    <span style={{marginBottom:'10px', fontSize:'14px'}}>{texts.noMatchTitle}</span>
                    <span style={{marginBottom:'5px'}}>{texts.noMatchSubtexts1}</span>
                    <span style={{paddingLeft:'20px'}}>{texts.noMatchSubtexts2}</span>
                </section>
            )}


        </main>
    )
}

/**
 * @param {string} origText
 * @param {string} searchText
 * @returns {import('react').ReactElement}
 */
const highlight = (origText, searchText) => {
    const i = origText.toLowerCase().indexOf(searchText.toLowerCase());
    const l = searchText.length;
    const left = (i < 0) ? origText : origText.substring(0, i);  // show origText if no match
    const mid = (i < 0) ? '' : origText.substring(i, i + l);
    const right = (i < 0) ? '' : origText.substring(i + l);
    return <div>
        <span>{left}</span>
        <span className={pStyles.highLight}>{mid}</span>
        <span>{right}</span>
    </div>
}




