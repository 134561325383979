import React from 'react'
import gStyles from '../../App.module.css';
import styles from './Info.module.css';
import * as size from '../../util/SizeConstants';
import * as icon from '../../util/IconConstants';
import texts from './AboutTexts'
import { goBack } from '../../navigation/PageManager'

export default class About extends React.Component {

    onBack = () => {
        goBack()
    }

    render() {
        return (
            <>
                <nav className={[gStyles.colFlex, gStyles.navBar].join(' ')}>

                    {/* horizontal menu */}
                    <div className={[gStyles.rowNowrapFlex].join(' ')}>

                        {/* back button */}
                        <span style={{ paddingLeft: size.NORMAL_SPACER }}>
                            <button onClick={this.onBack} className={[icon.BACK_WHITE, gStyles.btnIcon].join(' ')}></button>
                        </span>

                        {/* title */}
                        <span style={{ paddingLeft: size.NORMAL_SPACER, whiteSpace: 'nowrap' }}>{texts.aboutTitle}</span>


                    </div>

                </nav>
                <main className={[styles.mainContent].join(' ')}
                    dangerouslySetInnerHTML={{__html: 
                        `
                        <p>
                        <span style='font-weight:600'>MyBrainMate</span> is a Personal Information Management (PIM) software that helps you become productive personally.
                        </p>
                        <p>
                        Personal information is
                        </p>
                        <ul style='list-style-type:none'>
                        <li style='padding-bottom:5px'>About “you”</li>
                        <li style='padding-bottom:5px'>Owned by “you”</li>
                        <li style='padding-bottom:5px'>Relevant to “you”</li>
                        <li style='padding-bottom:5px'>Experienced by “you”</li>
                        <li style='padding-bottom:5px'>Used and consumed by “you”</li>
                        </ul>
                        <p>
                        The purpose of <span style='font-weight:600'>MyBrainMate</span> is to facilitate the acquiring, recording, organizing, usage, and management of personal information.
                        </p>
                        <p>
                        <span style='font-weight:600'>MyBrainMate</span> helps you manage precious resources such as time, energy, money, and information to become more efficient in utilizing and essentially enjoying these resources.
                        </p>
                        <p style='margin-top: 20px; font-size:18px'>
                        How it works
                        </p>
                        <p>
                        Generally, we can say that there are two stages on how our brain functions.
                        </p>
                        <p>
                        First stage, is the process of collecting, documenting, and organizing data. This is the <span style='font-style:italic'>coding</span> part.
                        </p>
                        <p>
                        The second stage is the analysis, evaluation of data, and using them for decision-making. This is the <span style='font-style:italic'>thinking</span> part.
                        </p>
                        <p>
                        <span style='font-weight:600'>MyBrainMate</span> can help you on the coding part so that you can focus on the thinking part.
                        </p>
                        <p style='margin-top:20px'>
                        “Let <span style='font-weight:600'>MyBrainMate</span> do the heavylifting, Let your brain do the thinking!”
                        </p>                        
                        `
                    }}
                >
                </main>
            </>




        )
    }

}