import React from 'react';

import { InfoCategory } from '../../data/InfoCategory'
import gStyles from '../../App.module.css';     // global styles
import pStyles from './Category.module.css';    // page-specific styles
import '../../resources/sprites.css'
import * as DataUtil from '../../data/DataUtil';
import texts from './EditCategoryTexts';

InfoCategory.toString(); // suppress warning

/* 
    Presentation component for Setup Category Page
    - should be stateless
*/


/**
 * @typedef {Object} Props
 * @property {InfoCategory[]} allCategories
 * @property {(id:string)=>void} onSelect
 */

/** @param {Props} props */
export const SetupCategoryView = (props) => (
    <main className={[gStyles.colFlex, pStyles.categoryForm].join(' ')}>

        {/* search results */}
        {props.allCategories.length > 0 &&
            <>
                <section className={[gStyles.colFlex, pStyles.resultSection].join(' ')}>
                    {
                        props.allCategories.map((c, i) => {
                            const ancestors = DataUtil.getAncestors(c);
                            return (
                            <div key={i} className={[gStyles.rowNowrapFlex, pStyles.line].join(' ')}
                                style={{ backgroundColor: c.backgroundColor, color: c.fontColor, marginLeft:`${20*ancestors.length}px` }}
                                onClick={() => props.onSelect(c.categoryId)}>
                                {c.image &&
                                    <span className={[gStyles.btnIcon].join(' ')} style={{ marginRight: '5px' }}>{c.image}</span>
                                  }
                                <span className={pStyles.lineDescription}>{c.name}</span>
                            </div>
                            )
                        })
                    }
                </section>
            </>
        }

        {/* search results */}
        {props.allCategories.length <= 0 &&
            <>
                <section className={[pStyles.emptySection].join(' ')}>
                    <span>{texts.noCategories}</span>
                </section>
            </>
        }

    </main>
)



