export default {

    byCategory      : 'View By Category',
    byDate          : 'View By Date',
    byExpertise     : 'View By Expertise',
    byImportance    : 'View By Importance',
    byStatus        : 'View By Status',
    byLabel         : 'View By Label',

}
