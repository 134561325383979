import React from 'react'
import styles from './SwitchView.module.css'
import dStyles from '../dropDown/Dropdown.module.css'
import gStyles from '../../App.module.css'
import { Utils } from '../../util/Utils'
import texts from './SwitchViewTexts'
import { SelectOption } from '../dropDown/SelectProps'
import { goToPage } from '../../navigation/PageManager'
import * as page from '../../util/PageConstants'


/**
 * @typedef {Object} Props
 * @property {string} currPage
 * @property {()=>void} onClose
 * 
 * @typedef {Object} State
 */

/** @extends {React.Component<Props, State>} */
export class SwitchView extends React.Component {

    /**@param {Props} props */
    constructor(props) {
        super(props);

        /**@type {SelectOption[]} */
        this.allViews = this.initViews();

        this.switchRef = React.createRef(); // reference to switch component to capture click outside

    }

    componentDidMount() {
        document.addEventListener("mousedown", this.onClickOutside, false);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.onClickOutside, false);
    }

    onClickOutside = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (!this.switchRef.current.contains(e.target)) {
            // click outside of calendar, close
            this.onSwitch();
        }
    }

    /**@param {string} [page] */
    onSwitch = (page = '') => {
        // Utils.debug('switching page', page);
        this.props.onClose();

        if (Utils.isEmpty(page)) return;
        if (page === this.props.currPage) return;

        goToPage(page);

    }



    render() {

        return (
            <div ref={this.switchRef}>
                <div className={[gStyles.colFlex, styles.switch].join(' ')}>

                    {/* options */}
                    {this.allViews.map((option, i) => (
                        <div key={i} onClick={() => this.onSwitch(option.code)}
                            className={[
                                dStyles.selectOption,
                                this.props.currPage === option.code ? dStyles.selectOptionSelected : '',
                            ].join(' ')}>
                            {option.description}
                        </div>
                    ))
                    }

                </div>
            </div>
        )
    }

    initViews = () => {
        return [
            new SelectOption(page.VIEW_BY_CATEGORY, texts.byCategory),
            new SelectOption(page.VIEW_BY_EXPERTISE, texts.byExpertise),
            new SelectOption(page.VIEW_BY_IMPORTANCE, texts.byImportance),
            new SelectOption(page.VIEW_BY_LABEL, texts.byLabel),    
        ]
    }

}



