import React from 'react';
import * as DataManager from '../../data/InfoManager'

import {SearchNavbar} from './SearchNavBar';
import {SearchBar} from './SearchBar';
import {SearchView} from './SearchView'
import {SearchResult} from './SearchResult'

import {goToPage, goBack, goToInfoPage} from '../../navigation/PageManager'
import * as PageManager from '../../navigation/PageManager'
import * as page from '../../util/PageConstants'
import { InfoCell } from '../../data/InfoCell';
import {sort} from '../../data/DataUtil';
import { StateManager } from '../../stateManager/StateManager';

InfoCell.toString();    // suppress warning

/**
 * Search Page
 * - container component
 */

 /**
  * @typedef {Object} Props
  * 
  * @typedef {Object} State
  * @property {InfoCell[]} matchCells
  * @property {string} searchText
  * @property {string} lastUpdated
  */

/** @extends {React.Component<Props, State>} */
export default class Search extends React.Component {

    /** @param {Props} props */
    constructor(props) {
        super(props);
        /**@type {InfoCell[]} */ this.allCells = DataManager.getAll();
        sort(this.allCells);

        /**@type {State} */
        this.state = {
            matchCells: [],
            searchText: '',
            lastUpdated: '',
        };
    }

    componentDidMount() {
        // log ('Search did mount');
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.lastUpdated !== StateManager.getData().cells.lastUpdated) {
            this.setState({lastUpdated: StateManager.getData().cells.lastUpdated});
            this.allCells = DataManager.getAll();
            sort(this.allCells);
        }
    }

    render() {
        return (
            <>
                <SearchNavbar
                    onClickBack={this.onClickBack}
                    onClickCreate={this.onClickCreate}
                    onClickHome={this.onClickHome}
                />
                <SearchBar
                    searchText={this.state.searchText}
                    onInputChange={this.onInputChange}
                    onClear={this.onClear}
                />
                {this.state.searchText ?
                    <SearchResult 
                        matchCells={this.state.matchCells}
                        searchText={this.state.searchText}
                        onSelect = {this.onSelect}
                    />
                    :
                    <SearchView
                    allCells={this.allCells}
                    onSelect={this.onSelect}
                />

                }
            </>
        )
    }

    /**@param {string} id */
    onSelect = (id) => {
        PageManager.goToInfoPage(page.EDIT_INFO, page.SEARCH, id);
    }

    onClickBack = (e) => {
        goBack();
    }

    onClickCreate = (e) => {
        goToInfoPage(page.EDIT_INFO, page.SEARCH);
    }

    onClickHome = (e) => {
        goToPage(page.HOME);
    }
    
    /** @param {import('react').SyntheticEvent<HTMLInputElement>} e */
    onInputChange = (e) => {
        const searchText = e.currentTarget.value;
        const matchCells = DataManager.findCells(searchText);
        sort(matchCells);
        this.setState({matchCells, searchText})
    }

    onClear = () => {
        this.setState({searchText:''})
    }

}

