import {StateManager} from '../stateManager/StateManager';
import {ActionType} from '../stateManager/ActionTypes'

import {InfoCategory} from './InfoCategory';
import { Utils } from '../util/Utils';

InfoCategory.toString(); // suppress warning

export class CategoryStoreState {
    /**@type {InfoCategory[]} */ categories = [];
    /**@type {string} */ lastUpdated = '';
}

/** @type {CategoryStoreState} */
let initialState = new CategoryStoreState();


///////////////// actions

/**
 * @typedef {Object} Action 
 * @property {string} type
 * @property {string} [categoryId]
 * @property {InfoCategory} [category]
 * @property {InfoCategory[]} [categories]
 */

  /**
  * 
  * @param {InfoCategory} category 
  * @return {Action}
  */
 const upsertAction = (category) => {
    return {
        type: ActionType.UPSERT_CATEGORY,
        category,
    }
 }

/**
* 
* @param {string} categoryId 
* @return {Action}
*/
const deleteAction = (categoryId) => {
    return {
        type: ActionType.DELETE_CATEGORY,
        categoryId,
    }
}

 
  /**
  * 
  * @param {InfoCategory[]} categories 
  * @return {Action}
  */
 const insertAllAction = (categories) => {
    return {
        type: ActionType.INSERT_ALL_CATEGORIES,
        categories,
    }
 }

 ///////////////// reducer

 /** 
  * @param {Action} action 
  * @return {CategoryStoreState}
  */
export let categoryReducer = (state = initialState, action) => {
    try {
        switch (action.type) {

            case ActionType.UPSERT_CATEGORY:
                return {
                    categories: upsert(state.categories, action.category),
                    lastUpdated: new Date().getTime().toString(),
                }
            case ActionType.DELETE_CATEGORY:
                    return {
                    categories: remove(state.categories, action.categoryId),
                    lastUpdated: new Date().getTime().toString(),
                }
            case ActionType.INSERT_ALL_CATEGORIES:
                return {
                    categories: action.categories,   // return all the cells to replace the state
                    lastUpdated: new Date().getTime().toString(),
                }
            default:
                return state;
        }        
    } catch (error) {
        Utils.error('Error in categoryReducer', error.message);
        throw new Error('Error in updating category state!'); // send user-oriented error message        
    }

}

/**
 * @param {InfoCategory[]} state - contains the original cells
 * @param {InfoCategory} category
 * @return {InfoCategory[]}
 */
const upsert = (state, category) => {
    /**@type {InfoCategory[]} */
    const allCategories = JSON.parse(JSON.stringify(state)) // deep clone 
    const idx = allCategories.findIndex( (c) => {
        return category.categoryId === c.categoryId
    });
    if (idx >= 0) {  
        allCategories.splice(idx,1, category); // delete and insert
    } else {
        allCategories.push(category);  // insert
    }
    return allCategories;
}

/**
 * @param {InfoCategory[]} state - contains the original cells
 * @param {string} categoryId
 * @return {InfoCategory[]}
 */
const remove = (state, categoryId) => {
    /**@type {InfoCategory[]} */
    const allCategories = JSON.parse(JSON.stringify(state)) // deep clone 
    const idx = allCategories.findIndex( (c) => {
        return categoryId === c.categoryId
    })
    if (idx >= 0) {  
        allCategories.splice(idx,1); // delete
    } else {
        Utils.error(`Error deleting category in state! ${categoryId} not found!`);
    }
    return allCategories;
}
/////////////////////////// helper public functions

/**
 * @param {InfoCategory} category
 */
export const upsertCategory = (category) => {
    StateManager.store.dispatch(upsertAction(category));
}

/**
 * @param {string} categoryId
 */
export const deleteCategory = (categoryId) => {
    StateManager.store.dispatch(deleteAction(categoryId));
}

/**
 * @param {InfoCategory[]} categories
 */
export const insertAll = (categories) => {
    StateManager.store.dispatch(insertAllAction(categories));
}