import {StateManager} from '../stateManager/StateManager';
import {ActionType} from '../stateManager/ActionTypes'

export class PageStoreState {
    /**@type {string} */ currPage = '';
    /**@type {string[]} */ prevPages = [];   // unused ?
    /**@type {string} */ cellId = '';       // pass in cell id in Edit Info page
    /**@type {string} */ categoryId = '';   // pass in category id in Edit Category page
    /**@type {string} */ labelId = '';   // pass in label id in Edit Label page
    /**@type {string} */ imageId = '';      // the selected image from search images
}


/** @type {PageStoreState} */
let initialState = new PageStoreState();

///////////  actions


/**
 * @typedef {Object} Action 
 * @property {string} type
 * @property {string} [toPage]
 * @property {string} [fromPage]
 * @property {string} [cellId]      
 * @property {string} [categoryId]  
 * @property {string} [labelId]  
 * @property {string} [imageId]     
 */

/** 
 * @param {string} toPage
 * @param {string} [fromPage ]
 * @return {Action}
 */
let updateAction = (toPage, fromPage) => {
    return {
        type: ActionType.TO_PAGE,
        toPage,
        fromPage,
    }
}

let backAction = () => {
    return {
        type: ActionType.BACK,
    }
}

/**
 * 
 * @param {string} cellId 
 * @return {Action}
 * 
*/
let cellIdAction = (cellId) => {
    return {
        type: ActionType.SET_INFO_ID,
        cellId: cellId,
    }

}

/**
 * 
 * @param {string} categoryId 
 * @return {Action}
 * 
*/
let categoryIdAction = (categoryId) => {
    return {
        type: ActionType.SET_CATEGORY_ID,
        categoryId,
    }
}

/**
 * 
 * @param {string} labelId 
 * @return {Action}
 * 
*/
let labelIdAction = (labelId) => {
    return {
        type: ActionType.SET_LABEL_ID,
        labelId,
    }

}

/**
 * 
 * @param {string} imageId 
 * @return {Action}
 * 
*/
let imageIdAction = (imageId) => {
    return {
        type: ActionType.SET_IMAGE_ID,
        imageId,
    }
}

///////////// reducer

/** 
 * 
 * @param {Action} action 
 * @return {PageStoreState}
 * 
*/
export let pageReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionType.TO_PAGE:
            return {
                ...state,
                currPage: action.toPage,
                prevPages: [...state.prevPages, action.fromPage], // add to the last
            };
        case ActionType.BACK:
            const prevStack = [...state.prevPages];  // clone
            const currPage = prevStack.pop();   // remove the last
            return {
                ...state,
                currPage,
                prevPages: prevStack
            };
        case ActionType.SET_INFO_ID:
            return { ...state, cellId: action.cellId };
        case ActionType.SET_CATEGORY_ID:
            return { ...state, categoryId: action.categoryId };
        case ActionType.SET_LABEL_ID:
            return { ...state, labelId: action.labelId };
        case ActionType.SET_IMAGE_ID:
            return { ...state, imageId: action.imageId };
        default:
            return state;
    }
}

///////////// helper public functions

/** 
 * @param {string} toPage
 * @param {string} [fromPage]
 */
export let goToPage = (toPage, fromPage) => {
    StateManager.store.dispatch(updateAction(toPage, fromPage));
}

export let goBack = () => {
    StateManager.store.dispatch(backAction());
}

export const getCurrentPage = () => {
    return StateManager.getData().page.currPage;
}

/**@param {string} id */
export let setCellId = (id) => {
    StateManager.store.dispatch(cellIdAction(id));
}

/**@param {string} id */
export let setCategoryId = (id) => {
    StateManager.store.dispatch(categoryIdAction(id));
}

/**@param {string} id */
export let setLabelId = (id) => {
    StateManager.store.dispatch(labelIdAction(id));
}

/**@param {string} id */
export let setImageId = (id) => {
    StateManager.store.dispatch(imageIdAction(id));
}