export default  {

    appTitle: "MyBrainMate Info",
    editInfoTitle: 'Edit Info',
    newInfoTitle: 'New Info',
    searchInfoTitle: 'Search Info',
    setupCategoryTitle: 'Setup Categories',
    editCategoryTitle: 'Edit Category',
    newCategoryTitle: 'New Category',
    searchImgTitle: 'Search Images',

    // status
    statusNotStarted: 'Not Started',
    statusInProgress: 'In Progress',
    statusDone: 'Done',
    statusWaiting: 'Waiting',
    statusCancelled: 'Cancelled',

    // priority
    priorityVeryHigh: 'Highest Priority',
    priorityHigh: 'High Priority',
    priorityNormal: 'Normal Priority',
    priorityLow: 'Low Priority',
    priorityVeryLow: 'Lowest Priority',

    // importance
    importanceVeryHigh: 'Highest Importance',
    importanceHigh: 'High Importance',
    importanceNormal: 'Normal Importance',
    importanceLow: 'Low Importance',
    importanceVeryLow: 'Lowest Importance',

    // expertise
    expertiseExpert: 'Expert',
    expertiseAdvanced: 'Advanced',
    expertiseIntermediate: 'Intermediate',
    expertiseBeginner: 'Beginner',
    expertiseNotApplicable: 'Not Applicable',

    // date selector
    dateSelectorNone: 'None',
    dateSelectorToday: 'Today',
    dateSelectorTomorrow: 'Tomorrow',
    dateSelectorWeek1: '1 Week',
    dateSelectorWeek2: '2 Weeks',
    dateSelectorMonth1: '1 Month',
    dateSelectorDate: 'Date',

    // repeat selector
    repeatSelectorNone: 'None',
    repeatSelectorDaily: 'Daily',
    repeatSelectorWeekly: 'Weekly',
    repeatSelectorMonthly: 'Monthly',
    repeatSelectorYearly: 'Yearly',
        
    yes: 'YES',
    no: 'NO',

    // tool tip
    goBackTip: 'Go back',
    searchTip: 'Search',
    createTip: 'Create info',
    saveTip: 'Save',
    saveNewTip: 'Save & New',
    deleteTip: 'Delete',
    calendarTip: 'Calendar',
    setupCategoryTip: 'Setup Categories',
    setupLabelTip: 'Setup Labels',
    createCategoryTip: 'Create category',
    createLabelTip: 'Create label',

    // view nav bar icons
    toggleScrollTip: 'Scroll up/down or left/right',
    toggleExpandAllTip: 'Expand/Collapse all',
    filterTip: 'Set or clear filter',
    switchViewTip: 'Switch view',
    settingsTip: 'Settings',
    toggleCompletedTip: 'Show or hide completed tasks',
    menuOptionTip: 'Show or hide other additional menu',

    // view
    addToCategoryTip: 'add info to this category',

    // view snackbars
    snackbarShowCompletedTasks: 'Showing completed tasks',
    snackbarHideCompletedTasks: 'Hiding completed tasks',
    snackbarScrollLeftRight: 'Scroll left and right',
    snackbarScrollUpDown: 'Scroll up and down',
    snackbarCollapseAll: 'Collapsing all',
    snackbarExpandAll: 'Expanding all',

    localStorageError: `Local Storage is not available in this browser. 
    MyBrainMate needs to store your data locally so that it can work offline. 
    Please check the documentation of your browser to enable cookies.`
}



