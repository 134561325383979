import {StateManager} from '../stateManager/StateManager';
import {ActionType} from '../stateManager/ActionTypes'

import {InfoCell} from './InfoCell';
import { Utils } from '../util/Utils';

InfoCell.toString();

export class InfoStoreState {
    /**@type {InfoCell[]} */ cells = [];
    /**@type {string} */ lastUpdated = '';
}

/** @type {InfoStoreState} */
let initialState = new InfoStoreState();


/**
 * @typedef {Object} Action 
 * @property {string} type
 * @property {string} [cellId]
 * @property {InfoCell} [cell]
 * @property {InfoCell[]} [cells]
 */

  /**
  * 
  * @param {InfoCell} cell 
  * @return {Action}
  */
 const upsertAction = (cell) => {
    return {
        type: ActionType.UPSERT,
        cell
    }
 }

/**
* 
* @param {string} cellId 
* @return {Action}
*/
const deleteAction = (cellId) => {
    return {
        type: ActionType.DELETE,
        cellId,
    }
}

 
  /**
  * 
  * @param {InfoCell[]} cells 
  * @return {Action}
  */
 const insertAllAction = (cells) => {
    return {
        type: ActionType.INSERT_ALL,
        cells, 
    }
 }

 ///////////////// reducer

 /** 
  * @param {Action} action 
  * @return {InfoStoreState}
  */
export let dataReducer = (state = initialState, action) => {
    try {
        switch (action.type) {

            case ActionType.UPSERT:
                return {
                    cells: upsert(state.cells, action.cell),
                    lastUpdated: new Date().getTime().toString(),
                }
            case ActionType.DELETE:
                return {
                    cells: remove(state.cells, action.cellId),
                    lastUpdated: new Date().getTime().toString(),
                }
            case ActionType.INSERT_ALL:
                return {
                    cells: action.cells,    // return all the cells to replace the state
                    lastUpdated: new Date().getTime().toString(),
                }
            default:
                return state;
        }
            
    } catch (error) {
        Utils.error('Error in dataReducer', error.message);
        throw new Error('Error in updating info state!'); // send user-oriented error message
    }
}

/**
 * @param {InfoCell[]} state - contains the original cells
 * @param {InfoCell} cell
 * @return {InfoCell[]}
 */
const upsert = (state, cell) => {
    /**@type {InfoCell[]} */
    const allCells = JSON.parse(JSON.stringify(state)) // deep clone 
    const idx = allCells.findIndex( (c) => {
        return cell.cellId === c.cellId
    })
    if (idx >= 0) {  
        allCells.splice(idx,1, cell); // delete and insert
    } else {
        allCells.push(cell);  // insert
    }
    return allCells;
}

/**
 * @param {InfoCell[]} state - contains the original cells
 * @param {string} cellId
 * @return {InfoCell[]}
 */
const remove = (state, cellId) => {
    /**@type {InfoCell[]} */
    const allCells = JSON.parse(JSON.stringify(state)) // deep clone 
    const idx = allCells.findIndex( (c) => {
        return cellId === c.cellId
    })
    if (idx >= 0) {  
        allCells.splice(idx,1); // delete
    } else {
        Utils.error(`Error deleting cell in state! ${cellId} not found!`);
    }
    return allCells;
}
/////////////////////////// helper public functions

/**
 * @param {InfoCell} cell
 */
export const upsertCell = (cell) => {
    StateManager.store.dispatch(upsertAction(cell));
}

/**
 * @param {string} cellId
 */
export const deleteCell = (cellId) => {
    StateManager.store.dispatch(deleteAction(cellId));
}

/**
 * @param {InfoCell[]} cells
 */
export const insertAll = (cells) => {
    StateManager.store.dispatch(insertAllAction(cells));
}