import React from 'react'
import gStyles from '../../App.module.css';
import styles from './Info.module.css';
import * as size from '../../util/SizeConstants';
import * as icon from '../../util/IconConstants';
import texts from './AboutTexts'
import { goBack } from '../../navigation/PageManager'

export default class About extends React.Component {

    onBack = () => {
        goBack()
    }

    render() {
        return (
            <>
                <nav className={[gStyles.colFlex, gStyles.navBar].join(' ')}>

                    {/* horizontal menu */}
                    <div className={[gStyles.rowNowrapFlex].join(' ')}>

                        {/* back button */}
                        <span style={{ paddingLeft: size.NORMAL_SPACER }}>
                            <button onClick={this.onBack} className={[icon.BACK_WHITE, gStyles.btnIcon].join(' ')}></button>
                        </span>

                        {/* title */}
                        <span style={{ paddingLeft: size.NORMAL_SPACER, whiteSpace: 'nowrap' }}>{texts.tipsTitle}</span>


                    </div>

                </nav>
                <main className={[styles.mainContent].join(' ')}
                    dangerouslySetInnerHTML={{__html: 
                        `
                        <p>
                        You can follow the guidelines below to get the most out of <span style='font-weight:600'>MyBrainMate Info</span> and be more productive
                        <p style='margin-top:20px; font-weight:600; font-size:18px'>
                        Using Categories and Sub-categories
                        <p>
                        Use category to group related info
                        <p>
                        Grouping information allows for better recall and for easily locating information
                        <p>
                        Categories and sub-categories place your info in a hierarchy, it’s a way of organizing vertically. Each info can belong only to a single category
                        <p>
                        Keep your number of categories (and their levels) around the magic number of 7. If you're nearing 10-12, it's time to create a subcategory (this is also called chunking)
                        <p style='margin-top:20px; font-weight:600; font-size:18px'>
                        Using Labels
                        <p>
                        Label is also used to group related info. It’s a horizontal way of organizing your info. An info can have multiple labels
                        <p>
                        A label is a tag you attach to your info to identify their grouping.
                        <p>
                        For example, you may use labels for time such as - Pre-history, Ancient Times, Modern Times - to indicate a period of time an event occurred
                        <p>
                        In conjunction with time, you can also use places - Europe, America, Asia, Africa - to indicate a geographic location
                        <p style='margin-top:20px; font-weight:600; font-size:18px'> 
                        Use colors and visuals
                        <p>
                        Our brain works better with images. Use colors to differentiate categories. Choose icon that best represents groups. This way it’s easier and faster to recognize your data at a glance
                        <p>
                        Use cool colors (blue, green, indigo) for info that are for fun, relaxation, entertainment. Use warm colors (yellow, red, orange) for info that are serious and high importance
                        <p style='margin-top:20px; font-weight:600; font-size:18px'> 
                        Only store personal information
                        <p>
                        You don't have to store to <span style='font-weight:600'>MyBrainMate Info</span> facts that are readily accessible and generally accepted facts such as references and encyclopedia articles. It's enough to just provide links to them
                        <p>
                        Only store info that are relevant to you, that is useful to you, experienced by you, or owned by you                                                            
                        `
                    }}
                >
                </main>
            </>




        )
    }

}