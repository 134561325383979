import React from 'react';
import * as InfoManager from '../../data/InfoManager'
import * as CategoryManager from '../../data/CategoryManager'

import { ByExpertiseNavbar } from './ByExpertiseNavBar';
import { ByExpertiseView } from './ByExpertiseView'

import { goToPage, goBack } from '../../navigation/PageManager'
import * as PageManager from '../../navigation/PageManager'
import * as page from '../../util/PageConstants'
import { isEmpty, logWarn, Utils } from '../../util/Utils';
import { StateManager } from '../../stateManager/StateManager';
import { GroupLevel, CategoryLevel, InfoLevel } from './ViewData';
import * as InfoConstants from '../../util/InfoConstants'
import gTexts from '../../resources/texts';
import * as ViewHelper from '../../util/ViewHelper'
import { Fields as FilterFields } from '../../components/filter/FilterState';
import { Filter } from '../../components/filter/Filter';
import * as FilterHelper from '../../util/FilterHelper'
import pStyles from './ByExpertise.module.css';    // page-specific styles
import { SwitchView } from '../../components/switchView/SwitchView';
import {Notification} from '../../components/notification/Notification'

/**
 * View by Expertise Page
 * - container component
 * 
 * by Ludwin 2019/11/18
 */

/**
 * @typedef {Object} Props
 * 
 * @typedef {Object} State
 * @property {Map} allGroups
 * @property {string} lastUpdated
 * @property {boolean} isShowMenuOptions
 * @property {boolean} isPageVertical
 * @property {boolean} isExpandAll
 * @property {boolean} isShowFilter
 * @property {FilterFields} filterFields
 * @property {boolean} isShowViews
 * 
 */

/** @extends {React.Component<Props, State>} */
export default class ByExpertise extends React.Component {

    /** @param {Props} props */
    constructor(props) {
        super(props);

        this.allGroups = new Map();

        this.buildDataModel();

        /**@type {State} */
        this.state = {
            allGroups: this.allGroups,
            lastUpdated: StateManager.getData().cells.lastUpdated,
            isShowMenuOptions: false,
            isPageVertical: true,
            isExpandAll: true,
            isShowFilter: false,
            filterFields: new FilterFields(),
            isShowViews: false,
        };
    }


    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.lastUpdated !== StateManager.getData().cells.lastUpdated) {
            this.buildDataModel();  // rebuild allGroups when info changed (add, delete, update) - their categories may have changed
            this.setState({
                lastUpdated: StateManager.getData().cells.lastUpdated,
                allGroups: this.allGroups,
            });
        }
    }

    render() {
        return (
            <>
                <ByExpertiseNavbar
                    isExpandAll         ={this.state.isExpandAll}
                    isShowMenuOptions   ={this.state.isShowMenuOptions}
                    isPageVertical      ={this.state.isPageVertical}
                    hasFilter           ={FilterHelper.hasFilter(this.state.filterFields)}
                    onClickBack         ={this.onClickBack}
                    onClickCreate       ={this.onClickCreate}
                    onClickSearch       ={this.onClickSearch}
                    onClickMenuOptions  ={this.onClickMenuOptions}
                    onClickPaging       ={this.onClickPaging}
                    onClickFilter       ={this.onClickFilter}
                    onClickSettings     ={this.onClickSettings}
                    onExpandAll         ={this.onExpandAll}
                    onClickHome         ={this.onClickHome}
                    onSwitchView        ={this.onSwitchView}
                />

                {/* filter */}
                <div className={pStyles.filterContainer}>
                    {this.state.isShowFilter &&
                        <Filter
                            currFilter={this.state.filterFields}
                            onSetFilter={this.onSetFilter}
                        />
                    }
                </div>

                {/* switch views */}
                {this.state.isShowViews &&
                    <SwitchView
                        currPage={page.VIEW_BY_EXPERTISE}
                        onClose={this.onSwitchView}
                    />
                }

                <ByExpertiseView
                    isShowMenuOptions       = {this.state.isShowMenuOptions}
                    isPageVertical          = {this.state.isPageVertical}
                    allGroups               = {this.allGroups}
                    onToggleGroup           = {this.onToggleGroup}
                    onToggleCategory        = {this.onToggleCategory}
                    onCreateWithCategory    = {this.onCreateWithCategory}
                    onSelect                = {this.onSelect}
                />

            </>
        )
    }

    // *************** handle navbar events

    onClickBack = () => {
        goBack();
    }

    onClickCreate = () => {
        PageManager.clearCellId();
        PageManager.goToPage(page.EDIT_INFO);
    }

    onClickSearch = () => {
        goToPage(page.SEARCH);
    }

    onClickMenuOptions = () => {
        this.setState({isShowMenuOptions: !this.state.isShowMenuOptions})
    }

    onClickHome = () => {
        goToPage(page.HOME);
    }

    onClickPaging = () => {
        Notification.snackBarInfo(this.state.isPageVertical ? gTexts.snackbarScrollLeftRight : gTexts.snackbarScrollUpDown);
        this.setState({isPageVertical: !this.state.isPageVertical});
    }

    onClickSettings = () => {
        // PageManager.goToPage(page.SYSTEM);
    }

    onExpandAll = () => {
        Notification.snackBarInfo(this.state.isExpandAll ? gTexts.snackbarCollapseAll : gTexts.snackbarExpandAll);
        this.setState({isExpandAll: !this.state.isExpandAll}, this.udpateExpandAll)
    }

    onSwitchView = () => {
        this.setState({ isShowViews: !this.state.isShowViews})
    }


    // ****************** handle view events

    /**
     * @param {string} groupId 
     */
    onToggleGroup = (groupId) => {
        /**@type {GroupLevel} */ const group = this.allGroups.get(groupId);
        group.isExpanded = !group.isExpanded; // toggle
        this.setState({ allGroups: this.allGroups });    
    }

    /**
     * @param {string} groupId 
     * @param {string} categoryId 
     */
    onToggleCategory = (groupId, categoryId) => {
        /**@type {GroupLevel} */ const group = this.allGroups.get(groupId);
        /**@type {CategoryLevel} */ const category = group.categoryMap.get(categoryId);
        category.isExpanded = !category.isExpanded; // toggle
        this.setState({ allGroups: this.allGroups});
    }
    
    /**
     * when click the create button per category
     * @param {import('react').SyntheticEvent<HTMLButtonElement>} e
     * @param {string} categoryId 
     */
    onCreateWithCategory = (e, categoryId) => {
        e.stopPropagation(); // prevent propagating event to other elements
        PageManager.clearCellId();
        PageManager.setCategoryId(categoryId);
        PageManager.goToPage(page.EDIT_INFO);
    }

    /**
     * on clicking the info name
     * @param {string} id 
     */
    onSelect = (id) => {
        PageManager.setCellId(id);
        PageManager.goToPage(page.EDIT_INFO);
    }

    // ********************* build data model for the view to display

    buildDataModel = () => {
        this.buildGroups();
        const allInfo = InfoManager.getAll();
        allInfo.forEach((info) => {
            if (FilterHelper.isPassFilter(info, Utils.isEmpty(this.state) ? null : this.state.filterFields)) {

                // group level
                const groupId = info.expertise;
                /**@type {GroupLevel} */ const groupLevel = this.allGroups.get(groupId);

                // category level
                const categoryId = info.categoryId;
                const categoryMap = groupLevel.categoryMap;
                if (!categoryMap.has(categoryId)) {  // save category only once
                    const category = CategoryManager.getCategory(categoryId);
                    if (!isEmpty(category)) {
                        const categoryLevel = new CategoryLevel(category);
                        categoryLevel.displayText = ViewHelper.getCategoryDisplayText(category);
                        categoryMap.set(categoryId, categoryLevel);
                    }
                }
                /**@type {CategoryLevel} */ const categoryLevel = categoryMap.get(categoryId);

                // info level
                if (!isEmpty(categoryLevel)) {
                    const infoMap = categoryLevel.infoMap;
                    infoMap.set(info.cellId, new InfoLevel(info));
                } else {
                    logWarn('Missing categoryId', categoryId);
                }

            }
        })
    }

    buildGroups = () => {
        this.createGroup(InfoConstants.Expertise.EXPERT, gTexts.expertiseExpert);
        this.createGroup(InfoConstants.Expertise.ADVANCED, gTexts.expertiseAdvanced);
        this.createGroup(InfoConstants.Expertise.INTERMEDIATE, gTexts.expertiseIntermediate);
        this.createGroup(InfoConstants.Expertise.BEGINNER, gTexts.expertiseBeginner);
        this.createGroup(InfoConstants.Expertise.NOT_APPLICABLE, gTexts.expertiseNotApplicable);
    }
    /**
     * @param {string} id
     * @param {string} desc
     * @returns {GroupLevel}
     */
    createGroup = (id, desc) => {
        const group = new GroupLevel(id, desc);
        this.allGroups.set(id, group);
        return group
    }

    updateDataModel = () => {
        this.buildDataModel();  // rebuild allGroups when toggling to show info preview
        this.setState({ allGroups: this.allGroups});
    }

    udpateExpandAll = () => {
        
        this.allGroups.forEach( /**@param {GroupLevel} g */ (g) => {
            g.isExpanded = this.state.isExpandAll;
            g.categoryMap.forEach( /**@param {GroupLevel} c */ (c) => {
                c.isExpanded = this.state.isExpandAll;
            })
        });
        this.setState({allGroups: this.allGroups});
    }

    // ******************************** filter handlers

    /** show filter dialog */
    onClickFilter = () => {
        this.setState({isShowFilter: true});
    }

    /**
     * the handler when filters are set
     * valid values:
     *      null = filter dialog was closed, 
     *      empty fields = filters are cleared
     *      non-empty fields = filters are set
     * 
     * @param {FilterFields}  inFilterFields // 
     */
    onSetFilter = (inFilterFields) => {
        this.setState({isShowFilter: false});  // close the filter
        
        if (Utils.isEmpty(inFilterFields)) return; // do nothing

        // set filter only if there's difference from current state
        if (FilterHelper.isDiff(this.state.filterFields, inFilterFields)) {
            this.setState({filterFields: inFilterFields}, this.updateDataModel);  // rebuild data model 
        }
    }

    

}

