import React from 'react';

import { InfoCell } from '../../data/InfoCell'
import gStyles from '../../App.module.css';     // global styles
import pStyles from './Search.module.css';    // page-specific styles
import texts from './SearchTexts';
import '../../resources/sprites.css'

InfoCell.toString(); // suppress warning

/* 
    Presentation component for Search Page
    - should be stateless
*/


/**
 * @typedef {Object} Props
 * @property {InfoCell[]} allCells
 * @property {(id:string)=>void} onSelect
 */

/** @param {Props} props */
export const SearchView = (props) => (
    <main className={[gStyles.colFlex, pStyles.searchForm].join(' ')}>

        {/* search results */}
        {props.allCells.length > 0 &&
            <>
                <section className={[gStyles.colFlex, pStyles.resultSection].join(' ')}>
                    {
                        props.allCells.map((c, i) => (
                            <div key={i} className={pStyles.line}
                                onClick={() => props.onSelect(c.cellId)}>
                                {c.name}
                            </div>
                        ))
                    }
                </section>

                <section className={[pStyles.totalLine].join(' ')}>
                    <span>{`${texts.searchTotal} ${props.allCells.length}`}</span>
                </section>
            </>
        }

        {/* no info */}
        {props.allCells.length <= 0 &&
            <section className={[pStyles.emptySearchSection].join(' ')}>
                <span>{texts.noInfo}</span>
            </section>
        }

    </main>
)




