import { InfoCell } from "../data/InfoCell";
import { Utils } from "./Utils";
import { Fields as FilterFields } from '../components/filter/FilterState';

InfoCell.toString(); // suppress warning
FilterFields.toString(); // suppress warning

 /**
  * 
  * @param {InfoCell} cell 
  * @param {FilterFields} filterFields 
  */
export const isPassFilter = (cell, filterFields) => {

    if (Utils.isEmpty(filterFields)) return true;  // skip if filters are not yet available (ie, called from constructor)

    if (hasFilter(filterFields)) {
        if (Utils.isNotEmpty(filterFields.categoryId)) {
            if (cell.categoryId !== filterFields.categoryId) return false;
        }
        if (Utils.isNotEmpty(filterFields.expertise)) {
            if (cell.expertise !== filterFields.expertise) return false;
        }
        if (Utils.isNotEmpty(filterFields.importance)) {
            if (cell.importance !== filterFields.importance) return false;
        }
        if (Utils.isNotEmpty(filterFields.labelId)) {
            if (Utils.isEmpty(cell.labels)) return false;
            if (!cell.labels.includes(filterFields.labelId)) return false;
        }
    }
    return true;
}

/**
 * @param {FilterFields} filterFields
 * @returns {boolean} */
export const hasFilter = (filterFields) => {
    if (Utils.isNotEmpty(filterFields.categoryId)) return true;
    if (Utils.isNotEmpty(filterFields.labelId)) return true;
    if (Utils.isNotEmpty(filterFields.status)) return true;
    if (Utils.isNotEmpty(filterFields.expertise)) return true;
    if (Utils.isNotEmpty(filterFields.importance)) return true;
    return false;
}

/**
 * check if two filters are different
 * @param {FilterFields} filter1,
 * @param {FilterFields} filter2,
 * @returns {boolean}
 */
export const isDiff = (filter1, filter2) => {
    const f1 = JSON.stringify(filter1);
    const f2 = JSON.stringify(filter2);
    return (f1 !== f2);
}
