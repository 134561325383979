import React from 'react'
import gStyles from '../../App.module.css'
import styles from './Dialog.module.css'
import texts from '../../resources/texts';
import { Notification, NotificationType } from './Notification';

export const Dialog = ({ 
    /**@type {string} */ title, 
    /**@type {string} */ content, 
    /**@type {(ans:string)=>void} */ onClose, 
    focusRef
    }) => {

    return (
        <div onBlur={()=>onClose('')} tabIndex={0} ref={focusRef}  // current focus, should close the dialog when out of focus (ie, click outside)
            className={[gStyles.colFlex, styles.dialogInfo].join(' ')}  >

            {/* title */}
            <div onClick={()=>onClose('')} 
                className={[
                    gStyles.rowFlex, 
                    Notification.getDialogType() === NotificationType.INFO ? styles.infoHeader :
                    Notification.getDialogType() === NotificationType.WARN ? styles.warnHeader :
                    Notification.getDialogType() === NotificationType.ERROR ? styles.errorHeader : ''
                    ].join(' ')}>
                <span  style={{width:'90%'}}>{title}</span>
                <span className={gStyles.splitRight}>&times;</span>
            </div>

            {/* content */}
            <div className={[gStyles.colFlex, styles.content].join(' ')}>
                {content}
            </div>

            {/* action */}
            <div className={[gStyles.rowFlex, styles.actionFooter].join(' ')}>
                <div className={gStyles.splitRight}></div>

                <div className={gStyles.btnText} style={{marginRight:'10px'}} onClick={()=>onClose(texts.yes)}>{texts.yes}</div>
                <div className={gStyles.btnText} onClick={()=>onClose(texts.no)}>{texts.no}</div>
            </div>
        </div>
    )
}