import React from 'react';
import gTexts from '../../resources/texts';
import gStyles from '../../App.module.css';
import * as icon from '../../util/IconConstants';

import '../../resources/sprites.css';

import * as size from '../../util/SizeConstants';

// Edit Info Nav bar - must be stateless

/**
 * @typedef {Object} Props
 * @property { string } currInfoId
 * @property { ()=>void} onClickBack
 * @property { ()=>void} onClickSave
 * @property { ()=>void} onClickSaveNew
 * @property { ()=>void} onClickDelete
 */


/** @param {Props} props */
export const EditInfoNavbar = (props) => (
    <>
        <nav className={[gStyles.rowNowrapFlex, gStyles.navBar].join(' ')}>

            {/* back button */}
            <span style={{ paddingLeft: size.NORMAL_SPACER }}>
                <button 
                    title={gTexts.goBackTip}
                    onClick={props.onClickBack} className={[icon.BACK_WHITE, gStyles.btnIcon].join(' ')}></button>
            </span>

            {/* title */}
            <span style={{ paddingLeft: size.NORMAL_SPACER }}>{props.currInfoId ? gTexts.editInfoTitle : gTexts.newInfoTitle}</span>

            {/* right side actions */}
            <div className={[gStyles.splitRight].join(' ')} >

                {/* delete button */}
                <span style={{ paddingRight: size.NORMAL_SPACER }}>
                    <button 
                        title={gTexts.deleteTip}
                        onClick={props.onClickDelete} className={[icon.DELETE_WHITE, gStyles.btnIcon].join(' ')}></button>
                </span>

                {/* save & new */}
                <span style={{ paddingRight: size.NORMAL_SPACER }}>
                    <button 
                        title={gTexts.saveNewTip}
                        onClick={props.onClickSaveNew} className={[icon.SAVE_NEW_WHITE, gStyles.btnIcon].join(' ')}></button>
                </span>

                {/* save */}
                <span style={{ paddingRight: size.NORMAL_SPACER }}>
                    <button 
                        title={gTexts.saveTip}
                        onClick={props.onClickSave} className={[icon.SAVE_WHITE, gStyles.btnIcon].join(' ')}></button>
                </span>

            </div>

        </nav>
        {/* <div style={{display:'block', clear:'both', content:''}}></div> */}


    </>
);


