export default {

    title               : 'Filter',
    categoryLabel       : 'Category:',
    expertiseLabel       : 'Expertise:',
    importanceLabel     : 'Importance:',
    tagLabel            : 'Label',
    
    none                : '(None)',

    ok                  : 'OK',
    clear               : 'Clear',

}
