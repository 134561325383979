import React from 'react';
import gTexts from '../../resources/texts';
import gStyles from '../../App.module.css';
import * as icon from '../../util/IconConstants';

import '../../resources/sprites.css';

import * as size from '../../util/SizeConstants';

// Edit Category Nav bar - must be stateless

/**
 * @typedef {Object} Props
 * @property { string } currCategoryId
 * @property { ()=>void} onClickBack
 * @property { ()=>void} onClickSave
 * @property { ()=>void} onClickSaveNew
 * @property { ()=>void} onClickDelete
 */


/** @param {Props} props */
export const EditCategoryNavbar = (props) => (
    <>
        <nav className={[gStyles.rowFlex, gStyles.navBar].join(' ')}>

            {/* back button */}
            <span style={{ paddingLeft: size.NORMAL_SPACER }}>
                <button onClick={props.onClickBack} className={[icon.BACK_WHITE, gStyles.btnIcon].join(' ')}></button>
            </span>

            {/* title */}
            <span style={{ paddingLeft: size.NORMAL_SPACER }}>{props.currCategoryId ? gTexts.editCategoryTitle : gTexts.newCategoryTitle}</span>

            {/* right side actions */}
            <div className={[gStyles.splitRight].join(' ')} >

                {/* delete button */}
                <span style={{ paddingRight: size.NORMAL_SPACER }}>
                    <button onClick={props.onClickDelete} className={[icon.DELETE_WHITE, gStyles.btnIcon].join(' ')}></button>
                </span>

                {/* save & new */}
                <span style={{ paddingRight: size.NORMAL_SPACER }}>
                    <button onClick={props.onClickSaveNew} className={[icon.SAVE_NEW_WHITE, gStyles.btnIcon].join(' ')}></button>
                </span>

                {/* save */}
                <span style={{ paddingRight: size.NORMAL_SPACER }}>
                    <button onClick={props.onClickSave} className={[icon.SAVE_WHITE, gStyles.btnIcon].join(' ')}></button>
                </span>

            </div>

        </nav>


    </>
);


