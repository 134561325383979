export default {
    descriptionHeader   : 'Description',
    stylingHeader       : 'Styling',

    nameLabel           : 'Name:',
    nameHint            : 'Enter category name',
    parentLabel         : 'Parent:',
    iconLabel           : 'Icon:',
    iconHint            : 'Use your keyboard to select emoji',
    textColorLabel      : 'Text color:',
    bgroundLabel        : 'Background color:',
    previewLabel        : 'Preview:',

    none                : '(None)',


    // help

    // error messages
    emptyNameError      : 'You forgot to input category name, duh!',
    rootNameError       : `ROOT category name is reserved, c'mon be more creative!`,
    emptyParentError    : 'Hey, you forgot to choose parent category!',
    sameColorError      : 'Duh, can\'t set same color for text and background',

    saveError           : 'Error saving category: ', 
    deleteError         : 'Error deleting category: ', 

    deleteWarning       : {title:'Delete Category',content:'Oh oh! This will delete the category and all its descendants. Would you like to continue?'},

    // popup messages
    validationError     : 'Uh oh, errors found!',
    save                : 'Saving category...',
    saveNew             : 'Saving & new...',
    delete              : 'Deleting category...',

    noCategories        : 'There are no categories. You can use categories to organize your info, start creating by tapping the + button...'
}