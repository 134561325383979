import React from 'react'
import styles from './Settings.module.css'
import gStyles from '../../App.module.css'
import { Utils } from '../../util/Utils'
import texts from './SettingsTexts'
import { SelectList } from '../../components/dropDown/Dropdown';
import { SelectOption } from '../../components/dropDown/SelectProps'
import * as Constants from '../../util/InfoConstants'
import * as ViewHelper from '../../util/ViewHelper'
import { State, Category, Fields } from './SettingsState'
import * as DataUtil from '../../data/DataUtil'
import { SettingsNavbar } from './SettingsNavBar'
import { goBack } from '../../navigation/PageManager'
import { getSettings, updateSettings } from '../../stateManager/SettingsStore'
import { Notification, NotificationType } from '../../components/notification/Notification'
import ImportExport from '../../components/importExport/ImportExport';

Fields.toString();    // suppress warning

/**
 * @typedef {Object} Props
 * 
 * @typedef {State} State
 */

/** @extends {React.Component<Props, State>} */
export default class Settings extends React.Component {

  /**@param {Props} props */
  constructor(props) {
    super(props);

    // initialize state
    /**@type {State} */
    this.state = this.initState();

    this.maxStorage = 1024 * 5;  // 5MB
    this.currStorage = Math.round(unescape(encodeURIComponent(JSON.stringify(localStorage))).length / 1024);   // KB
    this.percentUsed = Math.round((this.currStorage / this.maxStorage) * 100);
    this.percentUsed = this.percentUsed <= 0 ? 1 : this.percentUsed;

  }

  componentDidMount() {
  }


  componentWillUnmount() {
  }

  /** @param {string} code */
  onSelectCategory = (code) => {
    if (code.toLowerCase() === 'onblur') {
      this.setState({ showDropdown: { ...this.state.showDropdown, category: false } })
      return;
    }
    const changeCode = Utils.isEmpty(code) ? this.state.fields.defaultCategoryId : code;
    this.setState({
      showDropdown: { ...this.state.showDropdown, category: !this.state.showDropdown.category },
      fields: { ...this.state.fields, defaultCategoryId: changeCode },
    })
  }

  onClickSave = () => {
    Utils.debug('saving settings')
    Notification.snackBar(texts.saving)
    // save state
    updateSettings(this.state.fields.defaultCategoryId);

    // todo: persist data        
    goBack();
  }


  onClickDeleteTasks = () => {
    Notification.dialog(
      texts.deleteTitle,
      texts.deleteTexts,
      this.deleteTasks,  // pass callback when confirm delete
      NotificationType.WARN);
  }

  /**@param {string} ans */
  deleteTasks = (ans) => {
    if (Utils.isEmpty(ans)) return;
    if (ans.toLowerCase() !== 'yes') return;

    // todo: call taskmanager
    Notification.snackBar('Deleting completed tasks...');
    goBack();

  }

  render() {

    return (
      <>
        <SettingsNavbar onClickSave={this.onClickSave} />

        <main className={[gStyles.colFlex, gStyles.form].join(' ')}>

          {/* content */}
          <section className={[gStyles.colFlex, gStyles.formSection].join(' ')}>

            <div className={[gStyles.rowFlex, gStyles.formSectionHeader].join(' ')}>
              <span>{texts.sectionHeader}</span>
            </div>

            <div className={[gStyles.colFlex, gStyles.formContent].join(' ')} >
              {/* category */}
              <div className={gStyles.formField}>
                <div className={[gStyles.rowFlex, gStyles.fieldLabel].join(' ')} >
                  <span>{texts.categoryLabel}</span>
                </div>
                <SelectList
                  selectedOption={new SelectOption(
                    this.state.fields.defaultCategoryId,
                    this.state.categoryList.find((c) => (c.id === this.state.fields.defaultCategoryId)).name,
                  )}
                  selectOptions={this.state.categoryList.map((c) => (new SelectOption(c.id, c.name)))}
                  isShow={this.state.showDropdown.category}
                  onSelect={this.onSelectCategory}
                />

              </div>

              {/* storage */}
              <div className={gStyles.formField}>

                <div className={[gStyles.rowFlex, gStyles.fieldLabel].join(' ')} >
                  <span>{texts.storageLabel}</span>
                </div>

                {/* storage bar */}
                <div className={styles.maxStorage}>
                  <div className={styles.currUsage} style={{ width: `${this.percentUsed}%` }}>{`${this.percentUsed}%`}</div>
                </div>

                <div className={styles.storageSection}>
                  <div >
                    {`${texts.maxStorage}: ${this.maxStorage} KB`}
                  </div>
                  <div >
                    {`${texts.currUsage}: ${this.currStorage} KB`}
                  </div>
                  <div >
                    {`${texts.percentUsage}: ${this.percentUsed}%`}
                  </div>

                </div>
              </div>

              {/* Delete completed tasks */}
              {/* <div className={[gStyles.rowFlex, styles.deleteTaskSection].join(' ')} >
                <span style={{ marginRight: '10px' }}>{texts.deleteCompletedLabel}</span>
                <div onClick={this.onClickDeleteTasks} className={gStyles.btnDefault}>{texts.deleteButton}</div>
              </div> */}


            </div>
          </section>
          <ImportExport />



        </main>


      </>

    )
  }

  // **************************** initialize

  /** @return {State} */
  initState = () => {
    const baseState = new State();

    const defaultCategoryId = getSettings();
    if (Utils.isNotEmpty(defaultCategoryId)) {
      baseState.fields.defaultCategoryId = defaultCategoryId;
    }
    baseState.fields = this.fieldsToSentinel(baseState.fields);

    baseState.categoryList = this.getCategoryList();

    return baseState;
  }

  /** replace sentinel value with empty string 
   * @param {Fields} inFields
   */
  fieldsToEmpty = (inFields) => {
    const fields = { ...inFields }; // clone
    if (fields.defaultCategoryId === Constants.SENTINEL_ID) fields.defaultCategoryId = '';
    return fields;
  }

  /** replace empty string with sentinel value
   * @param {Fields} inFields
   */
  fieldsToSentinel = (inFields) => {
    const fields = { ...inFields }; // clone
    if (Utils.isEmpty(fields.defaultCategoryId)) fields.defaultCategoryId = Constants.SENTINEL_ID;
    return fields;
  }

  /** 
* @return {Category[]} 
*/
  getCategoryList = () => {

    const allCategories = DataUtil.getCategoriesHierarchy();

    // convert each category to {id,name} object
    const categories = allCategories
      .filter((fc) => (DataUtil.isRoot(fc)))  // include only root categories
      .map((c) => {
        const cat = new Category();
        cat.id = c.categoryId;
        cat.name = ViewHelper.getCategoryDisplayText(c);
        return cat;
      })

    // add none as first element
    const none = new Category();
    none.id = Constants.SENTINEL_ID;
    none.name = texts.none;
    categories.unshift(none);

    return categories;
  }



}



