import React from 'react';
import { StateManager } from '../../stateManager/StateManager';
import * as PageManager from '../../navigation/PageManager'
import * as page from '../../util/PageConstants';

import * as DataManager from '../../data/PersistenceManager'
import * as MediaQueryManager from '../../navigation/MediaQueryManager'

// pages
import Home from '../home/Home';
import EditInfo from '../editInfo/EditInfo';
import Search from '../search/Search'
import ViewByCategory from '../viewByCategory/ByCategory'
import ViewByExpertise from '../viewByExpertise/ByExpertise'
import ViewByImportance from '../viewByImportance/ByImportance'
import ViewByLabel from '../viewByLabel/ByLabel'
import SetupCategory from '../category/SetupCategory'
import EditCategory from '../category/EditCategory'
import SetupLabel from '../label/SetupLabel'
import EditLabel from '../label/EditLabel'
import Settings from '../settings/Settings'
import ImageSearch from '../../components/imageSearch/ImageSearch'
import About from '../info/About'
import HowTo from '../info/HowTo'
import Tips from '../info/Tips'

import { Utils } from '../../util/Utils';
import gStyles from '../../App.module.css'
import texts from '../../resources/texts'

import {Snackbar} from '../../components/notification/Snackbar'
import { Notification } from '../../components/notification/Notification';
import { Dialog } from '../../components/notification/Dialog';
import { ShortcutMenu } from '../../components/shortcutMenu/ShortcutMenu';
import { browserBack } from '../../navigation/PageManager';

/** 
 * the main page that renders the current page
 * 
 * listens to any state changes from Redux and propagate to children pages
 * 
 */
export default class Page extends React.Component {
    constructor(props) {
        super(props);
        if (!DataManager.isStorageAvailable()) {
            throw new Error(texts.localStorageError)
        }
        if (Utils.isEmpty( DataManager.checkUserLogin() ) ) {
            Utils.warn('Login user not found, returning to apps list!');
            window.location.href=page.LIST_APP;
            return;
        }
        DataManager.retrieveAll();  // will fetch all cells and put to store
        PageManager.goToPage(page.HOME);    // display the initial page

        this.dialogRef = React.createRef();  // reference for dialog box so it can receive focus when displayed
    }
    componentDidMount() {
        StateManager.store.subscribe(() => this.forceUpdate());  // re-render page when state changes
        MediaQueryManager.initMediaQueries();

        // capture browser back button
        window.history.pushState({}, '', page.ROOT_APP);
        window.addEventListener('popstate', browserBack, false);

        // global error handler
        window.onerror = (errMsg) => {
            console.error('MyBrainMate Info - Global Error: ', errMsg);
            return true;  // suppress error?
        }
    }

    componentWillUnmount() {
        MediaQueryManager.cleanupMediaQueries();
        window.removeEventListener('popstate', browserBack, false);
    }

    componentDidUpdate() {
        if (Utils.isNotEmpty(Notification.getDialogMessage().content) ) {
            this.dialogRef.current.focus();  // set focus on dialog
        }
    }

    render() {
        const max = PageManager.callStack.length - 1;
        return (
            <>
                {   // mount only the last page, the rest in the callstack is hidden
                    PageManager.callStack.map((p, i) => (
                        <div key={i} className={i === max ? gStyles.show : gStyles.hide}>
                            {this.getPageComponent(p)}
                        </div>
                    ))
                }

                <ShortcutMenu />

                {/* snackbar shows up only when there's a message */}
                <div style={{display:Utils.isEmpty(Notification.getSnackBarMessage()) ? 'none' : 'block'}}>
                    <Snackbar msg={Notification.getSnackBarMessage()}/>
                </div>

                {/* dialog shows up only when there's a message */}
                <div style={{display:Utils.isEmpty(Notification.getDialogMessage().content) ? 'none' : 'block'}}>
                    <Dialog 
                        title={Notification.getDialogMessage().title}
                        content={Notification.getDialogMessage().content}
                        onClose={Notification.getDialogMessage().onClose}
                        focusRef={this.dialogRef}   // pass the ref to get focus
                    />
                </div>

            </>
        )
    }


    /**@param {string} pageName */
    getPageComponent = (pageName) => {
        switch (pageName) {
            case page.HOME                  : return <Home />
            case page.EDIT_INFO             : return <EditInfo />
            case page.SEARCH                : return <Search />
            case page.VIEW_BY_CATEGORY      : return <ViewByCategory />
            case page.VIEW_BY_EXPERTISE     :  return <ViewByExpertise />
            case page.VIEW_BY_IMPORTANCE    : return <ViewByImportance />
            case page.VIEW_BY_LABEL         : return <ViewByLabel />
            case page.SETUP_CATEGORY        : return <SetupCategory />
            case page.EDIT_CATEGORY         : return <EditCategory />
            case page.SETUP_LABEL           : return <SetupLabel />
            case page.EDIT_LABEL            : return <EditLabel />
            case page.SYSTEM                : return <Settings />
            case page.SEARCH_ICON           : return <ImageSearch />
            case page.ABOUT                 : return <About />
            case page.HOW_TO                : return <HowTo />
            case page.TIPS_TRICKS           : return <Tips />
            default: return <Home />
        }
    }
}