import React from 'react';
import gStyles from '../../App.module.css';

import texts from './SettingsTexts'
import * as icon from '../../util/IconConstants';

import '../../resources/sprites.css';

import * as size from '../../util/SizeConstants';


import { goBack } from '../../navigation/PageManager';

/**
 *  Settings Nav bar - must be stateless
 * 
 *  by Ludwin 2019/11/17
 */ 

 /**
 * @typedef {Object} Props
 * @property { ()=>void} onClickSave
 */


/** @param {Props} props */
export const SettingsNavbar = (props) => (
    <>
        <nav className={[gStyles.colFlex, gStyles.navBar].join(' ')}>

            {/* horizontal menu */}
            <div className={[gStyles.rowNowrapFlex].join(' ')}>

                {/* back button */}
                <span style={{ paddingLeft: size.NORMAL_SPACER }}>
                    <button onClick={goBack} className={[icon.BACK_WHITE, gStyles.btnIcon].join(' ')}></button>
                </span>

                {/* title */}
                <span style={{ paddingLeft: size.NORMAL_SPACER, whiteSpace:'nowrap' }}>{texts.title}</span>

            {/* right side actions */}
            <div className={[gStyles.splitRight].join(' ')} >

                {/* save */}
                <span style={{ paddingRight: size.NORMAL_SPACER }}>
                    <button onClick={props.onClickSave} className={[icon.SAVE_WHITE, gStyles.btnIcon].join(' ')}></button>
                </span>

            </div>


            </div>
        </nav>
    </>
);




