import * as size from '../util/SizeConstants';
import * as MediaQueryManager from './MediaQueryManager'

import * as icon from '../util/IconConstants';
import gTexts from '../resources/texts'


export class MenuProps {
    constructor() {
        /**@type {boolean} */ this.isShowMenuOptions = true;
        /**@type {boolean} */ this.isPageVertical = true;
        /**@type {boolean} */ this.isExpandAll = true;
        /**@type {boolean} */ this.hasFilter = false;
        /**@type { ()=>void} */ this.onClickBack = null;
        /**@type { ()=>void} */ this.onClickCreate = null;
        /**@type { ()=>void} */ this.onClickSearch = null;
        /**@type { ()=>void} */ this.onClickMenuOptions = null;
        /**@type { ()=>void} */ this.onClickPaging = null;
        /**@type { ()=>void} */ this.onClickFilter = null;
        /**@type { ()=>void} */ this.onClickSettings = null;
        /**@type { ()=>void} */ this.onExpandAll = null;
        /**@type { ()=>void} */ this.onClickHome = null;
        /**@type { ()=>void} */ this.onSwitchView = null;
       
    }
}

// ***** define navigation icons

class BarIcon {
    /**
     * 
     * @param {string} icon // default icon
     * @param {()=>void} action 
     * @param {boolean} toggleFlag 
     * @param {string} altIcon // icon used when toggleFlag is false
     * @param {string} [toolTip]
     */
    constructor(icon, action, toggleFlag=true, altIcon='', toolTip='') {
        this.icon = icon;
        this.action = action;
        this.toggleFlag = toggleFlag;
        this.altIcon = altIcon;        
        this.toolTip = toolTip;
    }
}

/**@type {BarIcon[]} */
let navBarIcons = [];
let maxHorizontal = 0;
let maxVertical = 0;
/**@type {BarIcon} */
let showMenuIcon = null; // toggle vertical menu

/**@param {MenuProps} props */
const updateBarIcons = (props) => {
    navBarIcons = [
        new BarIcon(icon.ADD_WHITE, props.onClickCreate),   // create info
        // new BarIcon(icon.SEARCH_WHITE, props.onClickSearch),    // search, comment out - already in shortcut menu
        new BarIcon(icon.PAGE_VERTICAL_WHITE, props.onClickPaging, props.isPageVertical, icon.PAGE_HORIZONTAL_WHITE, gTexts.toggleScrollTip), // paging
        // new BarIcon(icon.HIDE_COMPLETED_WHITE, props.onShowCompleted, props.isShowCompleted, icon.SHOW_COMPLETED_WHITE, gTexts.toggleCompletedTip),  // show completed
        new BarIcon(icon.FILTER_SELECTED_WHITE, props.onClickFilter, props.hasFilter, icon.FILTER_WHITE, gTexts.filterTip), // filter
        new BarIcon(icon.COLLAPSE_ALL_WHITE, props.onExpandAll, props.isExpandAll, icon.EXPAND_ALL_WHITE, gTexts.toggleExpandAllTip), // expand all
        // new BarIcon(icon.HOME_WHITE, props.onClickHome),  // home
        new BarIcon(icon.SWITCH_VIEWS_WHITE, props.onSwitchView, true, '', gTexts.switchViewTip), // other views
        // new BarIcon(icon.SETTINGS_WHITE, props.onClickSettings, true, '', gTexts.settingsTip),  // settings
    ]
    showMenuIcon = new BarIcon(icon.COLLAPSE_WHITE, props.onClickMenuOptions, props.isShowMenuOptions, icon.EXPAND_WHITE, gTexts.menuOptionTip);
}

/**
 * @param {MenuProps} props 
 * @returns {BarIcon[]}
 */
export const getHorizontalMenu = (props) => {
    updateBarIcons(props);
    const currDevice = MediaQueryManager.getDevice();
    const maxLength = navBarIcons.length;
    switch (currDevice) {
        case size.SMARTPHONE_SMALL:
            maxHorizontal = 0;
            break;
        case size.SMARTPHONE_MEDIUM:
            maxHorizontal = 2;
            break;
        case size.SMARTPHONE_LARGE:
            maxHorizontal = 5;
            break;
        case size.TABLET_SMALL:
        case size.TABLET_MEDIUM:
        case size.DESKTOP_LAPTOP:
        case size.DESKTOP_LARGE:
            maxHorizontal = maxLength;
            break;
        default:
            maxHorizontal = 0;
    }
    maxVertical = maxLength - maxHorizontal;
    maxVertical = maxVertical < 0 ? 0 : maxVertical;

    let  maxHorizontalIcons =  navBarIcons.slice(0, maxHorizontal);  
    if (maxVertical > 0) {
        maxHorizontalIcons.push(showMenuIcon);  // add toggle menu
    }
    return maxHorizontalIcons;
}

export const getVerticalMenu = () => {
    if (maxVertical <= 0) return [];
    const maxVerticalIcons = navBarIcons.slice(maxHorizontal);
    const updatedIcons =  maxVerticalIcons.map( (i) => {
        const updatedIcon = new BarIcon(
            i.icon,
            ()=>onClickMenu(i.action),  // update the action to include closing the menu
            i.toggleFlag,
            i.altIcon,
            i.toolTip
        );
        return updatedIcon;
    })
    return updatedIcons;
}

/**
 * close the vertical menu options and execute handler
 * @param { ()=>void} clickHandler
 */
const onClickMenu = (clickHandler) => {
    showMenuIcon.action();  // close
    clickHandler();
}
