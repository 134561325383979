import React from 'react';
import globalTexts from '../../resources/texts';
import gStyles from '../../App.module.css';
import '../../resources/sprites.css';
import * as icon from '../../util/IconConstants'
import * as size from '../../util/SizeConstants'
import texts from '../../resources/texts';

// Home Page Header - must be stateless
export const HomeHeader = (props) => (
    <>
        <nav className={[gStyles.rowNowrapFlex, gStyles.navBar].join(' ')} >

            {/* title */}
            <span style={{ paddingLeft: size.NORMAL_SPACER }}>{globalTexts.appTitle}</span>
            
            {/* right side actions */}
            <div className={[gStyles.splitRight].join(' ')} >

                {/* search button, marginLeft:'auto' will justify the rest to the right */}
                <span style={{ paddingRight: size.NORMAL_SPACER }}>
                    <button 
                        onClick={props.onClickSearch} 
                        className={icon.SEARCH_WHITE + " " + gStyles.btnIcon}
                        title={texts.searchTip}
                    ></button>
                </span>

                {/* add button */}
                <span style={{ paddingRight: size.NORMAL_SPACER }}>
                    <button 
                        onClick={props.onClickCreate} 
                        className={icon.ADD_WHITE + " " + gStyles.btnIcon}
                        title={texts.createTip}
                    ></button>
                </span>

            </div>
        </nav>


    </>
);


