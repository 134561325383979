import React from 'react';

import {StateManager} from '../../stateManager/StateManager'
import * as DataManager from '../../data/InfoManager'
import {InfoCell} from '../../data/InfoCell';

import {EditInfoNavbar} from './EditInfoNavbar'
import { EditInfoView, Section } from './EditInfoView';

import * as PageManager from '../../navigation/PageManager';
import * as page from '../../util/PageConstants'
import * as InfoConstants from '../../util/InfoConstants'
import texts from './EditInfoTexts'

import * as DateUtil from '../../util/DateUtil';
import {isEmpty, Utils} from '../../util/Utils'

import {State, Category, Label, Errors, ShowInfo} from './InfoState'  // alias for the State type
import dayjs from 'dayjs';
import * as DataUtil from '../../data/DataUtil'
import * as ViewHelper from '../../util/ViewHelper'
import * as LabelManager from '../../data/LabelManager'
import { Notification, NotificationType } from '../../components/notification/Notification';
import { Calendar } from '../../components/calendar/Calendar';

Errors.toString();    // suppress warning

/**
 * @typedef {Object} Props - create an alias for props
 */


/** @type {State} */
/** @extends {React.Component<Props, State>} */
export default class EditInfo extends React.Component {

    /** @param {Props} props */
    constructor(props) {
        super(props); 

        this.origInfoCell = null;
        this.origStateFields = '';
        this.currCellId = PageManager.getCellId();
        this.currCategoryId = PageManager.getCategoryId();

        // initialize state
        /**@type {State} */
        this.state = this.initState();

    }
    
    /** @type {Errors}  */    
    errors = {name:'', category:'', startDate:'', endDate:'', title:'', content:''};  // local object to track errors and save to state as needed

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.categoriesLastUpdated !== StateManager.getData().categories.lastUpdated) {
            this.setState({
                categoriesLastUpdated: StateManager.getData().categories.lastUpdated,
                categoryList: this.getCategoryList(),
                });
        }
        if (this.state.labelsLastUpdated !== StateManager.getData().labels.lastUpdated) {
            this.setState({
                labelsLastUpdated: StateManager.getData().labels.lastUpdated,
                labelList: this.getLabelList(this.state),
                });
        }
    }

    render() {
        return (
            <>
                <EditInfoNavbar
                    currInfoId      = {this.currCellId}
                    onClickBack     = {this.onClickBack}
                    onClickSave     = {this.onClickSave}
                    onClickSaveNew  = {this.onClickSaveNew}
                    onClickDelete   = {this.onClickDelete}
                />
                <EditInfoView 
                    page                = {this.state}
                    onToggleSection     = {this.onToggleSection}
                    onInputChange       = {this.onInputChange}
                    onCreateCategory    = {this.onCreateCategory}
                    onCreateLabel       = {this.onCreateLabel}
                    onSelectCategory    = {this.onSelectCategory}
                    onSelectExpertise    = {this.onSelectExpertise}
                    onSelectImportance  = {this.onSelectImportance}
                    onSelectStartDate   = {this.onSelectStartDate}
                    onSelectEndDate     = {this.onSelectEndDate}
                    onClickStartDate    = {this.onClickStartDate}
                    onClickEndDate      = {this.onClickEndDate}
                    onClickLabel        = {this.onClickLabel}
                    onShowInfo          = {this.onShowInfo}
                    onCloseInfo         = {this.onCloseInfo}
                />
            </>
        )
    }

    // ******* map form fields to state

    /** @param {import('react').SyntheticEvent<HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement>} e */
    onInputChange = e => {
        const fields = Object.assign({}, this.state.fields);
        // if (e.currentTarget.name === 'labels') {
        //     this.updateLabels(e.currentTarget.value);
        // } else {
            fields[e.currentTarget.name] = e.currentTarget.value;   // use currentTarget instead of target object
            this.setState({fields}, this.postChange);
        // }
    };

    /** @param {string} code */
    onSelectCategory = (code) => {
        if (code.toLowerCase() === 'onblur') {
            this.setState({ showDropdown: { ...this.state.showDropdown, category: false } })
            return;
        }
        const changeCode = isEmpty(code) ? this.state.fields.categoryId : code;
        this.setState({
            showDropdown: { ...this.state.showDropdown, category: !this.state.showDropdown.category },
            fields: { ...this.state.fields, categoryId: changeCode },
        })
    }


    /** @param {string} code */
    onSelectExpertise = (code) => {
        if (code.toLowerCase() === 'onblur') {
            this.setState({ showDropdown: { ...this.state.showDropdown, expertise: false } })
            return;
        }
        const changeCode = isEmpty(code) ? this.state.fields.expertise : code;
        this.setState({
            showDropdown: { ...this.state.showDropdown, expertise: !this.state.showDropdown.expertise },
            fields: { ...this.state.fields, expertise: changeCode },
        })
    }

    /** @param {string} code */
    onSelectImportance = (code) => {
        if (code.toLowerCase() === 'onblur') {
            this.setState({ showDropdown: { ...this.state.showDropdown, importance: false } })
            return;
        }
        const changeCode = isEmpty(code) ? this.state.fields.importance : code;
        this.setState({
            showDropdown: { ...this.state.showDropdown, importance: !this.state.showDropdown.importance },
            fields: { ...this.state.fields, importance: changeCode },
        })
    }

    // ****************************************** dates

    /** @param {string} code */
    onSelectStartDate = (code) => {
        if (code.toLowerCase() === 'onblur') {
            this.setState({ showDropdown: { ...this.state.showDropdown, startDate: false } })
            return;
        }

        const changeDate = isEmpty(code) ? this.state.fields.startDate : this.deriveDate(code);
        const changeCode = isEmpty(code) ? this.state.dateSelector.startDate : code;
        this.setState({
            showDropdown: { ...this.state.showDropdown, startDate: !this.state.showDropdown.startDate },
            fields: { ...this.state.fields, startDate: changeDate },
            dateSelector: {...this.state.dateSelector, startDate:changeCode}
        }, this.postChange)

    }

    /** @param {string} startDate */
    onClickStartDate = (startDate) => {
        Calendar.showCalendar(startDate, this.onSelectCalendarStartDate, 'startDate')
    }

    /**@param {string} selectedDate */
    onSelectCalendarStartDate = (selectedDate) => {
        if (Utils.isNotEmpty(selectedDate)) {
            this.setState({ fields: { ...this.state.fields, startDate: selectedDate } }, this.postChange)
        }
    }

        /** @param {string} endDate */
    onClickEndDate = (endDate) => {
        Calendar.showCalendar(endDate, this.onSelectCalendarEndDate, 'endDate')
    }

    /**@param {string} selectedDate */
    onSelectCalendarEndDate = (selectedDate) => {
        if (Utils.isNotEmpty(selectedDate)) {
            this.setState({ fields: { ...this.state.fields, endDate: selectedDate } }, this.postChange)
        }
    }

    /** @param {string} code */
    onSelectEndDate = (code) => {
        if (code.toLowerCase() === 'onblur') {
            this.setState({ showDropdown: { ...this.state.showDropdown, endDate: false } })
            return;
        }

        const changeDate = isEmpty(code) ? this.state.fields.endDate : this.deriveDate(code);
        const changeCode = isEmpty(code) ? this.state.dateSelector.endDate : code;
        this.setState({
            showDropdown: { ...this.state.showDropdown, endDate: !this.state.showDropdown.endDate },
            fields: { ...this.state.fields, endDate: changeDate },
            dateSelector: {...this.state.dateSelector, endDate:changeCode}
        }, this.postChange)

    }

    /** @param {string} id */
    onClickLabel = (id) => {
        this.updateLabels(id);
    }    

    /** @param {string} id */
    updateLabels = (id) => {
        let labels2 = [...this.state.labelList];
        /** @type {string[]} */
        let labels3 = [];
        for (let l of labels2) {
            if (l.id === id) {
                l.isChecked = !l.isChecked; // toggle
            }
            if (l.isChecked) {
                labels3.push(l.id);
            }
        }
        this.setState({fields: {...this.state.fields, labels: labels3}});
    }

    postChange = () => {
        // update date selector when dates are updated
        if (!isEmpty(this.state.fields.startDate) && !isEmpty(this.state.fields.endDate)) {
            this.setState({dateSelector: {
                ...this.state.dateSelector, 
                startDate:InfoConstants.DateSelector.DATE,
                endDate:InfoConstants.DateSelector.DATE,
                } 
            });
        } else {
            if (!isEmpty(this.state.fields.startDate)) {
                this.setState({dateSelector: {...this.state.dateSelector, startDate:InfoConstants.DateSelector.DATE} });
            }
            if (!isEmpty(this.state.fields.endDate)) {
                this.setState({dateSelector: {...this.state.dateSelector, endDate:InfoConstants.DateSelector.DATE} });
            }
        }
        // update errors as user updates fields
        if ( this.hasError() ) {
            this.validate();
        }
    }

    /** @param {string} dateSelector */
    deriveDate = (dateSelector) => {
        let d = null;
        switch (dateSelector) {
            case InfoConstants.DateSelector.NONE:
                return ''
            case InfoConstants.DateSelector.TODAY:
                return DateUtil.toString(new Date());
            case InfoConstants.DateSelector.TOMORROW:
                d = DateUtil.addDays(new Date(), 1, 'day');
                return DateUtil.toString(d);
            default:
                return '';

        }        
    }


    // ************* handle form events

    /**@param {string} field */
    onShowInfo = (field) => { 
        const showInfo = new ShowInfo();
        switch (field) {
            case texts.categoryLabel    : showInfo.category = true; break;
            case texts.expertiseLabel    : showInfo.expertise = true; break;
            case texts.importanceLabel  : showInfo.importance = true; break;
            case texts.startDateLabel   : showInfo.startDate = true; break;
            case texts.endDateLabel     : showInfo.endDate = true; break;
            case texts.tagLabel         : showInfo.label = true; break;
            default                     : break;
        }
        this.setState({ showInfo: showInfo}) ;
    }
    
    onCloseInfo = () => {
        this.setState({ showInfo: new ShowInfo()}) ;
    }

    onClickBack = () => {
        PageManager.goBack();
    }

    onClickSave = () => {
        try {
            this.validate();
            if ( this.hasError() ) {
                Notification.snackBarError(texts.validationError);
            } else {
                if ( this.hasChanged() ) {
                    Notification.snackBarInfo(texts.save); 
                    this.save();    
                }
                PageManager.goBack();
            }                        
        } catch (error) {
            Utils.error('Error saving info!', error.message);
            Notification.snackBarError(`${texts.saveError} ${error.message}`, 10);
        }
    }

    onClickSaveNew = () => {
        try {
            this.validate();
            if ( this.hasError() ) {
                Notification.snackBarError(texts.validationError);
            } else {
                Notification.snackBarInfo(texts.saveNew);
                if ( this.hasChanged() ) {
                    this.save();
                }
                this.reset();
                this.setState(this.initState());
            }    
        } catch (error) {
            Utils.error('Error saving/creating info!', error.message);
            Notification.snackBarError(`${texts.saveError} ${error.message}`, 10);
        }
    }

    onClickDelete = () => {
        try {
            if (this.state.isNew) {
                PageManager.goBack();
            } else {
                Notification.dialog(
                    texts.deleteWarning.title, 
                    texts.deleteWarning.content,
                    this.delete,  // pass callback when confirm delete
                    NotificationType.WARN);
            }    
        } catch (error) {
            Utils.error('Error deleting info!', error.message);
            Notification.snackBarError(`${texts.deleteError} ${error.message}`, 10);
        }

    }

    /** @param {string} section */
    onToggleSection = (section) => {
        switch (section) {
            case Section.DESCRIPTION:
                this.setState( {expanded: {...this.state.expanded, description:!this.state.expanded.description} });
                break;
            case Section.DETAILS:
                this.setState( {expanded: {...this.state.expanded, details:!this.state.expanded.details} });
                break;
            case Section.DUE_DATES:
                this.setState( {expanded: {...this.state.expanded, dueDates:!this.state.expanded.dueDates} });
                break;
            case Section.LABELS:
                this.setState( {expanded: {...this.state.expanded, labels:!this.state.expanded.labels} });
                break;
            default:
                break;
        }
    }


    onCreateCategory = () => {
        PageManager.goToPage(page.SETUP_CATEGORY);
    }

    onCreateLabel = () => {
        PageManager.goToPage(page.SETUP_LABEL);
    }


    // ************* validations

    validate = () => {
        this.resetErrors()

        // empty name
        if (!this.state.fields.name?.trim()) {
            this.errors.name = texts.emptyNameError;
        }
        // empty category
        if (!this.state.fields.categoryId) {
            this.errors.category = texts.emptyCategoryError;
        }

        // date range error
        if (this.state.fields.startDate && this.state.fields.endDate) {
            const startDate = dayjs(this.state.fields.startDate);
            const endDate = dayjs(this.state.fields.endDate);
            if (startDate.isAfter(endDate) ) {
                this.errors.endDate = texts.dateRangeError;
            }
        }

        this.setState({ errors: this.errors });
    }

    resetErrors = () => {
        this.errors.name = '';
        this.errors.category = '';
        this.errors.startDate = '';
        this.errors.endDate = '';
        this.errors.title = '';
        this.errors.content = '';
    }

    /** @returns {boolean} */
    hasError = () => {
        if (!isEmpty(this.errors.name)) return true;
        if (!isEmpty(this.errors.category)) return true;
        if (!isEmpty(this.errors.startDate)) return true;
        if (!isEmpty(this.errors.endDate)) return true;
        if (!isEmpty(this.errors.title)) return true;
        if (!isEmpty(this.errors.content)) return true;
        return false;
    }

    // ******************************* save

    save = () => {
        Utils.debug('saving info...');
        try {
            const cell = this.stateToCell(this.state);
            DataManager.upsertCell(cell)                
        } catch (error) {            
            Utils.error('Error saving info!', error.message);
            Notification.snackBarError(`${texts.saveError} ${error.message}`, 10);
        }
        
    }

    /**
     * @return {boolean}
     */
    hasChanged = () => {
        const currStateFields = JSON.stringify(this.state.fields);
        return (this.origStateFields !== currStateFields)
    }

    // *************************** delete
    /**@param {string} ans */
    delete = (ans) => {
        try {
            if (Utils.isEmpty(ans)) return;
            if (ans.toLowerCase() !== 'yes') return;
    
            Notification.snackBarInfo(texts.delete);
            DataManager.deleteCell(this.state.fields.cellId);
            PageManager.goBack();
                
        } catch (error) {
            Utils.error('Error in deleting info! ', error.message);
            Notification.snackBarError(`${texts.deleteError} ${error.message}`, 10);
        }
    }


    // ************************************** initialization

    /** @return {InfoCell} */
    getInfoCell = () => {
        if (isEmpty(this.currCellId)) return null;

        const infoCell = DataManager.getCell(this.currCellId);
        return infoCell;
    }

    

    /** @return {State} */
    initState = () => {
        try {
            this.origInfoCell = this.getInfoCell();
            const baseState = new State();
    
            if ( !isEmpty(this.origInfoCell) ) {
                baseState.isNew = false;
                this.cellToState(this.origInfoCell,baseState);
            } else {
                baseState.isNew = true;
            }

            if (baseState.isNew) {
                baseState.expanded.description = true;  // only description tab is expanded
                baseState.expanded.details = true;
                baseState.expanded.dueDates = false;
                baseState.expanded.labels = false;
            }
    
            baseState.categoryList = this.getCategoryList();
            if (baseState.isNew && !isEmpty(baseState.categoryList)) {
                if (!isEmpty(this.currCategoryId)) {
                    baseState.fields.categoryId = this.currCategoryId;          // set category to the passed in id
                } else {
                    baseState.fields.categoryId = baseState.categoryList[0].id; // set category to the first item in the list
                }
            }
            if (baseState.isNew && this.state && this.state.fields.categoryId) {
                baseState.fields.categoryId = this.state.fields.categoryId; // copy previous category for save & new
            }
    
            baseState.labelList = this.getLabelList(baseState);
    
            this.saveOrigState(baseState);
            return baseState;            
        } catch (error) {
            Utils.error('Error in initializing state ', error.message);
            Notification.snackBarError('Error in initializing state!');
            return new State();
        }
    }

    reset = () => {
        PageManager.clearCellId(); // clear the passed id
        this.currCellId = ''; // clear the local id
        this.currCategoryId = '';
        this.origInfoCell = null;
    }


    /**@param {State} baseState */
    saveOrigState = (baseState) => {
        this.origStateFields = JSON.stringify(baseState.fields);  // save the state for diff later
    }

    /** 
     * @return {Category[]} 
     */
    getCategoryList = () => {

        const allCategories = DataUtil.getCategoriesHierarchy(); 

        // convert each category to {id,name} object
        const categories = allCategories.map((c) => {
            const cat = new Category();
            cat.id = c.categoryId;
            cat.name = ViewHelper.getCategoryDisplayText(c);  
            return cat;
        })

        return categories;
    }


    /** 
     * @param {State} baseState
     * @return {Label[]} 
     * 
    */
    getLabelList = (baseState) => {
        const allLabels = LabelManager.getAll();
        ViewHelper.sortLabels(allLabels);
        const labels = allLabels.map( (l) => {
            const label = new Label();
            label.id = l.labelId;
            label.name = l.name;
            return label;
        })
        
        // set checkbox
        for (let l of labels) {
            if (baseState.fields.labels.indexOf(l.id) >= 0) {
                l.isChecked = true;
            }
        }
        return labels;
    }

    /**
     * copy cell to state
     * @param {InfoCell} cell
     * @param {State} baseState
     */
    cellToState = (cell, baseState)  => {
        if (isEmpty(cell)) {
            Utils.warn('Cannot copy cell to state, cell is empty...');
        }
        baseState.fields.cellId     = cell.cellId;
        baseState.fields.categoryId = cell.categoryId;
        baseState.fields.name       = cell.name;
        baseState.fields.expertise  = cell.expertise;
        baseState.fields.importance = cell.importance;
        baseState.fields.notes      = cell.notes;
        baseState.fields.startDate  = cell.startDate;
        baseState.fields.endDate    = cell.endDate;
        baseState.fields.labels     = Array.from(cell.labels);
    }

    /**
     * copy state to cell
     * @param {State} currState
     * @return {InfoCell}
     */
    stateToCell = (currState)  => {
        /**@type {InfoCell} */ let cell = null;
        if (currState.isNew) {
            cell = new InfoCell();
        } else {
            cell = this.origInfoCell;
        }
        if (isEmpty(cell)) {
            Utils.warn('Cannot copy state to cell, cell is empty...');
            return null;
        }
        cell.cellId     = currState.fields.cellId;
        cell.categoryId = currState.fields.categoryId;
        cell.name       = currState.fields.name;
        cell.expertise   = currState.fields.expertise;
        cell.importance = currState.fields.importance;
        cell.notes      = currState.fields.notes;
        cell.startDate  = currState.fields.startDate;
        cell.endDate    = currState.fields.endDate;
        cell.labels     = [...new Set(currState.fields.labels)]; // convert to set to make it unique
        return cell;
    }

    
}