import React from 'react'
import styles from './Snackbar.module.css'
import { Notification, NotificationType } from './Notification'

/**
 * @typedef Props
 * @property {string} msg
 */
/**@param {Props} props */
export const Snackbar = (props) => (
    <div className={
        Notification.getSnackBarType() === NotificationType.INFO ? styles.snackbarInfo :
        Notification.getSnackBarType() === NotificationType.WARN ? styles.snackbarWarn :
        Notification.getSnackBarType() === NotificationType.ERROR ? styles.snackbarError :
        styles.snackbarInfo
        }>
        <span>{props.msg}</span>
    </div>
)
