import React from 'react';

import gStyles from '../../App.module.css';     // global styles
import pStyles from './ImageSearch.module.css';    // page-specific styles
import texts from './ImageSearchTexts';
import '../../resources/sprites.css'
import * as icon from '../../util/IconConstants'



/* 
    Presentation component for Search Image Page
    - should be stateless

*/


/**
 * @typedef {Object} Props
 * @property {string} searchText
 * @property {(e:import('react').SyntheticEvent<HTMLInputElement>)=>void} onInputChange
 * @property { ()=>void } onClear
 */

/** @param {Props} props */
export const SearchBar = (props) => (
        <div className={[gStyles.colFlex, pStyles.searchBar].join(' ')}>

            {/* search field */}
            <section className={[gStyles.rowNowrapFlex, pStyles.searchField].join(' ')}>
                <input type='text' placeholder={texts.searchHint} autoComplete='off' className={[pStyles.searchInput].join(' ')}
                    value={props.searchText} name={'searchText'} onChange={props.onInputChange}>
                </input>
                <button onClick={props.onClear} className={[gStyles.splitRight, icon.CROSS, gStyles.btnIcon].join(' ')}></button>
            </section>

        </div>
)




