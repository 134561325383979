import {StateManager} from './StateManager';
import {ActionType} from './ActionTypes'

export class CalendarStoreState {

    /**@type {string} */ inputDate = '';
    /**@type {string} */ targetField = '';


}

/** @type {CalendarStoreState} */
let initialState = new CalendarStoreState();


///////////////// actions

/**
 * @typedef {Object} Action 
 * @property {string} type
 * @property {string} [inputDate]
 * @property {string} [targetField]
 */

  /**
  * @param {string} inputDate 
  * @param {string} targetField 
  * @return {Action}
  */
 const updateCalendarDateAction = (inputDate, targetField) => {
    return {
        type: ActionType.UPDATE_CALENDAR,
        inputDate,
        targetField,
    }
 }


  /**
  * @return {Action}
  */
 const clearCalendarAction = () => {
    return {
        type: ActionType.CLEAR_CALENDAR,
    }
 }

 ///////////////// reducer

 /** 
  * @param {Action} action 
  * @return {CalendarStoreState}
  */
export let calendarReducer = (state = initialState, action) => {
    const newState = {...state}; 
    switch (action.type) {

        case ActionType.UPDATE_CALENDAR:
            newState.inputDate = action.inputDate;
            newState.targetField = action.targetField;
            return newState;
        case ActionType.CLEAR_CALENDAR:
            return new CalendarStoreState();
        default:
            return state;
    }
}

/////////////////////////// helper public functions

/** 
 * @param {string} inputDate
 * @param {string} [targetField]
 */
export const setCalendarDate = (inputDate, targetField='') => {
    StateManager.store.dispatch(updateCalendarDateAction(inputDate, targetField));
}

/** @returns {string}  */
export const getCalendarDate = () => {
    return StateManager.getData().calendar.inputDate;
}

/** @returns {string}  */
export const getTargetField = () => {
    return StateManager.getData().calendar.targetField;
}

export const clearCalendar = () => {
    StateManager.store.dispatch(clearCalendarAction());
}



