import * as NotificationStore from '../../stateManager/NotificationStore'


export class Notification {

    /**@returns {string} */
    static getSnackBarMessage = () => {
        return NotificationStore.getSnackbar().msg;
    }

    static getSnackBarType = () => {
        return NotificationStore.getSnackbar().type;
    }

    /**
     * display snackbar
     * 
     * @param {string} msg 
     * @param {number} [seconds]
     * @param {string} [type]
     */
    static snackBar = (msg, seconds=3, type=NotificationType.INFO) => {
        // set the msg to show the snackbar
        NotificationStore.setSnackbar(msg,type);     

        // clear the message after set time to hide the snackbar
        setTimeout(() => {
            NotificationStore.setSnackbar('',type);
        }, seconds*1000);
    }

    /**@param {string} msg */
    static snackBarInfo (msg, seconds=3)  {
        this.snackBar(msg, seconds);
    }

    /**@param {string} msg */
    static snackBarWarn (msg, seconds=5)  {
        this.snackBar(msg, seconds, NotificationType.WARN);
    }
    
    /**@param {string} msg */
    static snackBarError (msg, seconds=5)  {
        this.snackBar(msg, seconds, NotificationType.ERROR);
    }

    // ********************************************* Dialog
    /**
     * display confirmation dialog
     * 
     * @param {string} title 
     * @param {string} content 
     * @param {(ans:string)=>void} handler
     * @param {string} [type]
     */
    static dialog (title, content, handler, type=NotificationType.INFO)  {

        // set the msg to show the dialog
        NotificationStore.setDialog(title, content,type);     

        dialogHandler = handler;  // save the handler for later execution
    }
    
    /**@param {string} ans */
    static onClose(ans) {
        NotificationStore.clearDialog();  // this will hide the dialog
        dialogHandler(ans);  // execute the handler and pass the answer
    }


    /**
     * @typedef {Object} DialogMsg
     * @property {string} title
     * @property {string} content
     * @property {(ans:string)=>void} onClose
     */

     /**@returns {DialogMsg} */
     static getDialogMessage() {
        return {
            title: NotificationStore.getDialog().title,
            content: NotificationStore.getDialog().content,
            onClose: this.onClose,
        }
    }

    static getDialogType = () => {
        return NotificationStore.getDialog().type;
    }
    
}

/**
 * store the handler and execute when dialog closes
 * @param {string} ans 
 */
let dialogHandler = (ans) => {};


export const NotificationType = {
    INFO: 'INFO',
    WARN: 'WARN',
    ERROR: 'ERROR',
}