import { InfoCategory } from "../../data/InfoCategory";
import { InfoCell } from "../../data/InfoCell";

InfoCategory.toString();    // suppress warning
InfoCell.toString();        // suppress warning

export class GroupLevel {
    /**
     * 
     * @param {string} id 
     * @param {string} desc 
     */
    constructor(id, desc) {
        this.groupId = id;
        this.groupDesc = desc;
        this.isExpanded = true;
        this.categoryMap = new Map();

    }
}

export class CategoryLevel {

    /**@param {InfoCategory} category */
    constructor(category) {
        this.categoryId = category.categoryId;
        this.category = category;
        this.displayText = category.name;  // override with formatted text
        this.isExpanded = true;
        this.infoMap = new Map();
    }

}

export class InfoLevel {

    /**
     * 
     * @param {InfoCell} info 
     */
    constructor(info) {
        this.cellId = info.cellId;
        this.cell = info;

    }
}
