import * as InfoConstants from '../util/InfoConstants'


export class InfoCell {
        /**@type {string} */ cellType = 'INFO_CELL';
        /**@type {string} */ cellId = '';
        /**@type {string} */ name = '';
        /**@type {string} */ categoryId = '';
        /**@type {string} */ status = InfoConstants.Status.NOT_STARTED;
        /**@type {string} */ importance = InfoConstants.Importance.NORMAL;
        /**@type {string} */ expertise = InfoConstants.Expertise.NOT_APPLICABLE;
        /**@type {string} */ startDate = '';
        /**@type {string} */ endDate = '';
        /**@type {string} */ repeat = InfoConstants.RepeatSelector.NONE;
        /**@type {string} */ notes = '';
        /**@type {string[]} */    labels = [];  // convert inputs to set before setting back to array

    
}


