import React from 'react';

import { SetupLabelNavbar } from './SetupLabelNavbar';
import { SetupLabelView } from './SetupLabelView'

import { goToPage, goBack } from '../../navigation/PageManager'
import * as PageManager from '../../navigation/PageManager'
import * as page from '../../util/PageConstants'
import { log } from '../../util/Utils';
import { StateManager } from '../../stateManager/StateManager';
import * as LabelManager from '../../data/LabelManager'

import {sortLabels} from '../../util/ViewHelper'
import {InfoLabel} from '../../data/InfoLabel'

InfoLabel.toString(); // suppress warning

/**
 * Setup Label Page
 * - container component
 */

/**
 * @typedef {Object} Props
 * 
 * @typedef {Object} State
 * @property { InfoLabel[]} State.allLabels
 * @property {string} lastUpdated
 * 
 */

/** @extends {React.Component<Props, State>} */
export default class SetupLabel extends React.Component {

    /** @param {Props} props */
    constructor(props) {
        super(props);

        const labels = LabelManager.getAll();
        sortLabels(labels);
        /**@type {State} */
        this.state = {
            allLabels: labels,
            lastUpdated: '',
        };
    }

    componentDidMount() {
        // log ('Setup label did mount');
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.lastUpdated !== StateManager.getData().labels.lastUpdated) {
            const labels = LabelManager.getAll();
            sortLabels(labels);
            /**@type {State} */ const newState = {
                allLabels: labels,
                lastUpdated: StateManager.getData().labels.lastUpdated,
            }
            this.setState(newState);
        }
    }

    render() {
        log ('Setup label, render...');
        return (
            <>
                <SetupLabelNavbar
                    onClickBack={this.onClickBack}
                    onClickCreate={this.onClickCreate}
                    onClickHome={this.onClickHome}
                />
                <SetupLabelView
                    allLabels={this.state.allLabels}  
                    onSelect={this.onSelect}
                />


            </>
        )
    }

    /**@param {string} id */
    onSelect = (id) => {
        PageManager.setLabelId(id);
        PageManager.clearImageId();
        PageManager.goToPage(page.EDIT_LABEL);
    }

    onClickBack = (e) => {
        goBack();
    }

    onClickCreate = (e) => {
        PageManager.clearLabelId();
        PageManager.clearImageId();
        PageManager.goToPage(page.EDIT_LABEL);
    }

    onClickHome = (e) => {
        goToPage(page.HOME);
    }


}

