import React from 'react';
import {Provider} from 'react-redux';

import { StateManager } from './stateManager/StateManager';
import MainPage from './pages/main/MainPage';
import ExceptionHandler from './util/exception/ExceptionHandler'

StateManager.initStore();  // create redux store

function App() {
  return (
    <ExceptionHandler>
      <MainPage />
    </ExceptionHandler>
  );
}

const StatefulApp = () => (
  <Provider store={StateManager.store}>
    <App />
  </Provider>
);

export default StatefulApp;
