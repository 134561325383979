import * as LabelStore from './LabelStore'
import {InfoLabel} from './InfoLabel'
import {isEmpty, Utils} from '../util/Utils'
import {StateManager} from '../stateManager/StateManager'
import * as Constant from '../util/InfoConstants'

import * as PersistenceManager from './PersistenceManager'

InfoLabel.toString(); // suppress warning

/** @param {InfoLabel} label */
export const upsertLabel = (label) => {
    if ( isEmpty(label.labelId) ) {
        label.labelId = generateId(label);
    }
    Utils.debug('upsert label', label);
    LabelStore.upsertLabel(label);
    PersistenceManager.saveAll();  // todo: check race condition ??
}

/**
 * 
 * @param {string} labelId 
 */
export const deleteLabel = (labelId) => {
    LabelStore.deleteLabel(labelId);
    PersistenceManager.saveAll();
}

/**
 * 
 * @param {string} labelId 
 * @return {InfoLabel}
 */
export const getLabel = (labelId) => {

    /**@type {InfoLabel[]}*/
    const allLabels = StateManager.getData().labels.labels;
    /**@type {InfoLabel} */
    const label = allLabels.find( (c) => {
        return c.labelId === labelId
    })
    return label;
}

/**@return {InfoLabel[]} */
export const getAll = () => {
    return StateManager.getData().labels.labels;
}

/**@param {InfoLabel[]} labels */
export const insertAll = (labels) => {
    LabelStore.insertAll(labels);
}

/**
 * generate id based on user email and time
 * @param {InfoLabel} cell 
 * @return {string}
 */
const generateId = (cell) => {
    const now = new Date().getTime();  // UTC

    // format: email_INFO_LABEL_time
    const id = `${Constant.LOGIN_USER.USER_ID}${Constant.ID.LINK_CHAR}${Constant.ID.LABEL_ID_PREFIX}${Constant.ID.LINK_CHAR}${now}`;
    return id;
}




