
import * as InfoConstants from '../../util/InfoConstants'

export class Fields {

    /**@type {string} */ cellType = 'INFO_CATEGORY';        // REQUIRED !
    /**@type {string} */ categoryId = '';               // REQUIRED !
    /**@type {string} */ name = '';                         // REQUIRED !
    /**@type {string} */ parentCategoryId = InfoConstants.Category.ROOT;             // REQUIRED !

    /**@type {string} */ image = '';                // icon filename
    /**@type {string} */ backgroundColor = '';      // in #FFFFFF
    /**@type {string} */ fontColor = '';


}


export class Category {
    /**@type {string} */ id = '';
    /**@type {string} */ name = '';
}

export class Errors {
    /**@type {string} */ name = '';
    /**@type {string} */ parent = '';
    /**@type {string} */ sameColor = '';
    /**@type {string} */ title = '';
    /**@type {string} */ content = '';
}

export class Expanded {
    /**@type {boolean} */ description = true;
    /**@type {boolean} */ styling = true;

}

export class ShowDropdown {
    /**@type {boolean} */ category = false;
    /**@type {boolean} */ fontColor = false;
    /**@type {boolean} */ bgColor = false;
}

/**
 * alias for the State object to use as type for the component
 */
export class State {
    /**@type {Expanded}*/       expanded = new Expanded();
    /**@type {ShowDropdown}*/   showDropdown = new ShowDropdown();
    /**@type {Fields}*/         fields = new Fields();
    /**@type {Category[]}*/     categoryList = [];
    /**@type {Errors}*/         errors = new Errors();
    /**@type {boolean}*/        isNew = true;
}

