import * as CategoryStore from './CategoryStore'
import {InfoCategory} from './InfoCategory'
import {isEmpty, Utils} from '../util/Utils'
import {StateManager} from '../stateManager/StateManager'
import * as Constant from '../util/InfoConstants'

import * as PersistenceManager from '../data/PersistenceManager'
import * as DataUtil from '../data/DataUtil'
import * as InfoManager from './InfoManager'

InfoCategory.toString(); // suppress warning

/** @param {InfoCategory} category */
export const upsertCategory = (category) => {
    try {
        if ( isEmpty(category.categoryId) ) {
            category.categoryId = generateId(category);
        }
        // todo: save state here before upsert then add category - then save to localstorage for accuracy and avoid race/late condition
        CategoryStore.upsertCategory(category);
    
        PersistenceManager.saveAll();  // todo: check race condition ??
            
    } catch (error) {
        Utils.error('Error in CategoryManager in upserting category: ', error.message);
        throw error;  // rethrow and let the caller handles it
        
    }
}

/**
 * 
 * @param {string} categoryId 
 */
export const deleteCategory = (categoryId) => {
    try {
        // get descendants
        const descendants = DataUtil.getDescendants(getCategory(categoryId));

        deleteChildrenInfo(categoryId, descendants);
        
        deleteCategoryHierarchy(categoryId, descendants);

        PersistenceManager.saveAll();
        
    } catch (error) {
        Utils.error('Error in CategoryManager in deleting category: ', error.message);
        throw error;  // rethrow and let the caller handles it                
    }
}

/**
 * 
 * @param {string} categoryId 
 * @param {InfoCategory[]} descendants 
 */
const deleteChildrenInfo = (categoryId, descendants) => {
    // delete children info for descendants categories
    descendants.forEach( (d) => {
        const children = InfoManager.getChildren(d.categoryId);
        children.forEach( (c) => InfoManager.deleteCell(c.cellId));
    })
    // delete children info for parent category
    const children = InfoManager.getChildren(categoryId);
    children.forEach( (c) => InfoManager.deleteCell(c.cellId));
}

/**
 * 
 * @param {string} categoryId 
 * @param {InfoCategory[]} descendants 
 */
const deleteCategoryHierarchy = (categoryId, descendants) => {
    // deleting category descendants
    descendants.forEach( (d) => {
        CategoryStore.deleteCategory(d.categoryId);
    })
    // deleting category
    CategoryStore.deleteCategory(categoryId);

}

/**
 * 
 * @param {string} categoryId 
 * @return {InfoCategory}
 */
export const getCategory = (categoryId) => {

    /**@type {InfoCategory[]}*/
    const allCategories = StateManager.getData().categories.categories;
    /**@type {InfoCategory} */
    const infoCategory = allCategories.find( (c) => {
        return c.categoryId === categoryId
    })
    return infoCategory;
}

/**@return {InfoCategory[]} */
export const getAll = () => {
    return StateManager.getData().categories.categories;
}

/**@param {InfoCategory[]} categories */
export const insertAll = (categories) => {
    CategoryStore.insertAll(categories);
}

/**
 * generate id based on user email and time
 * @param {InfoCategory} cell 
 * @return {string}
 */
const generateId = (cell) => {
    const now = new Date().getTime();  // UTC

    // format: email_INFO_CATEGORY_time
    const id = `${Constant.LOGIN_USER.USER_ID}${Constant.ID.LINK_CHAR}${Constant.ID.CATEGORY_ID_PREFIX}${Constant.ID.LINK_CHAR}${now}`;
    return id;
}




