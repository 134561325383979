import React from 'react';

import {InfoLabel} from '../../data/InfoLabel'
import gStyles from '../../App.module.css';     // global styles
import pStyles from './Label.module.css';    // page-specific styles
import '../../resources/sprites.css'
import texts from './LabelTexts'

InfoLabel.toString(); // suppress warning
/* 
    Presentation component for Setup Label Page
    - should be stateless
*/


/**
 * @typedef {Object} Props
 * @property {InfoLabel[]} allLabels
 * @property {(id:string)=>void} onSelect
 */

/** @param {Props} props */
export const SetupLabelView = (props) => (
    <main className={[gStyles.colFlex, pStyles.labelForm].join(' ')}>

        {/* search results */}
        {props.allLabels.length > 0 &&
            <>
                <section className={[gStyles.colFlex, pStyles.resultSection].join(' ')}>
                    {
                        props.allLabels.map((l, i) => {
                            return (
                            <div key={i} className={[gStyles.rowNowrapFlex, pStyles.line].join(' ')}
                                style={{ backgroundColor: l.backgroundColor, color: l.fontColor }}
                                onClick={() => props.onSelect(l.labelId)}>
                                {l.image &&
                                    <span className={[gStyles.btnIcon].join(' ')} style={{ marginRight: '5px' }}>{l.image}</span>
                                }
                                <span className={pStyles.lineDescription}>{l.name}</span>
                            </div>
                            )
                        })
                    }
                </section>
            </>
        }

        {props.allLabels.length <= 0 &&
            <section className={[pStyles.emptySection].join(' ')}>
                <span>{texts.noLabels}</span>
            </section>
        }

    </main>
)



