export default {
    importExportHeader  : 'Import/Export',
    importDesc          : "Import allows you to restore the entire MyBrainMate Task data from external file. You can use import to restore your data during system crash, or if you lost your phone or device.",
    importTitle         : "Import file",
    exportDesc          : "Export allows you to save the entire MyBrainMate Task data to external file. It can serve as your backup. It's advisable to save this file to an external device or online storage. The file is encrypted and only MyBrainMate can decrypt the data.",
    exportTitle         : "Export file",

    importPrompt        : 'This will override your entire data from an external file. This operation cannot be undone. Do you want to continue?',
    exportPrompt        : 'This will save your data to your local device. Do you want to continue?',

    importButton        : 'Import...',
    exportButton        : 'Export...',

    importSuccess       : 'File imported successfuly and data uploaded to MyBrainMate Task.',
    exportSuccess       : 'File exported successfuly and data downloaded from MyBrainMate Task.',

    importFail          : 'File import failed!',
    exportFail          : 'File export failed!',
}