import React from 'react';
import gStyles from '../../App.module.css';

import texts from './ByImportanceTexts'
import * as icon from '../../util/IconConstants';

import '../../resources/sprites.css';

import * as size from '../../util/SizeConstants';

import {MenuProps, getHorizontalMenu, getVerticalMenu} from '../../navigation/MenuHelper'

MenuProps.toString();    // suppress warning

// Importance View Nav bar - must be stateless

/** @param {MenuProps} props */
export const ByImportanceNavbar = (props) => (
    <>
        <nav className={[gStyles.colFlex, gStyles.navBar].join(' ')}>

            {/* horizontal menu */}
            <div className={[gStyles.rowNowrapFlex].join(' ')}>

                {/* back button */}
                <span style={{ paddingLeft: size.NORMAL_SPACER }}>
                    <button onClick={props.onClickBack} className={[icon.BACK_WHITE, gStyles.btnIcon].join(' ')}></button>
                </span>

                {/* title */}
                <span style={{ paddingLeft: size.NORMAL_SPACER, whiteSpace:'nowrap' }}>{texts.viewTitle}</span>

                {/* right side actions */}
                <div className={[gStyles.rowNowrapFlex, gStyles.splitRight].join(' ')} >


                {getHorizontalMenu(props).map((iconButton, i) => { return (
                        <span key={i} style={{ paddingRight: size.NORMAL_SPACER }} >
                            <button title={iconButton.toolTip} onClick={iconButton.action}
                                className={[
                                    // i==0 ? gStyles.splitRight : '',
                                    iconButton.toggleFlag ? iconButton.icon : iconButton.altIcon,
                                    gStyles.btnIcon
                                ].join(' ')}
                            ></button>
                        </span>
                    )})
                    }
                </div>
            </div>

            {/* view options - vertical menu */}
            <div 
                className={[gStyles.colFlex, gStyles.subNavBar].join(' ')}  
                style={{display: props.isShowMenuOptions ? '' : 'none'}}
            >

                {getVerticalMenu().map((iconButton, i) => { return (
                    <span key={i} >
                        <button title={iconButton.toolTip} onClick={iconButton.action}
                            className={[
                                // i==0 ? gStyles.splitRight : '',
                                iconButton.toggleFlag ? iconButton.icon : iconButton.altIcon,
                                gStyles.btnIcon,
                            ].join(' ')}
                        ></button>
                    </span>
                )})
                }
            </div>
        </nav>
    </>
);




