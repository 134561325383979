import React from 'react';
import {HomeHeader} from './HomeHeader';
import {HomeView, Section} from './HomeView';
import * as PageManager from '../../navigation/PageManager';
import * as page from '../../util/PageConstants';
import { goToPage } from '../../navigation/PageManager';
import { Expanded } from './HomeState';


/**
 * Home Page
 * - container component
 */
export default class Home extends React.Component {

    // initialize expanded sections
    state = {
        expanded: new Expanded(),
    }

    componentDidMount() {
        // log ('Home did mount');
    }
    
    render() {
        return (
            <>
            <HomeHeader 
                onClickCreate = {this.onClickCreate}
                onClickSearch = {this.onClickSearch}
            /> 
            <HomeView
                expanded                = {this.state.expanded}
                onClickCreate           = {this.onClickCreate}
                onClickSearch           = {this.onClickSearch}
                onClickByCategory       = {this.onClickByCategory}
                onClickByExpertise      = {this.onClickByExpertise}
                onClickByImportance     = {this.onClickByImportance}
                onClickByLabel          = {this.onClickByLabel}
                onClickGotoApps         = {this.onClickGotoApps}
                onClickSetupCategories  = {this.onClickSetupCategories}
                onClickSetupLabels      = {this.onClickSetupLabels}
                onClickSettings         = {this.onClickSettings}
                onClickAbout            = {this.onClickAbout}
                onClickHowToUse         = {this.onClickHowToUse}
                onClickTipsTricks       = {this.onClickTipsTricks}
                onToggleSection         = {this.onToggleSection}
            />
            </>
        )
    }

    onClickCreate = () => {
        PageManager.goToInfoPage(page.EDIT_INFO, page.HOME) ;
    }
    
    onClickSearch = () => {
        PageManager.goToPage(page.SEARCH);
    }
    onClickByCategory = () => {
        PageManager.goToPage(page.VIEW_BY_CATEGORY);
    }
    onClickByExpertise = () => {
        PageManager.goToPage(page.VIEW_BY_EXPERTISE);
    }
    onClickByImportance = () => {
        PageManager.goToPage(page.VIEW_BY_IMPORTANCE);
    }
    onClickByLabel = () => {
        PageManager.goToPage(page.VIEW_BY_LABEL);
    }
    onClickGotoApps = () => {
        window.location.href = page.LIST_APP;
    }
    onClickSetupCategories = () => {
        PageManager.goToPage(page.SETUP_CATEGORY, page.HOME);
    }
    onClickSetupLabels = () => {
        PageManager.goToPage(page.SETUP_LABEL);
    }
    onClickSettings = () => {
      goToPage(page.SYSTEM)
  }
  onClickAbout = () => {
        goToPage(page.ABOUT);
    }
    onClickHowToUse = () => {
        goToPage(page.HOW_TO)
    }
    onClickTipsTricks = () => {
        goToPage(page.TIPS_TRICKS)
    }

    /**@param {string} section */
    onToggleSection = (section) => {
        switch (section) {
            case Section.CREATE:
                this.setState( {expanded: {...this.state.expanded, create:!this.state.expanded.create} });
                break;
            case Section.SEARCH:
                this.setState( {expanded: {...this.state.expanded, search:!this.state.expanded.search} });
                break;
            case Section.VIEW:
                this.setState( {expanded: {...this.state.expanded, view:!this.state.expanded.view} });
                break;
            case Section.APPS:
                this.setState( {expanded: {...this.state.expanded, apps:!this.state.expanded.apps} });
                break;
            case Section.SETUP:
                this.setState( {expanded: {...this.state.expanded, setup:!this.state.expanded.setup} });
                break;
            case Section.INFO:
                this.setState( {expanded: {...this.state.expanded, info:!this.state.expanded.info} });
                break;
            default:
                break;
        }
    }

}

