import React from 'react';

import gStyles from '../../App.module.css';     // global styles
import pStyles from './ImageSearch.module.css';    // page-specific styles
import '../../resources/sprites.css'

import { UserImage } from '../../util/ImageConstants'

UserImage.toString();  // dummy call for JS Doc typing

/* 
    Presentation component for Search Page
    - should be stateless
*/


/**
 * @typedef {Object} Props
 * @property {Map} groupImages
 * @property {(id:string)=>void} onSelect
 */

/** @param {Props} props */
export const SearchView = (props) => (
    <main className={[gStyles.colFlex, pStyles.searchForm].join(' ')}>

        {/* search results */}
        {props.groupImages.size > 0 &&
            <>
                <section className={[gStyles.colFlex, pStyles.resultSection].join(' ')} >
                    {displayImages(props.groupImages, props.onSelect)}
                </section>

            </>
        }

    </main>
)

/**
 * @param {Map<string,UserImage[]>} groupImages 
 * @param {(id:string)=>void} onSelect
 * @returns {import('react').ReactElement[]}
 */
const displayImages = (groupImages, onSelect) => {
    const elements = [];
    groupImages.forEach((images, key) => {
        elements.push(
            <div className={gStyles.colFlex} key={key}>
                <div className={[pStyles.groupHeader].join(' ')}>{key}</div>

                <div className={gStyles.rowFlex}>
                    {
                        images.map((img, i) => (
                            <div key={i} className={[gStyles.colFlex, pStyles.imageBox].join(' ')}
                                onClick={() => onSelect(img.id)}>
                                <button className={[img.id, gStyles.btnIcon].join(' ')} ></button>
                                <span>{img.name}</span>
                            </div>
                        ))
                    }

                </div>

            </div>
        )
    })
    return elements;

}




