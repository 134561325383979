import React from 'react';

import gStyles from '../../App.module.css';     // global styles
import pStyles from './ByExpertise.module.css';    // page-specific styles
import gTexts from '../../resources/texts'
import '../../resources/sprites.css'
import * as icon from '../../util/IconConstants'

import { isEmpty } from '../../util/Utils';
import { CategoryLevel, GroupLevel, InfoLevel } from './ViewData'
import * as ViewHelper from '../../util/ViewHelper'

CategoryLevel.toString();    // suppress warning
GroupLevel.toString();    // suppress warning
InfoLevel.toString();    // suppress warning

/* 
    Presentation component for Expertise View Page
    - should be stateless

    by Ludwin 2019/11/18
*/


/**
 * @typedef {Object} Props
 * @property {boolean} isShowMenuOptions
 * @property {boolean} isPageVertical
 * @property {Map} allGroups
 * @property {(groupId:string)=>void} onToggleGroup
 * @property {(groupId:string, catId:string)=>void} onToggleCategory
 * @property {(cellId:string)=>void} onSelect
 * @property {(e:import('react').SyntheticEvent<HTMLButtonElement>, categoryId:string)=>void} onCreateWithCategory
 */


/** @param {Props} props */
export const ByExpertiseView = (props) => (
    <main className={[
            props.isPageVertical ? gStyles.colFlex : gStyles.horizontalScroll,
            pStyles.mainView
            ].join(' ')}>

        {/* loop through hierarchies */}
        {props.allGroups.size > 0 &&
            <div>
                {buildGroup(props)}
            </div>
        }


    </main>
)

/**
 * build Group Level component
 * @param {Props} props 
 */
const buildGroup = (props) => {
    /**@type {GroupLevel[]} */ const groups = Array.from(props.allGroups.values());
    const groupView = groups.map((g, i) => {

        return (
            <div key={i} className={[
                    props.isPageVertical ? "" : gStyles.horizontalChild,
                    pStyles.groupSection].join(' ')} >
                <div className={[gStyles.rowFlex, pStyles.groupHeader].join(' ')} onClick={() => props.onToggleGroup(g.groupId)}>

                    {/* name */}
                    {g.groupDesc}

                    {/* expand */}
                    <button className={[
                        gStyles.splitRight, 
                        g.isExpanded ? icon.COLLAPSE_WHITE : icon.EXPAND_WHITE , 
                        gStyles.btnIcon,
                        ].join(' ')}></button>
                </div>

                <div className={pStyles.groupBody} style={{ display: g.isExpanded ? '' : 'none' }}>
                    {buildCategory(g, props)}
                </div>

            </div>
        )
    })
    return groupView;
}

/**
 * build category level component
 * @param {GroupLevel} group 
 * @param {Props} props
 */
const buildCategory = (group, props) => {
    /**@type {CategoryLevel[]} */ const categories = Array.from(group.categoryMap.values());
    ViewHelper.sortCategoryNames(categories);
    const categoryView = categories.map((c, i) => {
        return (
            <div key={i} className={[pStyles.categorySection].join(' ')} >
                <div 
                    onClick={() => props.onToggleCategory(group.groupId, c.categoryId)}
                    className={[gStyles.rowNowrapFlex, pStyles.categoryHeader].join(' ')}
                    style={{backgroundColor:c.category.backgroundColor, color:c.category.fontColor}}
                    >
                    {/* image */}
                    {!isEmpty(c.category.image) &&
                        <span className={gStyles.btnImg} style={{marginRight:'5px'}}>{c.category.image}</span>
                    }

                    {/* name */}
                    <span>{c.displayText}</span>

                    {/* add */}
                    <button 
                        title={gTexts.addToCategoryTip} onClick={(e)=>props.onCreateWithCategory(e, c.categoryId)}
                        className={[gStyles.splitRight, icon.ADD, gStyles.btnIcon].join(' ')} 
                        style={{marginRight:'20px'}}></button>

                    {/* expand */}
                    <button className={[
                        c.isExpanded ? icon.COLLAPSE : icon.EXPAND , 
                        gStyles.btnIcon,
                        ].join(' ')}></button>

                </div>
                <div className={pStyles.categoryBody} style={{ display: c.isExpanded ? '' : 'none' }}>
                    {buildInfo(c, props)}
                </div>

            </div>
        )
    })
    return categoryView;
}

/**
 * build info level component
 * @param {CategoryLevel} category 
 * @param {Props} props
 */
const buildInfo = (category, props) => {
    /**@type {InfoLevel[]} */ const infos = Array.from(category.infoMap.values());
    const infoView = infos.map((c, i) => {
        return (
            <div key={i}
                onClick={() => props.onSelect(c.cellId)}
                className={[gStyles.colFlex, pStyles.infoLine].join(' ')}>

                {/* name */}
                <span className={[pStyles.infoName].join(' ')} >
                    {c.cell.name}
                </span>

                {/* notes */}
                <span className={[pStyles.infoNotes].join(' ')} >
                    {c.cell.notes.substring(0,200)}
                </span>

            </div>
        )
    })
    return infoView;
}
