import React from 'react'
import gStyles from '../../App.module.css'
import styles from './FieldInfo.module.css'

/**
 * @typedef {Object} Props
 * @property {string} text
 * @property {boolean} isShow
 */

/**
 * shows info when clicking the label info button
 * will close when the field label loses focus (onblur)
 * 
 * @param {Props} props 
 */
export const FieldInfo = (props) => {
    return (
        <div 
            className={styles.infoContainer}>
            <div className={[
                    gStyles.colFlex,
                    styles.infoContent,
                    props.isShow ? '' : gStyles.hide,
                ].join(' ')}>

                {/* close */}
                <div className={[styles.infoHeader].join(' ')} >
                    <div className={[gStyles.splitRight].join(' ')}>&times;
                    </div>
                </div>

                {/* text */}
                <div className={styles.infoText}>{props.text}</div>
            </div>

        </div>
    )
}