import {StateManager} from './StateManager';
import {ActionType} from './ActionTypes'

export class SettingsStoreState {

    /**@type {string} */ defaultCategoryId = '';

}

/** @type {SettingsStoreState} */
let initialState = new SettingsStoreState();


///////////////// actions

/**
 * @typedef {Object} Action 
 * @property {string} type
 * @property {string} [defaultCategoryId]
 */

  /**
  * @param {string} defaultCategoryId 
  * @return {Action}
  */
 const updateSettingsAction = (defaultCategoryId) => {
    return {
        type: ActionType.UPDATE_SETTINGS,
        defaultCategoryId,
    }
 }


 ///////////////// reducer

 /** 
  * @param {Action} action 
  * @return {SettingsStoreState}
  */
export let settingsReducer = (state = initialState, action) => {
    const newState = {...state}; 
    switch (action.type) {

        case ActionType.UPDATE_SETTINGS:
            newState.defaultCategoryId = action.defaultCategoryId;
            return newState;
        default:
            return state;
    }
}

/////////////////////////// helper public functions

/** 
 * @param {string} defaultCategoryId
 */
export const updateSettings = (defaultCategoryId) => {
    StateManager.store.dispatch(updateSettingsAction(defaultCategoryId));
}

/** @returns {string}  */
export const getSettings = () => {
    return StateManager.getData().settings.defaultCategoryId;
}




