import {StateManager} from '../stateManager/StateManager';
import * as size from '../util/SizeConstants';
import {ActionType} from '../stateManager/ActionTypes'

// to update the current device based on media query

export class MediaQueryStoreState {
    /**@type {string} */ currDevice = size.SMARTPHONE_MEDIUM;  // default
}


/** @type {MediaQueryStoreState} */
let initialState = new MediaQueryStoreState();

///////////  actions


/**
 * @typedef {Object} Action 
 * @property {string} type
 * @property {string} [deviceId]     
 */


/**
 * 
 * @param {string} deviceId 
 * @return {Action}
 * 
*/
let updateDeviceAction = (deviceId) => {
    return {
        type: ActionType.UPDATE_DEVICE,
        deviceId,
    }
}

///////////// reducer

/** 
 * 
 * @param {Action} action 
 * @return {MediaQueryStoreState}
 * 
*/
export let mediaQueryReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionType.UPDATE_DEVICE:
            return { ...state, currDevice: action.deviceId };
        default:
            return state;
    }
}

///////////// helper public functions


/**@param {string} deviceId */
export let updateDevice = (deviceId) => {
    StateManager.store.dispatch(updateDeviceAction(deviceId));
}