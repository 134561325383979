import { InfoCategory } from "../data/InfoCategory"
import { InfoLabel } from "../data/InfoLabel"
import * as DataUtil from '../data/DataUtil'
import { CategoryLevel } from "../pages/viewByImportance/ViewData";
import * as Constants from './InfoConstants'
import gTexts from '../resources/texts'
import {isNotEmpty} from '../util/Utils'

InfoCategory.toString();    // suppress warning
InfoLabel.toString();        // suppress warning
CategoryLevel.toString();   // suppress warning

/**
 * @param {InfoCategory} category 
 * @returns {string}
 */
export const getCategoryDisplayText = (category) => {
    if (DataUtil.isRoot(category)) return category.name;

    let topAncestor = '', arrows='';

    const ancestors = DataUtil.getAncestors(category);
    if (isNotEmpty(ancestors)) {
        topAncestor = ancestors[ancestors.length-1].name;
        arrows = ' ' + ancestors.map(()=>'>').join('') + ' ';   // the no. of arrows equal the ancestors
    }
    const displayText = topAncestor + arrows + category.name;
    return displayText;
}

/**@param {CategoryLevel[]} categoryLevels */
export const sortCategoryNames = (categoryLevels) => {
    categoryLevels.sort( (a,b) => {
        const name1 = a.displayText.toLowerCase();
        const name2 = b.displayText.toLowerCase();
        if (name1 < name2) return -1;
        if (name1 > name2) return 1;
        return 0;
    });
}

/**@param {InfoLabel[]} labels */
export const sortLabels = (labels) => {
    labels.sort( (a,b) => {
        const name1 = a.name.toLowerCase();
        const name2 = b.name.toLowerCase();
        if (name1 < name2) return -1;
        if (name1 > name2) return 1;
        return 0;
    });
}

/**@param {string} code */
export const getStatusDescription = (code) => {
    switch (code) {
        case Constants.Status.NOT_STARTED: return gTexts.statusNotStarted
        case Constants.Status.IN_PROGRESS: return gTexts.statusInProgress
        case Constants.Status.DONE: return gTexts.statusDone
        case Constants.Status.WAITING: return gTexts.statusWaiting
        case Constants.Status.CANCELLED: return gTexts.statusCancelled
        default: return '';
    }
}

/**@param {string} code */
export const getPriorityDescription = (code) => {
    switch (code) {
        case Constants.Priority.VERY_HIGH: return gTexts.priorityVeryHigh
        case Constants.Priority.HIGH: return gTexts.priorityHigh
        case Constants.Priority.NORMAL: return gTexts.priorityNormal
        case Constants.Priority.LOW: return gTexts.priorityLow
        case Constants.Priority.VERY_LOW: return gTexts.priorityVeryLow
        default: return '';
    }
}

/**@param {string} code */
export const getImportanceDescription = (code) => {
    switch (code) {
        case Constants.Priority.VERY_HIGH: return gTexts.importanceVeryHigh
        case Constants.Priority.HIGH: return gTexts.importanceHigh
        case Constants.Priority.NORMAL: return gTexts.importanceNormal
        case Constants.Priority.LOW: return gTexts.importanceLow
        case Constants.Priority.VERY_LOW: return gTexts.importanceVeryLow
        default: return '';
    }
}

/**@param {string} code */
export const getExpertiseDescription = (code) => {
    switch (code) {
        case Constants.Expertise.EXPERT: return gTexts.expertiseExpert
        case Constants.Expertise.ADVANCED: return gTexts.expertiseAdvanced
        case Constants.Expertise.INTERMEDIATE: return gTexts.expertiseIntermediate
        case Constants.Expertise.BEGINNER: return gTexts.expertiseBeginner
        case Constants.Expertise.NOT_APPLICABLE: return gTexts.expertiseNotApplicable
        default: return '';
    }
}


/**@param {string} code */
export const getDateSelectorDescription = (code) => {
    switch (code) {
        case Constants.DateSelector.NONE: return gTexts.dateSelectorNone
        case Constants.DateSelector.TODAY: return gTexts.dateSelectorToday
        case Constants.DateSelector.TOMORROW: return gTexts.dateSelectorTomorrow
        case Constants.DateSelector.WEEK1: return gTexts.dateSelectorWeek1
        case Constants.DateSelector.WEEK2: return gTexts.dateSelectorWeek2
        case Constants.DateSelector.MONTH1: return gTexts.dateSelectorMonth1
        case Constants.DateSelector.DATE: return gTexts.dateSelectorDate
        default: return '';
    }
}

/**@param {string} code */
export const getRepeatDescription = (code) => {
    switch (code) {
        case Constants.RepeatSelector.NONE: return gTexts.repeatSelectorNone
        case Constants.RepeatSelector.DAILY: return gTexts.repeatSelectorDaily
        case Constants.RepeatSelector.WEEKLY: return gTexts.repeatSelectorWeekly
        case Constants.RepeatSelector.MONTHLY: return gTexts.repeatSelectorMonthly
        case Constants.RepeatSelector.YEARLY: return gTexts.repeatSelectorYearly
        default: return '';
    }
}
