import React from 'react';

import { InfoCell } from '../../data/InfoCell'
import gStyles from '../../App.module.css';     // global styles
import pStyles from './Search.module.css';    // page-specific styles
import texts from './SearchTexts';
import '../../resources/sprites.css'

import { isEmpty } from '../../util/Utils';

InfoCell.toString(); // suppress warning

/* 
    Presentation component for Search Results
    - should be stateless
*/


/**
 * @typedef {Object} Props
 * @property {InfoCell[]} matchCells
 * @property {string} searchText
 * @property {(id:string)=>void} onSelect
 */

/** @param {Props} props */
export const SearchResult = (props) => {
    return (
        <main className={[gStyles.colFlex, pStyles.searchForm].join(' ')}>

            {/* search results */}
            <section className={[gStyles.colFlex, pStyles.resultSection].join(' ')}>
                {
                    props.matchCells.map((c, i) => (
                        <div key={i} className={pStyles.line}
                            onClick={() => props.onSelect(c.cellId)}>
                            { highlight(c.name, props.searchText) }
                            { highlightNotes(c.notes, props.searchText) }
                        </div>
                    ))
                }
            </section>
            {/* total */}
            <section className={[pStyles.totalLine].join(' ')}>
                {props.matchCells.length
                    ? <span>{`${texts.searchTotal} ${props.matchCells.length}`}</span>
                    : <span>{texts.noMatch}</span>
                }
            </section>

        </main>
    )
}

/**
 * @param {string} origText
 * @param {string} searchText
 * @returns {import('react').ReactElement}
 */
const highlight= (origText, searchText) => {
    const i = origText.toLowerCase().indexOf(searchText.toLowerCase());
    const l = searchText.length;
    const left  = (i<0) ? origText : origText.substring(0,i);  // show origText if no match
    const mid   = (i<0) ? '' : origText.substring(i,i+l);
    const right = (i<0) ? '' : origText.substring(i+l);
    return <div>
        <span>{left}</span>
        <span className={pStyles.highLight}>{mid}</span>
        <span>{right}</span>
    </div> 
}

/**
 * @param {string} origNotes
 * @param {string} searchText
 * @returns {import('react').ReactElement}
 */
const highlightNotes= (origNotes, searchText) => {
    if (isEmpty(origNotes)) return null;
    const i = origNotes.toLowerCase().indexOf(searchText.toLowerCase());
    if (i<0) return null;

    const maxChars = 100;   // limit display to 100 characters for each side
    const l = searchText.length;
    let left = origNotes.substring(0,i);
    if (left.length > maxChars) {
        left = '...' + left.substring(left.length-maxChars);
    }
    
    let right = origNotes.substring(i+l);
    if (right.length > maxChars) {
        right = right.substring(0,maxChars) + '...';
    }
    return <div className={pStyles.notesSection}>
        <span>{left}</span>
        <span className={pStyles.highLight}>{searchText}</span>
        <span>{right}</span>
    </div> 
}



