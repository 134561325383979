import {InfoCell} from './InfoCell'
import {InfoCategory} from './InfoCategory'
import * as CategoryManager from '../data/CategoryManager'
import * as InfoConstants from '../util/InfoConstants'
import {logError, isEmpty} from '../util/Utils'

InfoCell.toString(); // suppress warning
InfoCategory.toString(); // suppress warning

 /**@type {number} */ const MAX_SAFE_CTR = 1000;  // fail-safe ctr to guard against infinite loop

/**@param {InfoCell[]} cells */
export const sort = (cells) => {
    cells.sort( (a,b) => {
        const name1 = a.name.toLowerCase();
        const name2 = b.name.toLowerCase();
        if (name1 < name2) return -1;
        if (name1 > name2) return 1;
        return 0;
    });
}


/**@param {InfoCategory[]} categories */
export const sortCategories = (categories) => {
    categories.sort( (a,b) => {
        const name1 = a.name.toLowerCase();
        const name2 = b.name.toLowerCase();
        if (name1 < name2) return -1;
        if (name1 > name2) return 1;
        return 0;
    });
}

// ******************** hierarchies

/** @returns {InfoCategory[]} */
export const getCategoriesHierarchy = () => {

    /**@type {InfoCategory[]} */
    const hierarchy = [];

    const rootCategories = getRootCategories();
    sortCategories(rootCategories);
    hierarchy.push(...rootCategories);
    
    /**@type {number} */ let ctr = 0;
    /**@type {number} */ let max = hierarchy.length;    // will grow as more categories are read

    while (ctr < max) {     // regular loop instead of recursion to avoid stack overflow
        if (ctr > MAX_SAFE_CTR) {
            logError('Exceeded maximum safe counter for loop!');
            break;
        }

        const category = hierarchy[ctr];
        const subCategories = getChildren(category.categoryId);
        sortCategories(subCategories);

        if ( !isEmpty(subCategories) ) {
            hierarchy.splice(hierarchy.indexOf(category)+1, 0, ...subCategories); // insert children next to their parent
        }

        ctr++;
        max = hierarchy.length; // update max to include new subcategories
    }

    return hierarchy;
}

/** 
 * @param {InfoCategory} child
 * @returns {InfoCategory[]} 
 */
export const getAncestors = (child) => {

    /**@type {InfoCategory[]} */
    const ancestors = [];
    if (isRoot(child)) return ancestors;    // don't include root

    let nextChild = child;

    /**@type {number} */ let ctr = 0;
    while (true) {     // regular loop instead of recursion to avoid stack overflow
        ctr++;
        if (ctr > MAX_SAFE_CTR) {
            logError('Exceeded maximum safe counter for loop!');
            break;
        }
        const parent = getParent(nextChild);
        if (isEmpty(parent)) break;

        ancestors.push(parent);

        if (isRoot(parent)) break;

        nextChild = parent;

    }
    return ancestors;
}

/** 
 * get the root category (top ancestor) of child
 * @param {InfoCategory} child
 * @returns {InfoCategory} 
 */
export const getTopAncestor = (child) => {

    if (isRoot(child)) return child;    
    const ancestors = getAncestors(child);
    if (!isEmpty(ancestors)) {
        return ancestors[ancestors.length-1]; // return the last one
    }
    return null;
}

/** 
 * @param {InfoCategory} parent
 * @returns {InfoCategory[]} 
 */
export const getDescendants = (parent) => {

    /**@type {InfoCategory[]} */
    const hierarchy = [];
    hierarchy.push(parent);

    /**@type {number} */ let ctr = 0;
    /**@type {number} */ let max = hierarchy.length;

    while (ctr < max) {     // regular loop instead of recursion to avoid stack overflow
        if (ctr > MAX_SAFE_CTR) {
            logError('Exceeded maximum safe counter for loop!');
            break;
        }

        const category = hierarchy[ctr];
        const subCategories = getChildren(category.categoryId);
        sortCategories(subCategories);

        if ( !isEmpty(subCategories) ) {
            hierarchy.splice(hierarchy.indexOf(category)+1, 0, ...subCategories); // insert children next to their parent
        }

        ctr++;
        max = hierarchy.length; // update max to include new subcategories
    }
    hierarchy.shift(); // remove self (first element)
    return hierarchy;
}

/**@returns {InfoCategory[]} */
const getRootCategories = () => {
    const allCategories = CategoryManager.getAll();
    const rootCategories = allCategories.filter( (c) => {
        return isRoot(c);
    });
    return rootCategories;
}

/**
 * @param {string} categoryId
 * @returns {InfoCategory[]} 
 */
const getChildren = (categoryId) => {
    const allCategories = CategoryManager.getAll();
    const children = allCategories.filter( (c) => {
        return c.parentCategoryId === categoryId
    });
    return children;
}

/**
 * @param {InfoCategory} category
 * @returns {boolean}
 */
export const isRoot = (category) => {
    return (category.parentCategoryId === InfoConstants.Category.ROOT)
}

/**
 * @param {InfoCategory} category
 * @returns {InfoCategory}
 */
const getParent = (category)  => {
    if (isRoot(category)) return category;
    const parent = CategoryManager.getCategory(category.parentCategoryId);
    if (isEmpty(parent)) {
        logError('Category has no parent', category.categoryId);
    }
    return parent;
}

