import * as InfoConstants from '../util/InfoConstants'


export class InfoCategory {

        // identification
        
        /**@type {string} */ cellType = 'INFO_CATEGORY';        // REQUIRED !
        /**@type {string} */ categoryId = '';               // REQUIRED !
        /**@type {string} */ name = '';                         // REQUIRED !
        /**@type {string} */ parentCategoryId = InfoConstants.Category.ROOT;             // REQUIRED !

        // styling

        /**@type {string} */ image = '';                // icon filename
        /**@type {string} */ backgroundColor = '';      // in #FFFFFF
        /**@type {string} */ fontColor = '';

    
}


