export default  {
    createHeader:   'Create',
    searchHeader:   'Search',
    viewHeader:     'Views',
    setupHeader:    'Setup',
    appsHeader:     'Switch App',
    infoHeader:     'Info',
    iWantTo:        '',
    createInfo:     'Create New Info',
    searchInfo:     'Search My Info',
    iWantToView:    '',
    byCategory:     'View by Category',
    byExpertise:     'View by Expertise',
    byImportance:   'View by Importance',
    byLabel:        'View by Label',
    gotoApps:       'Go to Apps list',
    iWantToSetup:   '',
    infoCategories: 'Info Categories',
    infoLabels:     'Info Labels',
    systemSettings: 'System Settings',
    iWantToKnow:    '',
    about:          'About MyBrainMate',
    howToUse:       'How to use MyBrainMate Info',
    tipsTricks:     'Tips & Tricks of MyBrainMate Info'
}