
/**
 * Redux action types are global so define all here in one place to avoid duplicates
 */

export const ActionType = {
    // page actions
    TO_PAGE:'TO_PAGE',
    BACK: 'BACK',
    SET_INFO_ID: 'SET_INFO_ID',
    SET_CATEGORY_ID: 'SET_CATEGORY_ID',
    SET_LABEL_ID: 'SET_LABEL_ID',
    SET_IMAGE_ID: 'SET_IMAGE_ID',

    // info actions
    UPSERT: 'UPSERT',
    READ: 'READ',
    DELETE:'DELETE',
    INSERT_ALL:'INSERT_ALL',
    CLEAR: 'CLEAR',
    
    // category actions
    UPSERT_CATEGORY: 'UPSERT_CATEGORY',
    READ_CATEGORY: 'READ_CATEGORY',
    DELETE_CATEGORY:'DELETE_CATEGORY',
    INSERT_ALL_CATEGORIES:'INSERT_ALL_CATEGORIES',
    CLEAR_CATEGORIES: 'CLEAR_CATEGORIES',

    // label actions
    UPSERT_LABEL: 'UPSERT_LABEL',
    DELETE_LABEL:'DELETE_LABEL',
    INSERT_ALL_LABELS:'INSERT_ALL_LABELS',

    // media query actions
    UPDATE_DEVICE: 'UPDATE_DEVICE',

    // notification actions
    UPDATE_SNACKBAR: 'UPDATE_SNACKBAR',

    UPDATE_DIALOG: 'UPDATE_DIALOG',
    UPDATE_DIALOG_ANSWER: 'UPDATE_DIALOG_ANSWER',

    // calendar actions
    UPDATE_CALENDAR: 'UPDATE_CALENDAR',
    CLEAR_CALENDAR: 'CLEAR_CALENDAR',

    // settings
    UPDATE_SETTINGS: 'UPDATE_SETTINGS',
}
