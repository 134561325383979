import { StateManager } from '../stateManager/StateManager';
import * as MediaStore from './MediaQueryStore';
import { Utils } from '../util/Utils';

import * as size from '../util/SizeConstants'


let matchSmartPhoneSmall  = null;
let matchSmartPhoneMedium = null;
let matchSmartPhoneLarge  = null;
let matchTabletSmall      = null;
let matchTabletMedium     = null;
let matchDesktopLaptop    = null;
let matchDesktopLarge     = null;


    // @ media listener

export const initMediaQueries = () => {
    Utils.debug('initializing media queries...')
    if (!window.matchMedia) {
        Utils.warn(`window.matchMedia not supported!, won't setup media queries`);
        return;
    }
    matchSmartPhoneSmall  = window.matchMedia(size.SMARTPHONE_SMALL);
    matchSmartPhoneMedium = window.matchMedia(size.SMARTPHONE_MEDIUM);
    matchSmartPhoneLarge  = window.matchMedia(size.SMARTPHONE_LARGE);
    matchTabletSmall      = window.matchMedia(size.TABLET_SMALL);
    matchTabletMedium     = window.matchMedia(size.TABLET_MEDIUM);
    matchDesktopLaptop    = window.matchMedia(size.DESKTOP_LAPTOP);
    matchDesktopLarge     = window.matchMedia(size.DESKTOP_LARGE);


    matchSmartPhoneSmall.addListener(smartPhoneSmall)
    matchSmartPhoneMedium.addListener(smartPhoneMedium)
    matchSmartPhoneLarge.addListener(smartPhoneLarge)
    matchTabletSmall.addListener(tabletSmall)
    matchTabletMedium.addListener(tabletMedium)
    matchDesktopLaptop.addListener(desktopLaptop)
    matchDesktopLarge.addListener(desktopLarge)

    // run listeners first time to establish device on page load
    smartPhoneSmall(matchSmartPhoneSmall);
    smartPhoneMedium(matchSmartPhoneMedium);
    smartPhoneLarge(matchSmartPhoneLarge);
    tabletSmall(matchTabletSmall);
    tabletMedium(matchTabletMedium);
    desktopLaptop(matchDesktopLaptop);
    desktopLarge(matchDesktopLarge);

}

export const cleanupMediaQueries = () => {
    Utils.debug('cleaning up media queries...')
    if (!window.matchMedia) {
        Utils.warn(`window.matchMedia not supported!, won't cleanup media queries`);
        return;
    }

    matchSmartPhoneSmall.removeListener(smartPhoneSmall)
    matchSmartPhoneMedium.removeListener(smartPhoneMedium)
    matchSmartPhoneLarge.removeListener(smartPhoneLarge)
    matchTabletSmall.removeListener(tabletSmall)
    matchTabletMedium.removeListener(tabletMedium)
    matchDesktopLaptop.removeListener(desktopLaptop)
    matchDesktopLarge.removeListener(desktopLarge)
}

// /** @param {MediaQueryListEvent} e*/
const smartPhoneSmall = (e) => {
    if (e.matches) {
        Utils.debug('smartPhoneSmall');
        MediaStore.updateDevice(size.SMARTPHONE_SMALL);
    }
}
const smartPhoneMedium = (e) => {
    if (e.matches) {
        Utils.debug('smartPhoneMedium');
        MediaStore.updateDevice(size.SMARTPHONE_MEDIUM);
    }
}
const smartPhoneLarge = (e) => {
    if (e.matches) {
        Utils.debug('smartPhoneLarge');
        MediaStore.updateDevice(size.SMARTPHONE_LARGE);
    }
}
const tabletSmall = (e) => {
    if (e.matches) {
        Utils.debug('tabletSmall');
        MediaStore.updateDevice(size.TABLET_SMALL);
    }
}
const tabletMedium = (e) => {
    if (e.matches) {
        Utils.debug('tabletMedium');
        MediaStore.updateDevice(size.TABLET_MEDIUM);
    }
}
const desktopLaptop = (e) => {
    if (e.matches) {
        Utils.debug('desktopLaptop');
        MediaStore.updateDevice(size.DESKTOP_LAPTOP);
    }
}
const desktopLarge = (e) => {
    if (e.matches) {
        Utils.debug('desktopLarge');
        MediaStore.updateDevice(size.DESKTOP_LARGE);
    }
}


export const getDevice = () => {
    return StateManager.getData().media.currDevice;
}



