/**
 * page ids used for navigation
 */

export const HOME               = 'HOME';
export const EDIT_INFO          = 'EDIT_INFO';
export const SEARCH             = 'SEARCH';
export const VIEW_BY_CATEGORY   = 'VIEW_BY_CATEGORY';
export const VIEW_BY_IMPORTANCE = 'VIEW_BY_IMPORTANCE';
export const VIEW_BY_EXPERTISE   = 'VIEW_BY_EXPERTISE';
export const VIEW_BY_LABEL      = 'VIEW_BY_LABEL';
export const EDIT_CATEGORY      = 'EDIT_CATEGORY';
export const SETUP_CATEGORY     = 'SETUP_CATEGORY';
export const EDIT_LABEL         = 'EDIT_LABEL';
export const SETUP_LABEL        = 'SETUP_LABEL';
export const SEARCH_ICON        = 'SEARCH_ICON';
export const SELECT_COLOR       = 'SELECT_COLOR';
export const FILTER             = 'FILTER';
export const SYSTEM             = 'SYSTEM';
export const ABOUT              = 'ABOUT';
export const HOW_TO             = 'HOW_TO';
export const TIPS_TRICKS        = 'TIPS_TRICKS';
export const ERROR              = 'ERROR';

// redirect to apps url
export const ROOT_APP           = '/';  // the path of this app
export const LIST_APP           = 'https://apps.mybrainmate.com';  // the path to apps list
