import React from 'react';
import gTexts from '../../resources/texts';
import texts from './LabelTexts';
import gStyles from '../../App.module.css';
import * as icon from '../../util/IconConstants';

import '../../resources/sprites.css';

import * as size from '../../util/SizeConstants';

// Setup Label Nav bar - must be stateless

/**
 * @typedef {Object} Props
 * @property { ()=>void} onClickBack
 * @property { ()=>void} onClickCreate
 * @property { ()=>void} onClickHome
 */


/** @param {Props} props */
export const SetupLabelNavbar = (props) => (
    <>
        <nav className={[gStyles.rowFlex, gStyles.navBar].join(' ')}>

            {/* back button */}
            <span style={{ paddingLeft: size.NORMAL_SPACER }}>
                <button onClick={props.onClickBack} className={[icon.BACK_WHITE, gStyles.btnIcon].join(' ')}></button>
            </span>

            {/* title */}
            <span style={{ paddingLeft: size.NORMAL_SPACER }}>{texts.setupLabelTitle}</span>

            {/* right side actions */}
            <div className={[gStyles.splitRight].join(' ')} >

                {/* add */}
                <span style={{ paddingRight: size.NORMAL_SPACER }}>
                    <button 
                        title={gTexts.createLabelTip}
                        onClick={props.onClickCreate} className={[icon.ADD_WHITE, gStyles.btnIcon].join(' ')}></button>
                </span>

                {/* home */}
                <span style={{ paddingRight: size.NORMAL_SPACER, display:'none' }}>  {/* hide for now, shortcut menu provides it */}
                    <button onClick={props.onClickHome} className={[icon.HOME_WHITE, gStyles.btnIcon].join(' ')}></button>
                </span>
            </div>
        </nav>


    </>
);


