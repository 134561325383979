import { createStore, combineReducers} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';

import * as Constants from '../util/InfoConstants';

// reducers
import {pageReducer} from '../navigation/PageStore';
import {dataReducer} from '../data/InfoStore';  // all cells
import {categoryReducer} from '../data/CategoryStore';  // all categories
import {labelReducer} from '../data/LabelStore';  // all labels
import {mediaQueryReducer} from '../navigation/MediaQueryStore'
import {notificationReducer} from './NotificationStore'
import {calendarReducer} from './CalendarStore'
import {settingsReducer} from './SettingsStore'

import {StateType} from './StateType';

StateType.toString();    // suppress warning

/** 
 * Manage state using Redux 
 * 
 * by Ludwin 2019/10/28
 */

export class StateManager {

    /**
     * the global storage for Redux state
     * don't access outside of this class, use only in the main page to subscribe during state update
     */
    static store = null;  // 

    /** create store */
    static initStore() {
        const reducer = combineReducers({
            page: pageReducer,
            cells: dataReducer,
            categories: categoryReducer,
            labels: labelReducer,
            media: mediaQueryReducer,
            notification: notificationReducer,
            calendar: calendarReducer,
            settings: settingsReducer,
        });

        // save store to class as static
        if (Constants.ENVIRONMENT==='prod') {
            StateManager.store  = createStore(reducer);  
        } else {
            // use only in development
            StateManager.store  = createStore(reducer, composeWithDevTools());
        }
        
    }


    /** 
     * use this function to get the redux state
     * @returns {StateType}
     */
    static getData() {
        return StateManager.store.getState();
    }

    /**
     * use this function to dispatch action to redux store
     */
    static dispatch(action) {
        StateManager.store.dispatch(action);
    }
}