import React from 'react'
import {SelectProps, SelectOption} from './SelectProps'
import styles from './Dropdown.module.css'
import gStyles from '../../App.module.css'
import * as icon from '../../util/IconConstants'
import {isEmpty} from '../../util/Utils'

SelectProps.toString(); // suppress warning
SelectOption.toString(); // suppress warning

/**
 * @typedef {Object} Category
 * @property {string} id
 * @property {string} name
 * 
 * @typedef {Object} CategoryProps
 * @property {string} categoryId
 * @property {Category[]} categories
 * @property {(code:string)=>void} onSelect
 * @property {boolean} isShow
 */

/**@param {CategoryProps} props */
export const SelectCategory = (props) => {
    /*
        use the onblur event to capture click outside of the dropdown and hide it, 
        pass the code 'onblur' to distinguish it from other dropdown values,
        set tabindex so that <div> will get focus
    */
   const selectedCategory = props.categories.find(c=>c.id===props.categoryId);
   const categoryName = isEmpty(selectedCategory) ? '(Unknown)' : selectedCategory.name;

   return (
    <div 
        onBlur={()=>props.onSelect('onblur')} tabIndex={0}  
        className={styles.selectContainer} >  

        {/* trigger element */}
        <div onClick={() => props.onSelect('')} 
            className={styles.selectTrigger}>
            <span> {categoryName} </span>
            <span className={[
                gStyles.splitRight,
                icon.DROPDOWN,
                gStyles.btnIcon
                ].join(' ')}
                >
            </span>
        </div>

        {/* dropdown */}
        <div
            className={[
            styles.selectContent,
            props.isShow ? '' : styles.hide,
            ].join(' ')}>

            {/* options */}
            {props.categories.map((option, i) => (
                <div key={i} onClick={() => props.onSelect(option.id)} 
                    className={[
                        styles.selectOption,
                        props.categoryId === option.id ? styles.selectOptionSelected : '',
                    ].join(' ')}>
                    <span style={{paddingLeft:`${levels(option.name)}px`}}>
                        {extractName(option.name)}
                    </span>
                </div>
            ))
            }
        </div>
    </div>
   )
}

/**@param {string} name */
const levels = (name) => {
    return (name.match(/>/g) || []).length * 10; // multiply each level by 10pixels
}

/**@param {string} name */
const extractName = (name) => {
    const names = name.split('>');
    if (names.length>1) {
        return names[names.length-1];  // get the last name
    }
    return name;
}

/**
 * custom dropdown component
 * 
 * @param {SelectProps} props 
 */
export const SelectList = (props) => (
    /*
        use the onblur event to capture click outside of the dropdown and hide it, 
        pass the code 'onblur' to distinguish it from other dropdown values,
        set tabindex so that <div> will get focus
    */
    <div 
        onBlur={()=>props.onSelect('onblur')} tabIndex={0}  
        className={styles.selectContainer} >  

        {/* trigger element */}
        <div onClick={() => props.onSelect('')} 
            className={styles.selectTrigger}>
            <span>
                {isEmpty(props.selectedOption.description) ? props.selectedOption.code : props.selectedOption.description}
            </span>
            <span className={[
                gStyles.splitRight,
                icon.DROPDOWN,
                gStyles.btnIcon
                ].join(' ')}
                >
            </span>
        </div>

        {/* dropdown */}
        <div
            className={[
            styles.selectContent,
            props.isShow ? '' : styles.hide,
            ].join(' ')}>

            {/* options */}
            {props.selectOptions.map((option, i) => (
                <div key={i} onClick={() => props.onSelect(option.code)} 
                    className={[
                        styles.selectOption,
                        props.selectedOption.code === option.code ? styles.selectOptionSelected : '',
                    ].join(' ')}>
                    {option.description}
                </div>
            ))
            }
        </div>
    </div>
)

/**
 * custom dropdown component, not full width (fit content)
 * 
 * @param {SelectProps} props 
 */
export const SelectFit = (props) => (
    /*
        use the onblur event to capture click outside of the dropdown and hide it, 
        pass the code 'onblur' to distinguish it from other dropdown values,
        set tabindex so that <div> will get focus
    */
    <div 
        onBlur={()=>props.onSelect('onblur')} tabIndex={0}  
        className={styles.selectContainerFit} >  

        {/* trigger element */}
        <div onClick={() => props.onSelect('')} 
            className={[gStyles.rowNowrapFlex, styles.selectTrigger].join(' ')}>
            <span>
                {isEmpty(props.selectedOption.description) ? props.selectedOption.code : props.selectedOption.description}
            </span>
            <span className={[
                icon.DROPDOWN,
                gStyles.btnIcon
                ].join(' ')}
                style={{display:'inline-block', marginLeft:'10px'}}
                >
            </span>
        </div>

        {/* dropdown */}
        <div
            className={[
            styles.selectContentFit,
            props.isShow ? '' : styles.hide,
            ].join(' ')}>

            {/* options */}
            {props.selectOptions.map((option, i) => (
                <div key={i} onClick={() => props.onSelect(option.code)} 
                    className={[
                        styles.selectOption,
                        props.selectedOption.code === option.code ? styles.selectOptionSelected : '',
                    ].join(' ')}>
                    {option.description}
                </div>
            ))
            }
        </div>
    </div>
)

/**
 * @typedef {Object} ColorProps
 * @property {string} colorCode
 * @property {(code:string)=>void} onSelect
 * @property {boolean} isShow
 */

/**
 * custom dropdown component, for selecting color
 * 
 * @param {ColorProps} props 
 */
export const SelectColor = (props) => (
    /*
        use the onblur event to capture click outside of the dropdown and hide it, 
        pass the code 'onblur' to distinguish it from other dropdown values,
        set tabindex so that <div> will get focus
    */
    <div 
        onBlur={()=>props.onSelect('onblur')} tabIndex={0}  
        className={styles.selectContainer} >  

        {/* trigger element */}
        <div onClick={() => props.onSelect('')} 
            className={styles.selectTrigger}
            style={{
                backgroundColor:props.colorCode,
                color: darkColors.includes(props.colorCode) ? 'white' : 'black',
                paddingLeft: '10px'
            }}
    >
            {/* todo: create variable at the top */}
            <span>
                {colors.find(c => c.code === props.colorCode).description} 
            </span>
            <span className={[
                gStyles.splitRight,
                icon.DROPDOWN,
                gStyles.btnIcon
                ].join(' ')}
                >
            </span>
        </div>

        {/* dropdown */}
        <div
            className={[
            styles.selectContent,
            props.isShow ? '' : styles.hide,
            ].join(' ')}>

            {/* options */}
            {colors.map((option, i) => (
                <div key={i} onClick={() => props.onSelect(option.code)} 
                    className={[
                        styles.selectOption,
                        props.colorCode === option.code ? styles.selectOptionSelected : '',
                    ].join(' ')}
                    style={{
                        backgroundColor:option.code,
                        color: darkColors.includes(option.code) ? 'white' : 'black',
                    }}
                    >
                    <span>{option.description}</span>
                </div>
            ))
            }
        </div>
    </div>
)

// change font color for these background for readability
const darkColors = ['#000000','#4B0082','#000080','#800000','#800080'];  

/**@type {SelectOption[]} */
const colors = [
    { description: '(None)', code: '' },
    { description: 'Aqua', code: '#00FFFF' },
    { description: 'Azure', code: '#F0FFFF' },
    { description: 'Beige', code: '#F5F5DC' },
    { description: 'Black', code: '#000000' },
    { description: 'Blue', code: '#0000FF' },
    { description: 'Blue Violet', code: '#8A2BE2' },
    { description: 'Brown', code: '#A52A2A' },
    { description: 'Chocolate', code: '#D2691E' },
    { description: 'Coral', code: '#FF7F50' },
    { description: 'Crimson', code: '#DC143C' },
    { description: 'Cyan', code: '#00FFFE' }, // change last value to avoid duplicate with aqua
    { description: 'Fuchsia', code: '#FF00FF' },
    { description: 'Gold', code: '#FFD700' },
    { description: 'Gray', code: '#808080' },
    { description: 'Green', code: '#008000' },
    { description: 'Green Yellow', code: '#ADFF2F' },
    { description: 'Fire Brick', code: '#B22222' },
    { description: 'Honey Dew', code: '#F0FFF0' },
    { description: 'Hot Pink', code: '#FF69B4' },
    { description: 'Indian Red', code: '#CD5C5C' },
    { description: 'Indigo', code: '#4B0082' },
    { description: 'Ivory', code: '#FFFFF0' },
    { description: 'Khaki', code: '#F0E68C' },
    { description: 'Lavender', code: '#E6E6FA' },
    { description: 'Lime', code: '#00FF00' },
    { description: 'Linen', code: '#FAF0E6' },
    { description: 'Magenta', code: '#FF00FE' }, // change last value to avoid duplicate with fuschia
    { description: 'Maroon', code: '#800000' },
    { description: 'Mint Cream', code: '#F5FFFA' },
    { description: 'Misty Rose', code: '#FFE4E1' },
    { description: 'Moccasin', code: '#FFE4B5' },
    { description: 'Navy', code: '#000080' },
    { description: 'Olive', code: '#808000' },
    { description: 'Orange', code: '#FFA500' },
    { description: 'Orange Red', code: '#FF4500' },
    { description: 'Orchid', code: '#DA70D6' },
    { description: 'Peach Puff', code: '#FFDAB9' },
    { description: 'Peru', code: '#CD853F' },
    { description: 'Pink', code: '#FFC0CB' },
    { description: 'Plum', code: '#DDA0DD' },
    { description: 'Purple', code: '#800080' },
    { description: 'Red', code: '#FF0000' },
    { description: 'Royal Blue', code: '#4169E1' },
    { description: 'Saddle Brown', code: '#8B4513' },
    { description: 'Salmon', code: '#FA8072' },
    { description: 'Sea Green', code: '#2E8B57' },
    { description: 'Sienna', code: '#A0522D' },
    { description: 'Silver', code: '#C0C0C0' },
    { description: 'SkyBlue', code: '#87CEEB' },
    { description: 'Snow', code: '#FFFAFA' },
    { description: 'Spring Green', code: '#00FF7F' },
    { description: 'Tan', code: '#D2B48C' },
    { description: 'Teal', code: '#008080' },
    { description: 'Thistle', code: '#D8BFD8' },
    { description: 'Tomato', code: '#FF6347' },
    { description: 'Turquoise', code: '#40E0D0' },
    { description: 'Violet', code: '#EE82EE' },
    { description: 'Wheat', code: '#F5DEB3' },
    { description: 'White', code: '#FFFFFF' },
    { description: 'Yellow', code: '#FFFF00' },

]