import React from 'react';

import gStyles from '../../App.module.css';     // global styles
import pStyles from './Category.module.css';    // page-specific styles
import texts from './EditCategoryTexts';
import '../../resources/sprites.css'
import * as icon from '../../util/IconConstants'

import { isEmpty } from '../../util/Utils';

import { State } from './CategoryState';

import {SelectColor, SelectCategory} from '../../components/dropDown/Dropdown'

State.toString();    // suppress warning

/**
 * Presentation component for Edit Info Page
 * - should be stateless
 * 
 * @author Ludwin Barbin,  2019-12-11
 * 
 */

export const Section = {
    DESCRIPTION: 'DESCRIPTION',
    STYLING: 'STYLING',
}

// should match those in the form state for proper mapping
const fieldName = {
    name: 'name',
    parentCategoryId: 'parentCategoryId',
    fontColor: 'fontColor',
    backgroundColor: 'backgroundColor',
    image: 'image',
}

/**
 * @typedef {Object} Props
 * @property {State} page
 * @property {(section:string)=>void} onToggleSection
 * @property {(e:import('react').SyntheticEvent<HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement>)=>void} onInputChange
 * @property {(e:import('react').SyntheticEvent<HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement>)=>void} onImageChange
 * @property { ()=>void } onClickAddImage
 * @property { ()=>void} onClickRemoveImage
 * @property { (code:string)=>void} onSelectFontColor
 * @property { (code:string)=>void} onSelectBackgroundColor
 * @property { (code:string)=>void} onSelectCategory
 * 
 * 
 */

/** @param {Props} props */
export const EditCategoryView = (props) => (
    <main className={[gStyles.colFlex, gStyles.form].join(' ')}>

        {/* description */}
        <section className={[gStyles.colFlex, gStyles.formSection].join(' ')}>
            <div className={[gStyles.rowFlex, gStyles.formSectionHeader].join(' ')} onClick={() => props.onToggleSection(Section.DESCRIPTION)}>
                <span>{texts.descriptionHeader}</span>
                <button className={[
                    gStyles.splitRight, 
                    props.page.expanded.description ? icon.COLLAPSE_WHITE : icon.EXPAND_WHITE, 
                    gStyles.btnIcon].join(' ')}></button>
            </div>
            <div className={[gStyles.colFlex, gStyles.formContent].join(' ')} style={{ display: props.page.expanded.description ? 'block' : 'none' }}>
                {/* name */}
                <div className={[gStyles.colFlex, gStyles.formField].join(' ')}>
                    <div className={gStyles.fieldLabel}>{texts.nameLabel}</div>
                    <input type='text' placeholder={texts.nameHint} className={gStyles.inputField}
                        value={props.page.fields.name} name={fieldName.name} onChange={props.onInputChange}  autoComplete='off'>
                    </input>
                    <div className={props.page.errors.name ? gStyles.errorField : gStyles.hide} >{props.page.errors.name}</div>
                </div>
                {/* parent */}
                <div className={[gStyles.colFlex, gStyles.formField].join(' ')}>
                    <div className={[gStyles.rowFlex, gStyles.fieldLabel].join(' ')} >
                        <div className={[gStyles.rowFlex].join(' ')} >
                            <span>{texts.parentLabel}</span>
                        </div>
                    </div>
                    {!isEmpty(props.page.categoryList) &&

                        <SelectCategory
                            categoryId={props.page.fields.parentCategoryId}
                            categories={props.page.categoryList}
                            onSelect ={props.onSelectCategory}
                            isShow={props.page.showDropdown.category}
                        />
                    }
                    <div className={props.page.errors.parent ? gStyles.errorField : gStyles.hide} >{props.page.errors.parent}</div>
                </div>
            </div>
        </section>

        {/* styling */}
        <section className={[gStyles.colFlex, gStyles.formSection].join(' ')} >
            <div className={[gStyles.rowFlex, gStyles.formSectionHeader].join(' ')} onClick={() => props.onToggleSection(Section.STYLING)}>
                <span>{texts.stylingHeader}</span>
                <button className={[
                    gStyles.splitRight, 
                    props.page.expanded.styling ? icon.COLLAPSE_WHITE : icon.EXPAND_WHITE, 
                    gStyles.btnIcon].join(' ')}></button>
            </div>
            <div className={[gStyles.colFlex, gStyles.formContent].join(' ')} style={{ display: props.page.expanded.styling ? 'block' : 'none' }}>

                {/* icon */}
                <div className={[gStyles.colFlex, gStyles.formField].join(' ')}>
                    <div className={gStyles.fieldLabel}>{texts.iconLabel}</div>
                    <div className={gStyles.rowFlex}>
                      <input type='text' placeholder={texts.iconHint} className={gStyles.inputField}
                          value={props.page.fields.image} name={fieldName.image} onChange={props.onImageChange} autoComplete='off'>
                      </input>
                    </div>
                </div>

                {/* text color */}
                <div className={[gStyles.colFlex, gStyles.formField].join(' ')}>
                    <div className={gStyles.fieldLabel}>{texts.textColorLabel}</div>
                    <SelectColor 
                        colorCode={props.page.fields.fontColor}
                        onSelect={props.onSelectFontColor}
                        isShow = {props.page.showDropdown.fontColor}
                    />
                    <div className={props.page.errors.sameColor ? gStyles.errorField : gStyles.hide} >{props.page.errors.sameColor}</div>
                </div>

                {/* background color */}
                <div className={[gStyles.colFlex, gStyles.formField].join(' ')}>
                    <div className={gStyles.fieldLabel}>{texts.bgroundLabel}</div>
                    <SelectColor 
                        colorCode={props.page.fields.backgroundColor}
                        onSelect={props.onSelectBackgroundColor}
                        isShow = {props.page.showDropdown.bgColor}
                    />
                </div>

                {/* preview */}
                <div className={[gStyles.colFlex, gStyles.formField].join(' ')}>
                    <div className={gStyles.fieldLabel}>{texts.previewLabel}</div>
                    <div className={[gStyles.rowNowrapFlex, pStyles.preview].join(' ')} 
                        style={{backgroundColor:props.page.fields.backgroundColor, color: props.page.fields.fontColor}}>
                        <span className={gStyles.btnIcon}>{props.page.fields.image}</span>
                        <span className={pStyles.lineDescription}>{props.page.fields.name}</span>
                    </div>
                </div>

            </div>
        </section>
    </main>
)




