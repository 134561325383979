/**
 * Application constants for Info
 */

//  System Categories
export const Category = {
    ROOT    : 'ROOT',
    DEFAULT : 'DEFAULT'
}

export const CellType = {
    INFO: 'INFO',
    CATEGORY: 'INFO_CATEGORY',
    LABEL: 'INFO_LABEL',
}

//  Status
export const Status = {
    NOT_STARTED     : 'NOT_STARTED', 
    IN_PROGRESS     : 'IN_PROGRESS', 
    DONE            : 'DONE', 
    WAITING         : 'WAITING', 
    CANCELLED       : 'CANCELLED'
}

export const Priority = {
    VERY_LOW    : 'VERY_LOW', 
    LOW         : 'LOW', 
    NORMAL      : 'NORMAL', 
    HIGH        : 'HIGH', 
    VERY_HIGH   : 'VERY_HIGH'
}

export const Importance = {
    VERY_LOW    : 'VERY_LOW', 
    LOW         : 'LOW', 
    NORMAL      : 'NORMAL', 
    HIGH        : 'HIGH', 
    VERY_HIGH   : 'VERY_HIGH'
}

export const Expertise = {
    EXPERT          : 'EXPERT', 
    ADVANCED        : 'ADVANCED', 
    INTERMEDIATE    : 'INTERMEDIATE', 
    BEGINNER        : 'BEGINNER', 
    NOT_APPLICABLE  : 'NOT_APPLICABLE'
}

export const ByDate = {
    NO_DATE     : 'NO_DATE', 
    PAST_DATE   : 'PAST_DATE', 
    TODAY       : 'TODAY', 
    TOMORROW    : 'TOMORROW', 
    THIS_WEEK   : 'THIS_WEEK',
    NEXT_WEEK   : 'NEXT_WEEK',
    FUTURE      : 'FUTURE',
}


export const DateSelector = {
    NONE: 'NONE',
    TODAY: 'TODAY',
    TOMORROW: 'TOMORROW',
    WEEK1: 'WEEK1',
    WEEK2: 'WEEK2',
    MONTH1: 'MONTH1',
    DATE: 'DATE',
}

export const RepeatSelector = {
    NONE: 'NONE',
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY',
    MONTHLY: 'MONTHLY',
    YEARLY: 'YEARLY',
}

export const DateRange = {
    MIN: '2000-01-01',  // start date
    ADD_YEARS: 20,      // max year of info from today
}

export const ID = {
    CELL_ID_PREFIX: 'INFO',
    CATEGORY_ID_PREFIX: 'INFO_CATEGORY',
    LABEL_ID_PREFIX: 'INFO_LABEL',
    LINK_CHAR: '_',
}

export const SENTINEL_ID = '-1';  // used to indicate non-existent record or state

export const DEV_USER_ID = 'myinfo_dev@mybrainmate.com';    // used in dev only

export const DEFAULT_USER_ID = 'myinfo@mybrainmate.com';    // all users have same id, no need to login

export const LOGIN_USER = {
    USER_ID: '',        // will be updated with the logged in user
    LAST_LOGIN: '',
    LAST_ACTIVITY: '',
}

export const LOGIN_USER_KEY = 'mybrainmate_login_user';

export const EXPORT_FILENAME = 'mybrainmate_info.txt';

export let ENVIRONMENT = 'prod'; // dev|prod; todo: switch to 'prod' when deploying