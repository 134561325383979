import React from 'react'
import styles from './Filter.module.css'
import gStyles from '../../App.module.css'
import {Utils} from '../../util/Utils'
import gTexts from '../../resources/texts'
import texts from './FilterTexts'
import { SelectList} from '../dropDown/Dropdown'
import { SelectOption } from '../dropDown/SelectProps'
import * as Constants from '../../util/InfoConstants'
import * as ViewHelper from '../../util/ViewHelper'
import {State, Label, Category, Fields} from './FilterState'
import * as LabelManager from '../../data/LabelManager'
import * as DataUtil from '../../data/DataUtil'


/**
 * @typedef {Object} Props
 * @property {(f:Fields)=>void} onSetFilter
 * @property {Fields} currFilter
 * 
 * @typedef {State} State
 */

 /** @extends {React.Component<Props, State>} */
export class Filter extends React.Component {

    /**@param {Props} props */
    constructor(props) {
        super(props);

        // initialize state
        /**@type {State} */
        this.state = this.initState();

        this.filterRef = React.createRef(); // reference to filter component to capture click outside

    }

    componentDidMount() {
        document.addEventListener("mousedown", this.onClickOutside, false); 
    }


    componentWillUnmount() {
        document.removeEventListener("mousedown", this.onClickOutside, false); 
    }

    onClickOutside = (e) => {
        e.stopPropagation();
        if (!this.filterRef.current.contains(e.target)) {  
            // click outside of calendar, close
            this.onClose();
        }
    }


    onClose = () => {
        this.props.onSetFilter(null);
    }

    onClear = () => {
        Utils.logDebug('clearing filter...');
        this.props.onSetFilter(new Fields()); // pass empty fields
    }

    onOK = () => {
        Utils.logDebug('saving filter...');
        this.props.onSetFilter(this.fieldsToEmpty(this.state.fields));  // pass user selection
    }

    /** @param {string} code */
    onSelectCategory = (code) => {
        if (code.toLowerCase() === 'onblur') {
            this.setState({ showDropdown: { ...this.state.showDropdown, category: false } })
            return;
        }
        const changeCode = Utils.isEmpty(code) ? this.state.fields.categoryId : code;
        this.setState({
            showDropdown: { ...this.state.showDropdown, category: !this.state.showDropdown.category },
            fields: { ...this.state.fields, categoryId: changeCode },
        })
    }

    /** @param {string} code */
    onSelectExpertise = (code) => {
        if (code.toLowerCase() === 'onblur') {
            this.setState({ showDropdown: { ...this.state.showDropdown, expertise: false } })
            return;
        }
        const changeCode = Utils.isEmpty(code) ? this.state.fields.expertise : code;
        this.setState({
            showDropdown: { ...this.state.showDropdown, expertise: !this.state.showDropdown.expertise },
            fields: { ...this.state.fields, expertise: changeCode },
        })
    }

    /** @param {string} code */
    onSelectImportance = (code) => {
        if (code.toLowerCase() === 'onblur') {
            this.setState({ showDropdown: { ...this.state.showDropdown, importance: false } })
            return;
        }
        const changeCode = Utils.isEmpty(code) ? this.state.fields.importance : code;
        this.setState({
            showDropdown: { ...this.state.showDropdown, importance: !this.state.showDropdown.importance },
            fields: { ...this.state.fields, importance: changeCode },
        })
    }
    
    /** @param {string} code */
    onSelectLabel = (code) => {
        if (code.toLowerCase() === 'onblur') {
            this.setState({ showDropdown: { ...this.state.showDropdown, label: false } })
            return;
        }
        const changeCode = Utils.isEmpty(code) ? this.state.fields.labelId : code;
        this.setState({
            showDropdown: { ...this.state.showDropdown, label: !this.state.showDropdown.label },
            fields: { ...this.state.fields, labelId: changeCode },
        })
    }


    render() {

        return (
            <div ref={this.filterRef}>
                <div className={[gStyles.colFlex, styles.filter].join(' ')}>

                    {/* title */}
                    <div onClick={this.onClose} 
                        className={[
                            gStyles.rowFlex, 
                            styles.filterHeader
                            ].join(' ')}>
                        <span  style={{width:'90%'}}>{texts.title}</span>
                        <span className={gStyles.splitRight}>&times;</span>
                    </div>

                    {/* content */}
                    <div className={[gStyles.colFlex, styles.filterContent].join(' ')}>

                        {/* category */}
                        <div className={gStyles.formField}>
                            <div className={[gStyles.rowFlex, gStyles.fieldLabel].join(' ')} >
                                <span>{texts.categoryLabel}</span>
                            </div>
                            <SelectList
                                selectedOption={new SelectOption(
                                    this.state.fields.categoryId,
                                    this.state.categoryList.find((c)=>(c.id===this.state.fields.categoryId)).name,
                                    )}
                                selectOptions={this.state.categoryList.map( (c)=>( new SelectOption(c.id, c.name) ) ) }
                                isShow={this.state.showDropdown.category}
                                onSelect={this.onSelectCategory}
                            />

                        </div>

                        {/* expertise */}
                        <div className={gStyles.formField}>
                            <div className={[gStyles.rowFlex, gStyles.fieldLabel].join(' ')} >
                                <span>{texts.expertiseLabel}</span>
                            </div>
                            <SelectList
                                selectedOption={new SelectOption(
                                    this.state.fields.expertise, 
                                    this.state.fields.expertise === Constants.SENTINEL_ID ? texts.none : ViewHelper.getExpertiseDescription(this.state.fields.expertise)
                                    )}
                                selectOptions={[
                                    new SelectOption(Constants.SENTINEL_ID, texts.none),
                                    new SelectOption(Constants.Expertise.EXPERT, gTexts.expertiseExpert),
                                    new SelectOption(Constants.Expertise.ADVANCED, gTexts.expertiseAdvanced),
                                    new SelectOption(Constants.Expertise.INTERMEDIATE, gTexts.expertiseIntermediate),
                                    new SelectOption(Constants.Expertise.BEGINNER, gTexts.expertiseBeginner),
                                    new SelectOption(Constants.Expertise.NOT_APPLICABLE, gTexts.expertiseNotApplicable),
        
                                ]}
                                isShow={this.state.showDropdown.expertise}
                                onSelect={this.onSelectExpertise}
                            />
                        </div>

                        {/* importance */}
                        <div className={gStyles.formField}>
                            <div className={[gStyles.rowFlex, gStyles.fieldLabel].join(' ')} >
                                <span>{texts.importanceLabel}</span>
                            </div>
                            <SelectList
                                selectedOption={new SelectOption(
                                    this.state.fields.importance, 
                                    this.state.fields.importance === Constants.SENTINEL_ID ? texts.none : ViewHelper.getImportanceDescription(this.state.fields.importance)
                                    )}
                                selectOptions={[
                                    new SelectOption(Constants.SENTINEL_ID, texts.none),
                                    new SelectOption(Constants.Importance.VERY_HIGH, gTexts.importanceVeryHigh),
                                    new SelectOption(Constants.Importance.HIGH, gTexts.importanceHigh),
                                    new SelectOption(Constants.Importance.NORMAL, gTexts.importanceNormal),
                                    new SelectOption(Constants.Importance.LOW, gTexts.importanceLow),
                                    new SelectOption(Constants.Importance.VERY_LOW, gTexts.importanceVeryLow),
                                ]}
                                isShow={this.state.showDropdown.importance}
                                onSelect={this.onSelectImportance}
                            />
                        </div>

                        {/* label */}
                        <div className={gStyles.formField}>
                            <div className={[gStyles.rowFlex, gStyles.fieldLabel].join(' ')} >
                                <span>{texts.tagLabel}</span>
                            </div>
                            <SelectList
                                selectedOption={new SelectOption(
                                    this.state.fields.labelId,
                                    this.state.labelList.find((l)=>(l.id===this.state.fields.labelId)).name,
                                    )}
                                selectOptions={this.state.labelList.map( (l)=>( new SelectOption(l.id, l.name) ) ) }
                                isShow={this.state.showDropdown.label}
                                onSelect={this.onSelectLabel}
                            />
                        </div>

                    </div>

                    {/* action */}
                    <div className={[gStyles.rowFlex, styles.actionFooter].join(' ')}>
                        <div className={gStyles.splitRight}></div>

                        <div className={gStyles.btnText} onClick={this.onClear}>{texts.clear}</div>
                        <div className={gStyles.btnText} style={{ marginLeft: '10px' }} onClick={this.onOK}>{texts.ok}</div>
                    </div>
                

                </div>

            </div>
        )
    }

    // **************************** initialize filter

    /** @return {State} */
    initState = () => {
        const baseState = new State();

        if (Utils.isNotEmpty(this.props.currFilter) ) {
            baseState.fields = {...this.props.currFilter};
        }
        baseState.fields = this.fieldsToSentinel(baseState.fields);        

        baseState.categoryList = this.getCategoryList();
        baseState.labelList = this.getLabelList(baseState);

        return baseState;
    }

    /** replace sentinel value with empty string 
     * @param {Fields} inFields
     */
    fieldsToEmpty = (inFields) => {
        const fields = {...inFields}; // clone
        if (fields.categoryId === Constants.SENTINEL_ID) fields.categoryId='';
        if (fields.labelId === Constants.SENTINEL_ID) fields.labelId='';
        if (fields.expertise === Constants.SENTINEL_ID) fields.expertise='';
        if (fields.importance === Constants.SENTINEL_ID) fields.importance='';
        return fields;
    }

    /** replace empty string with sentinel value
     * @param {Fields} inFields
     */
    fieldsToSentinel = (inFields) => {
        const fields = {...inFields}; // clone
        if (Utils.isEmpty(fields.categoryId) ) fields.categoryId=Constants.SENTINEL_ID;
        if (Utils.isEmpty(fields.labelId) ) fields.labelId=Constants.SENTINEL_ID;
        if (Utils.isEmpty(fields.expertise) ) fields.expertise=Constants.SENTINEL_ID;
        if (Utils.isEmpty(fields.importance) ) fields.importance=Constants.SENTINEL_ID;
        return fields;
    }

        /** 
     * @return {Category[]} 
     */
    getCategoryList = () => {

        const allCategories = DataUtil.getCategoriesHierarchy(); 

        // convert each category to {id,name} object
        const categories = allCategories
            .filter((fc)=>(DataUtil.isRoot(fc)))  // include only root categories
            .map((c) => {            
            const cat = new Category();
            cat.id = c.categoryId;
            cat.name = ViewHelper.getCategoryDisplayText(c);  
            return cat;
        })

        // add none as first element
        const none = new Category();
        none.id=Constants.SENTINEL_ID;
        none.name = texts.none;
        categories.unshift(none);

        return categories;
    }


    /** 
     * @param {State} baseState
     * @return {Label[]} 
     * 
    */
    getLabelList = (baseState) => {
        const allLabels = LabelManager.getAll();
        ViewHelper.sortLabels(allLabels);
        const labels = allLabels.map( (l) => {
            const label = new Label();
            label.id = l.labelId;
            label.name = l.name;
            return label;
        })
        
        // add none as first element
        const none = new Label();
        none.id=Constants.SENTINEL_ID;
        none.name = texts.none;
        labels.unshift(none);

        return labels;
    }
}



