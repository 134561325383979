export class UserImage {
    /**@type {string} */ id = '';
    /**@type {string} */ name = '';
    /**@type {string} */ category = '';
    /**@type {string} */ tags = '';

    /**
     * 
     * @param {string} id 
     * @param {string} name 
     * @param {string} category 
     * @param {string} tags 
     */
    constructor(id, name, category, tags) {
        this.id = id;
        this.name = name;
        this.category = category;
        this.tags = tags;
    }
}


export default [
    new UserImage('icons8-bat-40', 'bat', 'Animals', 'fly'),
    new UserImage('icons8-bear-40', 'bear', 'Animals', 'wild'),
    new UserImage('icons8-bird-40', 'bird', 'Animals', 'fly'),
    new UserImage('icons8-bull-40', 'bull', 'Animals', 'spain'),
    new UserImage('icons8-bumblebee-40', 'bumblebee', 'Animals', 'insect'),
    new UserImage('icons8-butterfly-40', 'butterfly', 'Animals', 'color'),
    new UserImage('icons8-camel-40', 'camel', 'Animals', 'dessert'),
    new UserImage('icons8-cat-40', 'cat', 'Animals', 'pet'),
    new UserImage('icons8-corgi-40', 'corgi', 'Animals', 'pet'),
    new UserImage('icons8-deer-40', 'deer', 'Animals', 'wild'),
    new UserImage('icons8-dog-40', 'dog', 'Animals', 'pet'),
    new UserImage('icons8-dog-bone-40', 'dog bone', 'Animals', ''),
    new UserImage('icons8-dolphin-40', 'dolphin', 'Animals', 'ocean'),
    new UserImage('icons8-dove-40', 'dove', 'Animals', 'fly'),
    new UserImage('icons8-duck-40', 'duck', 'Animals', ''),
    new UserImage('icons8-flamingo-40', 'flamingo', 'Animals', 'pink'),
    new UserImage('icons8-fox-40', 'fox', 'Animals', 'wild'),
    new UserImage('icons8-frog-40', 'frog', 'Animals', 'jump'),
    new UserImage('icons8-german-shepherd-40', 'german shepherd', 'Animals', 'dog'),
    new UserImage('icons8-giraffe-40', 'giraffe', 'Animals', 'neck'),
    new UserImage('icons8-insect-40', 'insect', 'Animals', ''),
    new UserImage('icons8-kangaroo-40', 'kangaroo', 'Animals', 'jump'),
    new UserImage('icons8-koi-fish-40', 'koi fish', 'Animals', 'luck'),
    new UserImage('icons8-leopard-40', 'leopard', 'Animals', 'wild'),
    new UserImage('icons8-orca-40', 'orca', 'Animals', 'ocean'),
    new UserImage('icons8-owl-40', 'owl', 'Animals', 'fly'),
    new UserImage('icons8-panda-40', 'panda', 'Animals', 'bear'),
    new UserImage('icons8-parrot-40', 'parrot', 'Animals', 'fly'),
    new UserImage('icons8-peace-pigeon-40', 'peace pigeon', 'Animals', 'fly'),
    new UserImage('icons8-peacock-40', 'peacock', 'Animals', 'color'),
    new UserImage('icons8-poodle-40', 'poodle', 'Animals', 'pet'),
    new UserImage('icons8-pug-40', 'pug', 'Animals', 'pet'),
    new UserImage('icons8-rabbit-40', 'rabbit', 'Animals', 'pet'),
    new UserImage('icons8-reindeer-40', 'reindeer', 'Animals', 'wild, santa'),
    new UserImage('icons8-seahorse-40', 'seahorse', 'Animals', 'ocean'),
    new UserImage('icons8-seal-40', 'seal', 'Animals', 'ocean'),
    new UserImage('icons8-shark-40', 'shark', 'Animals', 'ocean, predator'),
    new UserImage('icons8-sheep-40', 'sheep', 'Animals', ''),
    new UserImage('icons8-snail-40', 'snail', 'Animals', 'slow'),
    new UserImage('icons8-snake-40', 'snake', 'Animals', ''),
    new UserImage('icons8-spider-40', 'spider', 'Animals', ''),
    new UserImage('icons8-starfish-40', 'starfish', 'Animals', ''),
    new UserImage('icons8-turtle-40', 'turtle', 'Animals', 'slow'),
    new UserImage('icons8-werewolf-40', 'werewolf', 'Animals', ''),
    new UserImage('icons8-whale-40', 'whale', 'Animals', 'ocean, large'),
    new UserImage('icons8-wolf-40', 'wolf', 'Animals', 'wild'),
    
    new UserImage('icons8-australian-dollar-40', 'australian dollar', 'Money & Business', 'money, currency'),
    new UserImage('icons8-badge-40', 'badge', 'Money & Business', ''),
    new UserImage('icons8-bitcoin-40', 'bitcoin', 'Money & Business', 'money'),
    new UserImage('icons8-briefcase-40', 'briefcase', 'Money & Business', 'bag'),
    new UserImage('icons8-cash-40', 'cash', 'Money & Business', 'money'),
    new UserImage('icons8-cent-40', 'cent', 'Money & Business', 'money'),
    new UserImage('icons8-chart-40', 'chart', 'Money & Business', 'graph'),
    new UserImage('icons8-check-book-40', 'check book', 'Money & Business', 'money'),
    new UserImage('icons8-coins-40', 'coins', 'Money & Business', 'money'),
    new UserImage('icons8-company-40', 'company', 'Money & Business', 'building'),
    new UserImage('icons8-donate-40', 'donate', 'Money & Business', 'money'),
    new UserImage('icons8-euro-40', 'euro', 'Money & Business', 'money, currency'),
    new UserImage('icons8-factory-40', 'factory', 'Money & Business', 'building'),
    new UserImage('icons8-flow-chart-40', 'flow chart', 'Money & Business', 'graph'),
    new UserImage('icons8-futures-40', 'futures', 'Money & Business', 'money'),
    new UserImage('icons8-hongkong-dollar-40', 'hongkong dollar', 'Money & Business', 'currency, money'),
    new UserImage('icons8-increase-40', 'increase', 'Money & Business', ''),
    new UserImage('icons8-japanese-yen-40', 'japanese yen', 'Money & Business', 'currency, money'),
    new UserImage('icons8-management-40', 'management', 'Money & Business', ''),
    new UserImage('icons8-mind-map-40', 'mind map', 'Money & Business', 'graph'),
    new UserImage('icons8-peso-symbol-40', 'peso symbol', 'Money & Business', 'currency, money'),
    new UserImage('icons8-resume-40', 'resume', 'Money & Business', 'job'),
    new UserImage('icons8-rupee-40', 'rupee', 'Money & Business', 'currency, money'),
    new UserImage('icons8-stack-of-money-40', 'stack of money', 'Money & Business', ''),
    new UserImage('icons8-suitcase-40', 'suitcase', 'Money & Business', 'bag'),
    new UserImage('icons8-swiss-franc-40', 'swiss franc', 'Money & Business', 'currency, money'),
    new UserImage('icons8-turkish-lira-40', 'turkish lira', 'Money & Business', 'currency, money'),
    new UserImage('icons8-us-dollar-40', 'us dollar', 'Money & Business', 'currency, money'),
    new UserImage('icons8-warehouse-40', 'warehouse', 'Money & Business', 'building'),
    new UserImage('icons8-warranty-40', 'warranty', 'Money & Business', ''),
    new UserImage('icons8-won-40', 'won', 'Money & Business', 'currency, money'),
    
    new UserImage('icons8-airport-40', 'airport', 'City', 'travel'),
    new UserImage('icons8-bank-building-40', 'bank building', 'City', 'money'),
    new UserImage('icons8-bench-40', 'bench', 'City', ''),
    new UserImage('icons8-city-buildings-40', 'city buildings', 'City', ''),
    new UserImage('icons8-city-railway-station-40', 'city railway station', 'City', 'train'),
    new UserImage('icons8-courthouse-40', 'courthouse', 'City', 'judge'),
    new UserImage('icons8-embassy-40', 'embassy', 'City', ''),
    new UserImage('icons8-fire-hydrant-40', 'fire hydrant', 'City', 'emergency'),
    new UserImage('icons8-fire-station-40', 'fire station', 'City', 'emergency'),
    new UserImage('icons8-food-cart-40', 'food cart', 'City', ''),
    new UserImage('icons8-fountain-40', 'fountain', 'City', 'water'),
    new UserImage('icons8-gas-station-40', 'gas station', 'City', 'car'),
    new UserImage('icons8-hotel-40', 'hotel', 'City', 'travel, tourism'),
    new UserImage('icons8-museum-40', 'museum', 'City', 'tourism'),
    new UserImage('icons8-parking-meter-40', 'parking meter', 'City', 'car'),
    new UserImage('icons8-parliament-40', 'parliament', 'City', 'government, building'),
    new UserImage('icons8-playground-40', 'playground', 'City', 'kids'),
    new UserImage('icons8-seesaw-40', 'seesaw', 'City', 'kids'),
    new UserImage('icons8-skyscrapers-40', 'skyscrapers', 'City', 'building'),
    new UserImage('icons8-street-light-40', 'street light', 'City', ''),
    new UserImage('icons8-traffic-light-40', 'traffic light', 'City', 'car'),
    
    new UserImage('icons8-angel-with-sword-40', 'angel with sword', 'Arts, Culture & Religion', 'good, heaven'),
    new UserImage('icons8-buddha-40', 'buddha', 'Arts, Culture & Religion', 'religion'),
    new UserImage('icons8-bust-40', 'bust', 'Arts, Culture & Religion', 'art'),
    new UserImage('icons8-cathedral-40', 'cathedral', 'Arts, Culture & Religion', 'religion'),
    new UserImage('icons8-color-palette-40', 'color palette', 'Arts, Culture & Religion', 'art'),
    new UserImage('icons8-cross-40', 'cross', 'Arts, Culture & Religion', 'religion'),
    new UserImage('icons8-easel-40', 'easel', 'Arts, Culture & Religion', 'art'),
    new UserImage('icons8-geisha-40', 'geisha', 'Arts, Culture & Religion', 'japan'),
    new UserImage('icons8-german-house-40', 'German house', 'Arts, Culture & Religion', ''),
    new UserImage('icons8-gondola-40', 'gondola', 'Arts, Culture & Religion', ''),
    new UserImage('icons8-greek-helmet-40', 'Greek helmet', 'Arts, Culture & Religion', ''),
    new UserImage('icons8-hanukkah-40', 'Hanukkah', 'Arts, Culture & Religion', 'religion'),
    new UserImage('icons8-holy-bible-40', 'Holy Bible', 'Arts, Culture & Religion', 'jesus, christian, religion'),
    new UserImage('icons8-lantern-40', 'lantern', 'Arts, Culture & Religion', ''),
    new UserImage('icons8-monster-face-40', 'monster face', 'Arts, Culture & Religion', 'halloween'),
    new UserImage('icons8-monument-40', 'monument', 'Arts, Culture & Religion', ''),
    new UserImage('icons8-moon-star-40', 'moon star', 'Arts, Culture & Religion', ''),
    new UserImage('icons8-mosque-40', 'mosque', 'Arts, Culture & Religion', 'religion'),
    new UserImage('icons8-mummy-40', 'mummy', 'Arts, Culture & Religion', 'halloween'),
    new UserImage('icons8-ninja-head-40', 'ninja head', 'Arts, Culture & Religion', 'japan'),
    new UserImage('icons8-origami-40', 'origami', 'Arts, Culture & Religion', 'japan'),
    new UserImage('icons8-pharaoh-40', 'pharaoh', 'Arts, Culture & Religion', 'egypt'),
    new UserImage('icons8-potters-wheel-40', 'potters wheel', 'Arts, Culture & Religion', 'art'),
    new UserImage('icons8-queen-crown-40', 'queen crown', 'Arts, Culture & Religion', 'britain'),
    new UserImage('icons8-quran-40', 'Quran', 'Arts, Culture & Religion', 'religion'),
    new UserImage('icons8-rising-sun-40', 'rising sun', 'Arts, Culture & Religion', 'japan'),
    new UserImage('icons8-samurai-helmet-40', 'samurai helmet', 'Arts, Culture & Religion', 'japan'),
    new UserImage('icons8-santas-hat-40', 'santas hat', 'Arts, Culture & Religion', 'christmas'),
    new UserImage('icons8-scream-40', 'scream', 'Arts, Culture & Religion', 'horror, halloween'),
    new UserImage('icons8-sculpture-40', 'sculpture', 'Arts, Culture & Religion', 'art'),
    new UserImage('icons8-star-of-david-40', 'star of david', 'Arts, Culture & Religion', 'religion'),
    new UserImage('icons8-sumo-40', 'sumo', 'Arts, Culture & Religion', 'japan'),
    new UserImage('icons8-super-hero-male-40', 'super hero male', 'Arts, Culture & Religion', ''),
    new UserImage('icons8-synagogue-40', 'synagogue', 'Arts, Culture & Religion', 'religion'),
    new UserImage('icons8-tango-40', 'tango', 'Arts, Culture & Religion', 'dance'),
    new UserImage('icons8-temple-40', 'temple', 'Arts, Culture & Religion', ''),
    new UserImage('icons8-the-birth-of-venus-40', 'the birth of venus', 'Arts, Culture & Religion', 'art'),
    new UserImage('icons8-the-pope-40', 'the pope', 'Arts, Culture & Religion', 'religion'),
    new UserImage('icons8-third-eye-symbol-40', 'third eye symbol', 'Arts, Culture & Religion', ''),
    new UserImage('icons8-thor-hammer-40', 'thor hammer', 'Arts, Culture & Religion', ''),
    new UserImage('icons8-trojan-horse-40', 'trojan horse', 'Arts, Culture & Religion', 'greek'),
    new UserImage('icons8-viking-helmet-40', 'viking helmet', 'Arts, Culture & Religion', ''),
    new UserImage('icons8-viking-ship-40', 'viking ship', 'Arts, Culture & Religion', ''),
    new UserImage('icons8-white-rosary-40', 'white rosary', 'Arts, Culture & Religion', 'religion'),
    new UserImage('icons8-witch-40', 'witch', 'Arts, Culture & Religion', ''),
    new UserImage('icons8-wizard-40', 'wizard', 'Arts, Culture & Religion', ''),
    new UserImage('icons8-yin-yang-40', 'yin yang', 'Arts, Culture & Religion', ''),
    new UserImage('icons8-zombie-40', 'zombie', 'Arts, Culture & Religion', 'horror, halloween'),
    
    new UserImage('icons8-apache-helicopter-40', 'apache helicopter', 'Defense & Security', 'war'),
    new UserImage('icons8-battleship-40', 'battleship', 'Defense & Security', 'war'),
    new UserImage('icons8-bulletproof-vest-40', 'bulletproof vest', 'Defense & Security', ''),
    new UserImage('icons8-cannon-40', 'cannon', 'Defense & Security', 'weapon'),
    new UserImage('icons8-dog-tag-40', 'dog tag', 'Defense & Security', ''),
    new UserImage('icons8-dynamite-40', 'dynamite', 'Defense & Security', ''),
    new UserImage('icons8-explosion-40', 'explosion', 'Defense & Security', ''),
    new UserImage('icons8-fighter-jet-40', 'fighter jet', 'Defense & Security', 'plane'),
    new UserImage('icons8-fingerprint-40', 'fingerprint', 'Defense & Security', ''),
    new UserImage('icons8-firewall-40', 'firewall', 'Defense & Security', ''),
    new UserImage('icons8-grenade-40', 'grenade', 'Defense & Security', 'weapon, war'),
    new UserImage('icons8-helmet-40', 'helmet', 'Defense & Security', ''),
    new UserImage('icons8-katana-40', 'katana', 'Defense & Security', 'sword'),
    new UserImage('icons8-key-2-40', 'key', 'Defense & Security', ''),
    new UserImage('icons8-missile-40', 'missile', 'Defense & Security', 'war, weapon'),
    new UserImage('icons8-mushroom-cloud-40', 'mushroom cloud', 'Defense & Security', ''),
    new UserImage('icons8-sniper-rifle-40', 'sniper rifle', 'Defense & Security', 'weapon'),
    new UserImage('icons8-tank-40', 'tank', 'Defense & Security', 'war'),
    new UserImage('icons8-unlock-40', 'unlock', 'Defense & Security', ''),
    
    new UserImage('icons8-abacus-40', 'abacus', 'School & Education', 'math'),
    new UserImage('icons8-ball-point-pen-40', 'ball point pen', 'School & Education', 'school'),
    new UserImage('icons8-book-shelf-40', 'book shelf', 'School & Education', 'school, office'),
    new UserImage('icons8-books-40', 'books', 'School & Education', 'school, reading'),
    new UserImage('icons8-calculator-40', 'calculator', 'School & Education', 'school, office, math'),
    new UserImage('icons8-dictionary-40', 'dictionary', 'School & Education', 'book, school'),
    new UserImage('icons8-diploma-40', 'diploma', 'School & Education', 'certificate'),
    new UserImage('icons8-drawing-compass-40', 'drawing compass', 'School & Education', ''),
    new UserImage('icons8-erase-40', 'erase', 'School & Education', 'school, office, stationery'),
    new UserImage('icons8-glue-40', 'glue', 'School & Education', 'school, office, stationery'),
    new UserImage('icons8-graduation-cap-40', 'graduation cap', 'School & Education', 'school'),
    new UserImage('icons8-hand-with-pen-40', 'hand with pen', 'School & Education', 'writing'),
    new UserImage('icons8-hole-punch-40', 'hole punch', 'School & Education', 'school, office'),
    new UserImage('icons8-marker-pen-40', 'marker pen', 'School & Education', 'school, office'),
    new UserImage('icons8-math-40', 'math', 'School & Education', ''),
    new UserImage('icons8-paint-40', 'paint', 'School & Education', ''),
    new UserImage('icons8-paper-clamp-40', 'paper clamp', 'School & Education', 'school, office, stationery'),
    new UserImage('icons8-pen-40', 'pen', 'School & Education', 'school, office, stationery'),
    new UserImage('icons8-pencil-40', 'pencil', 'School & Education', 'school, office, stationery'),
    new UserImage('icons8-quill-with-ink-40', 'quill with ink', 'School & Education', 'writing'),
    new UserImage('icons8-reading-40', 'reading', 'School & Education', ''),
    new UserImage('icons8-ruler-40', 'ruler', 'School & Education', 'school, office, stationery'),
    new UserImage('icons8-school-building-40', 'school building', 'School & Education', 'school, office, stationery'),
    new UserImage('icons8-scotch-tape-40', 'scotch tape', 'School & Education', 'school, office, stationery'),
    new UserImage('icons8-sheet-of-paper-40', 'sheet of paper', 'School & Education', 'school, office, stationery'),
    new UserImage('icons8-stanley-knife-40', 'stanley knife', 'School & Education', 'school, office, stationery'),
    new UserImage('icons8-stapler-40', 'stapler', 'School & Education', 'school, office, stationery'),
    new UserImage('icons8-stationery-40', 'stationery', 'School & Education', ''),
    new UserImage('icons8-teacher-40', 'teacher', 'School & Education', 'profession'),
    new UserImage('icons8-unicef-40', 'unicef', 'School & Education', 'UN'),
    new UserImage('icons8-university-40', 'university', 'School & Education', 'school'),
    
    new UserImage('icons8-beach-ball-40', 'beach ball', 'Fun & Entertainment', ''),
    new UserImage('icons8-cards-40', 'cards', 'Fun & Entertainment', 'game'),
    new UserImage('icons8-carousel-40', 'carousel', 'Fun & Entertainment', ''),
    new UserImage('icons8-circus-tent-40', 'circus tent', 'Fun & Entertainment', ''),
    new UserImage('icons8-coconut-cocktail-40', 'coconut cocktail', 'Fun & Entertainment', 'beach'),
    new UserImage('icons8-confetti-40', 'confetti', 'Fun & Entertainment', 'party'),
    new UserImage('icons8-dice-40', 'dice', 'Fun & Entertainment', 'game'),
    new UserImage('icons8-edvard-munch-40', 'edvard munch', 'Fun & Entertainment', ''),
    new UserImage('icons8-ferris-wheel-40', 'ferris wheel', 'Fun & Entertainment', ''),
    new UserImage('icons8-fidget-spinner-40', 'fidget spinner', 'Fun & Entertainment', ''),
    new UserImage('icons8-game-controller-40', 'game controller', 'Fun & Entertainment', ''),
    new UserImage('icons8-jet-ski-40', 'jet ski', 'Fun & Entertainment', ''),
    new UserImage('icons8-joker-40', 'joker', 'Fun & Entertainment', ''),
    new UserImage('icons8-kite-40', 'kite', 'Fun & Entertainment', 'wind'),
    new UserImage('icons8-mahjong-40', 'mahjong', 'Fun & Entertainment', 'game'),
    new UserImage('icons8-mime-40', 'mime', 'Fun & Entertainment', ''),
    new UserImage('icons8-minecraft-logo-40', 'minecraft logo', 'Fun & Entertainment', 'game'),
    new UserImage('icons8-minecraft-sword-40', 'minecraft sword', 'Fun & Entertainment', 'game'),
    new UserImage('icons8-party-balloons-40', 'party balloons', 'Fun & Entertainment', 'party'),
    new UserImage('icons8-picnic-table-40', 'picnic table', 'Fun & Entertainment', ''),
    new UserImage('icons8-pool-table-40', 'pool table', 'Fun & Entertainment', 'game'),
    new UserImage('icons8-prize-40', 'prize', 'Fun & Entertainment', ''),
    new UserImage('icons8-rubiks-cube-40', 'rubiks cube', 'Fun & Entertainment', 'game'),
    new UserImage('icons8-sand-castle-40', 'sand castle', 'Fun & Entertainment', 'beach'),
    new UserImage('icons8-slot-machine-40', 'slot machine', 'Fun & Entertainment', 'casino, bet'),
    new UserImage('icons8-sun-lounger-40', 'sun lounger', 'Fun & Entertainment', 'beach'),
    new UserImage('icons8-theme-park-40', 'theme park', 'Fun & Entertainment', ''),
    new UserImage('icons8-venetian-mask-40', 'venetian mask', 'Fun & Entertainment', ''),
    
    new UserImage('icons8-alarm-40', 'alarm', 'Time, Events & Holidays', 'bell'),
    new UserImage('icons8-candle-40', 'candle', 'Time, Events & Holidays', 'christmas'),
    new UserImage('icons8-candy-cane-40', 'candy cane', 'Time, Events & Holidays', 'christmas'),
    new UserImage('icons8-christmas-star-40', 'christmas star', 'Time, Events & Holidays', ''),
    new UserImage('icons8-christmas-stocking-40', 'christmas stocking', 'Time, Events & Holidays', 'socks'),
    new UserImage('icons8-clock-40', 'clock', 'Time, Events & Holidays', 'time'),
    new UserImage('icons8-elf-40', 'elf', 'Time, Events & Holidays', 'santa'),
    new UserImage('icons8-firework-40', 'firework', 'Time, Events & Holidays', ''),
    new UserImage('icons8-frankensteins-monster-40', 'frankensteins monster', 'Time, Events & Holidays', 'horror'),
    new UserImage('icons8-gingerbread-house-40', 'gingerbread house', 'Time, Events & Holidays', 'food'),
    new UserImage('icons8-heart-with-arrow-40', 'heart with arrow', 'Time, Events & Holidays', 'love'),
    new UserImage('icons8-hourglass-40', 'hourglass', 'Time, Events & Holidays', 'time'),
    new UserImage('icons8-jack-o-lantern-40', 'jack o lantern', 'Time, Events & Holidays', ''),
    new UserImage('icons8-jingle-bell-40', 'jingle bell', 'Time, Events & Holidays', 'christmas'),
    new UserImage('icons8-pocket-watch-40', 'pocket watch', 'Time, Events & Holidays', 'time'),
    new UserImage('icons8-santa-40', 'santa', 'Time, Events & Holidays', ''),
    new UserImage('icons8-stopwatch-40', 'stopwatch', 'Time, Events & Holidays', 'time'),
    new UserImage('icons8-time-40', 'time', 'Time, Events & Holidays', ''),
    new UserImage('icons8-womens-watch-40', 'womens watch', 'Time, Events & Holidays', 'time'),
    
    new UserImage('icons8-barber-pole-40', 'barber pole', 'Fashion & Grooming', 'hair'),
    new UserImage('icons8-barbershop-40', 'barbershop', 'Fashion & Grooming', 'hair'),
    new UserImage('icons8-black-hat-40', 'black hat', 'Fashion & Grooming', ''),
    new UserImage('icons8-bowler-hat-40', 'bowler hat', 'Fashion & Grooming', ''),
    new UserImage('icons8-bra-40', 'bra', 'Fashion & Grooming', 'undies'),
    new UserImage('icons8-diamond-ring-40', 'diamond ring', 'Fashion & Grooming', 'jewelry'),
    new UserImage('icons8-english-mustache-40', 'english mustache', 'Fashion & Grooming', 'grooming'),
    new UserImage('icons8-eyelash-40', 'eyelash', 'Fashion & Grooming', ''),
    new UserImage('icons8-face-powder-40', 'face powder', 'Fashion & Grooming', 'makeup'),
    new UserImage('icons8-glasses-40', 'glasses', 'Fashion & Grooming', ''),
    new UserImage('icons8-hair-brush-40', 'hair brush', 'Fashion & Grooming', 'grooming'),
    new UserImage('icons8-hair-dryer-40', 'hair dryer', 'Fashion & Grooming', ''),
    new UserImage('icons8-hand-fan-40', 'hand fan', 'Fashion & Grooming', ''),
    new UserImage('icons8-hanger-40', 'hanger', 'Fashion & Grooming', 'clothes'),
    new UserImage('icons8-hat-40', 'hat', 'Fashion & Grooming', ''),
    new UserImage('icons8-jewelry-40', 'jewelry', 'Fashion & Grooming', ''),
    new UserImage('icons8-lips-40', 'lips', 'Fashion & Grooming', 'body'),
    new UserImage('icons8-lipstick-40', 'lipstick', 'Fashion & Grooming', 'makeup'),
    new UserImage('icons8-makeup-40', 'makeup', 'Fashion & Grooming', ''),
    new UserImage('icons8-mascara-40', 'mascara', 'Fashion & Grooming', 'makeup'),
    new UserImage('icons8-mens-underwear-40', 'mens underwear', 'Fashion & Grooming', 'undies'),
    new UserImage('icons8-mirror-40', 'mirror', 'Fashion & Grooming', ''),
    new UserImage('icons8-nails-40', 'nails', 'Fashion & Grooming', 'body'),
    new UserImage('icons8-onesies-40', 'onesies', 'Fashion & Grooming', 'baby'),
    new UserImage('icons8-perfume-bottle-40', 'perfume bottle', 'Fashion & Grooming', ''),
    new UserImage('icons8-purse-40', 'purse', 'Fashion & Grooming', ''),
    new UserImage('icons8-razor-40', 'razor', 'Fashion & Grooming', 'grooming'),
    new UserImage('icons8-red-purse-40', 'red purse', 'Fashion & Grooming', ''),
    new UserImage('icons8-shoe-brush-1-40', 'shoe brush', 'Fashion & Grooming', ''),
    new UserImage('icons8-silver-ring-40', 'silver ring', 'Fashion & Grooming', 'jewelry'),
    new UserImage('icons8-smart-watch-40', 'smart watch', 'Fashion & Grooming', 'time'),
    new UserImage('icons8-smoking-pipe-40', 'smoking pipe', 'Fashion & Grooming', 'cigar, smoke'),
    new UserImage('icons8-socks-40', 'socks', 'Fashion & Grooming', 'clothes'),
    new UserImage('icons8-sun-glasses-40', 'sun glasses', 'Fashion & Grooming', ''),
    new UserImage('icons8-t-shirt-40', 't-shirt', 'Fashion & Grooming', 'clothes'),
    new UserImage('icons8-tie-40', 'tie', 'Fashion & Grooming', 'clothes'),
    new UserImage('icons8-toothbrush-40', 'toothbrush', 'Fashion & Grooming', ''),
    new UserImage('icons8-trainers-40', 'trainers', 'Fashion & Grooming', 'shoes'),
    new UserImage('icons8-trousers-40', 'trousers', 'Fashion & Grooming', 'clothes'),
    new UserImage('icons8-tweezers-40', 'tweezers', 'Fashion & Grooming', 'makeup'),
    new UserImage('icons8-umbrella-40', 'umbrella', 'Fashion & Grooming', ''),
    new UserImage('icons8-wedding-dress-40', 'wedding dress', 'Fashion & Grooming', 'clothes'),
    new UserImage('icons8-wedding-rings-40', 'wedding rings', 'Fashion & Grooming', 'jewelry'),
    new UserImage('icons8-winter-boots-40', 'winter boots', 'Fashion & Grooming', ''),
    new UserImage('icons8-womens-suit-40', 'womens suit', 'Fashion & Grooming', 'clothes'),
    
    new UserImage('icons8-apple-40', 'apple', 'Food & Dining', 'fruit'),
    new UserImage('icons8-bacon-40', 'bacon', 'Food & Dining', 'pig'),
    new UserImage('icons8-banana-40', 'banana', 'Food & Dining', 'fruit'),
    new UserImage('icons8-banana-split-40', 'banana split', 'Food & Dining', 'ice cream, dessert'),
    new UserImage('icons8-bar-40', 'bar', 'Food & Dining', 'drink'),
    new UserImage('icons8-beer-mug-40', 'beer mug', 'Food & Dining', 'alcohol'),
    new UserImage('icons8-birthday-cake-40', 'birthday cake', 'Food & Dining', 'party'),
    new UserImage('icons8-bread-40', 'bread', 'Food & Dining', ''),
    new UserImage('icons8-cafe-40', 'cafe', 'Food & Dining', 'drink'),
    new UserImage('icons8-carrot-40', 'carrot', 'Food & Dining', 'vegie'),
    new UserImage('icons8-cheese-40', 'cheese', 'Food & Dining', ''),
    new UserImage('icons8-chili-pepper-40', 'chili pepper', 'Food & Dining', 'hot'),
    new UserImage('icons8-confectionery-40', 'confectionery', 'Food & Dining', ''),
    new UserImage('icons8-cookies-40', 'cookies', 'Food & Dining', ''),
    new UserImage('icons8-cotton-candy-40', 'cotton candy', 'Food & Dining', 'sweet'),
    new UserImage('icons8-crab-40', 'crab', 'Food & Dining', 'seafood'),
    new UserImage('icons8-cutlery-40', 'cutlery', 'Food & Dining', 'dining'),
    new UserImage('icons8-dim-sum-40', 'dim sum', 'Food & Dining', 'chinese'),
    new UserImage('icons8-doughnut-40', 'doughnut', 'Food & Dining', ''),
    new UserImage('icons8-french-fries-40', 'french fries', 'Food & Dining', 'potato'),
    new UserImage('icons8-grapes-40', 'grapes', 'Food & Dining', 'fruit'),
    new UserImage('icons8-hamburger-40', 'hamburger', 'Food & Dining', 'mcdonald'),
    new UserImage('icons8-hot-dog-40', 'hot dog', 'Food & Dining', ''),
    new UserImage('icons8-kettle-40', 'kettle', 'Food & Dining', 'hot'),
    new UserImage('icons8-mango-40', 'mango', 'Food & Dining', 'fruit'),
    new UserImage('icons8-nut-40', 'nut', 'Food & Dining', ''),
    new UserImage('icons8-octopus-40', 'octopus', 'Food & Dining', 'seafood'),
    new UserImage('icons8-olive-oil-40', 'olive oil', 'Food & Dining', ''),
    new UserImage('icons8-orange-40', 'orange', 'Food & Dining', 'fruit'),
    new UserImage('icons8-pancake-40', 'pancake', 'Food & Dining', 'breakfast'),
    new UserImage('icons8-pint-40', 'pint', 'Food & Dining', 'drink, alcohol'),
    new UserImage('icons8-pizza-40', 'pizza', 'Food & Dining', ''),
    new UserImage('icons8-popcorn-40', 'popcorn', 'Food & Dining', 'movie'),
    new UserImage('icons8-prawn-40', 'prawn', 'Food & Dining', 'seafood'),
    new UserImage('icons8-pretzel-40', 'pretzel', 'Food & Dining', ''),
    new UserImage('icons8-pumpkin-40', 'pumpkin', 'Food & Dining', 'vegie'),
    new UserImage('icons8-raspberry-40', 'raspberry', 'Food & Dining', ''),
    new UserImage('icons8-reservation-40', 'reservation', 'Food & Dining', 'table, dining'),
    new UserImage('icons8-rice-bowl-40', 'rice bowl', 'Food & Dining', 'dining'),
    new UserImage('icons8-sandwich-40', 'sandwich', 'Food & Dining', ''),
    new UserImage('icons8-sashimi-40', 'sashimi', 'Food & Dining', 'japan'),
    new UserImage('icons8-sausages-40', 'sausages', 'Food & Dining', 'hot dog'),
    new UserImage('icons8-shellfish-40', 'shellfish', 'Food & Dining', 'seafood'),
    new UserImage('icons8-spaghetti-40', 'spaghetti', 'Food & Dining', 'pasta, italian'),
    new UserImage('icons8-steak-40', 'steak', 'Food & Dining', 'meat'),
    new UserImage('icons8-strawberry-40', 'strawberry', 'Food & Dining', 'fruit'),
    new UserImage('icons8-sushi-40', 'sushi', 'Food & Dining', 'japan, rice'),
    new UserImage('icons8-tableware-40', 'tableware', 'Food & Dining', 'dining'),
    new UserImage('icons8-thanksgiving-40', 'thanksgiving', 'Food & Dining', 'turkey'),
    new UserImage('icons8-vodka-40', 'vodka', 'Food & Dining', 'alcohol, drink'),
    new UserImage('icons8-watermelon-40', 'watermelon', 'Food & Dining', 'fruit'),
    
    new UserImage('icons8-aed-40', 'aed', 'Health & Well-being', 'heart, first aid, emergency'),
    new UserImage('icons8-aids-ribbon-40', 'aids ribbon', 'Health & Well-being', ''),
    new UserImage('icons8-ambulance-40', 'ambulance', 'Health & Well-being', 'emergency, car'),
    new UserImage('icons8-assistive-technology-40', 'assistive technology', 'Health & Well-being', ''),
    new UserImage('icons8-bandage-40', 'bandage', 'Health & Well-being', ''),
    new UserImage('icons8-brain-40', 'brain', 'Health & Well-being', 'body'),
    new UserImage('icons8-cancer-ribbon-40', 'cancer ribbon', 'Health & Well-being', ''),
    new UserImage('icons8-denture-40', 'denture', 'Health & Well-being', ''),
    new UserImage('icons8-doctors-bag-40', 'doctors bag', 'Health & Well-being', ''),
    new UserImage('icons8-drop-of-blood-40', 'drop of blood', 'Health & Well-being', 'red'),
    new UserImage('icons8-heart-monitor-40', 'heart monitor', 'Health & Well-being', ''),
    new UserImage('icons8-hospital-3-40', 'hospital', 'Health & Well-being', 'building'),
    new UserImage('icons8-hospital-sign-40', 'hospital sign', 'Health & Well-being', ''),
    new UserImage('icons8-lungs-40', 'lungs', 'Health & Well-being', 'body'),
    new UserImage('icons8-medical-heart-40', 'medical heart', 'Health & Well-being', 'body'),
    new UserImage('icons8-muscle-40', 'muscle', 'Health & Well-being', 'body'),
    new UserImage('icons8-pink-ribbon-40', 'pink ribbon', 'Health & Well-being', ''),
    new UserImage('icons8-rod-of-asclepius-40', 'rod of asclepius', 'Health & Well-being', ''),
    new UserImage('icons8-scalpel-40', 'scalpel', 'Health & Well-being', ''),
    new UserImage('icons8-skull-40', 'skull', 'Health & Well-being', 'body'),
    new UserImage('icons8-stethoscope-40', 'stethoscope', 'Health & Well-being', 'doctor'),
    new UserImage('icons8-syringe-40', 'syringe', 'Health & Well-being', 'injection'),
    new UserImage('icons8-tooth-40', 'tooth', 'Health & Well-being', 'dental'),
    new UserImage('icons8-uterus-40', 'uterus', 'Health & Well-being', 'body'),
    
    new UserImage('icons8-aquarium-40', 'aquarium', 'Home & Maintenance', 'fish'),
    new UserImage('icons8-box-tissue-40', 'box tissue', 'Home & Maintenance', ''),
    new UserImage('icons8-broom-40', 'broom', 'Home & Maintenance', 'cleaning'),
    new UserImage('icons8-chair-40', 'chair', 'Home & Maintenance', 'furniture'),
    new UserImage('icons8-closet-40', 'closet', 'Home & Maintenance', 'furniture'),
    new UserImage('icons8-crib-40', 'crib', 'Home & Maintenance', 'baby'),
    new UserImage('icons8-curtains-40', 'curtains', 'Home & Maintenance', ''),
    new UserImage('icons8-desk-lamp-40', 'desk lamp', 'Home & Maintenance', ''),
    new UserImage('icons8-dog-house-40', 'dog house', 'Home & Maintenance', 'pet'),
    new UserImage('icons8-fireplace-40', 'fireplace', 'Home & Maintenance', 'hot'),
    new UserImage('icons8-fridge-40', 'fridge', 'Home & Maintenance', ''),
    new UserImage('icons8-grill-40', 'grill', 'Home & Maintenance', 'cooking'),
    new UserImage('icons8-iron-40', 'iron', 'Home & Maintenance', 'hot'),
    new UserImage('icons8-knitting-40', 'knitting', 'Home & Maintenance', 'hobby'),
    new UserImage('icons8-needle-40', 'needle', 'Home & Maintenance', ''),
    new UserImage('icons8-potted-plant-40', 'potted plant', 'Home & Maintenance', ''),
    new UserImage('icons8-radiator-40', 'radiator', 'Home & Maintenance', 'hot'),
    new UserImage('icons8-sewing-button-40', 'sewing button', 'Home & Maintenance', 'clothes'),
    new UserImage('icons8-sewing-machine-40', 'sewing machine', 'Home & Maintenance', ''),
    new UserImage('icons8-shower-40', 'shower', 'Home & Maintenance', 'water'),
    new UserImage('icons8-sleeping-in-bed-40', 'sleeping in bed', 'Home & Maintenance', ''),
    new UserImage('icons8-sofa-40', 'sofa', 'Home & Maintenance', ''),
    new UserImage('icons8-toaster-40', 'toaster', 'Home & Maintenance', ''),
    new UserImage('icons8-toilet-paper-40', 'toilet paper', 'Home & Maintenance', ''),
    new UserImage('icons8-toothpaste-40', 'toothpaste', 'Home & Maintenance', 'teeth, dentist'),
    new UserImage('icons8-towel-40', 'towel', 'Home & Maintenance', ''),
    new UserImage('icons8-tv-40', 'tv', 'Home & Maintenance', 'electronics'),
    new UserImage('icons8-watering-can-40', 'watering can', 'Home & Maintenance', 'garden, plants'),
    new UserImage('icons8-yarn-40', 'yarn', 'Home & Maintenance', 'hobby'),
    
    new UserImage('icons8-anchor-40', 'anchor', 'Miscellaneous', 'boat'),
    new UserImage('icons8-bell-40', 'bell', 'Miscellaneous', 'ring'),
    new UserImage('icons8-binoculars-40', 'binoculars', 'Miscellaneous', 'eyes'),
    new UserImage('icons8-cardboard-box-40', 'cardboard box', 'Miscellaneous', ''),
    new UserImage('icons8-championship-belt-40', 'championship belt', 'Miscellaneous', ''),
    new UserImage('icons8-charity-40', 'charity', 'Miscellaneous', 'donate'),
    new UserImage('icons8-dancing-40', 'dancing', 'Miscellaneous', 'party'),
    new UserImage('icons8-diamond-40', 'diamond', 'Miscellaneous', 'jewelry'),
    new UserImage('icons8-diver-helmet-40', 'diver helmet', 'Miscellaneous', ''),
    new UserImage('icons8-emerald-40', 'emerald', 'Miscellaneous', 'stone, jewelry'),
    new UserImage('icons8-female-40', 'female', 'Miscellaneous', ''),
    new UserImage('icons8-fire-extinguisher-40', 'fire extinguisher', 'Miscellaneous', 'emergency'),
    new UserImage('icons8-flammable-40', 'flammable', 'Miscellaneous', ''),
    new UserImage('icons8-gas-mask-40', 'gas mask', 'Miscellaneous', 'danger'),
    new UserImage('icons8-goal-40', 'goal', 'Miscellaneous', ''),
    new UserImage('icons8-handshake-40', 'handshake', 'Miscellaneous', ''),
    new UserImage('icons8-hang-40', 'hang', 'Miscellaneous', 'rope'),
    new UserImage('icons8-headstone-40', 'headstone', 'Miscellaneous', 'dead, rip'),
    new UserImage('icons8-heart-outline-40', 'heart outline', 'Miscellaneous', ''),
    new UserImage('icons8-horn-40', 'horn', 'Miscellaneous', 'noise, loud'),
    new UserImage('icons8-horseshoe-40', 'horseshoe', 'Miscellaneous', ''),
    new UserImage('icons8-intelligence-40', 'intelligence', 'Miscellaneous', 'iq'),
    new UserImage('icons8-jewel-40', 'jewel', 'Miscellaneous', ''),
    new UserImage('icons8-law-40', 'law', 'Miscellaneous', ''),
    new UserImage('icons8-magnet-40', 'magnet', 'Miscellaneous', ''),
    new UserImage('icons8-maintenance-40', 'maintenance', 'Miscellaneous', ''),
    new UserImage('icons8-matches-40', 'matches', 'Miscellaneous', 'fire, hot'),
    new UserImage('icons8-metal-detector-40', 'metal detector', 'Miscellaneous', ''),
    new UserImage('icons8-notification-40', 'notification', 'Miscellaneous', ''),
    new UserImage('icons8-pacifier-40', 'pacifier', 'Miscellaneous', 'baby'),
    new UserImage('icons8-peace-symbol-40', 'peace symbol', 'Miscellaneous', ''),
    new UserImage('icons8-restrict-40', 'restrict', 'Miscellaneous', ''),
    new UserImage('icons8-ruby-40', 'ruby', 'Miscellaneous', 'stone, jewelry'),
    new UserImage('icons8-service-bell-40', 'service bell', 'Miscellaneous', 'ring'),
    new UserImage('icons8-sign-language-40', 'sign language', 'Miscellaneous', 'hands'),
    new UserImage('icons8-signature-40', 'signature', 'Miscellaneous', ''),
    new UserImage('icons8-sleigh-40', 'sleigh', 'Miscellaneous', ''),
    new UserImage('icons8-speed-40', 'speed', 'Miscellaneous', ''),
    new UserImage('icons8-spiderweb-40', 'spiderweb', 'Miscellaneous', ''),
    new UserImage('icons8-spotlight-40', 'spotlight', 'Miscellaneous', ''),
    new UserImage('icons8-stamp-40', 'stamp', 'Miscellaneous', ''),
    new UserImage('icons8-star-of-bethlehem-40', 'star of bethlehem', 'Miscellaneous', 'jesus, christmas'),
    new UserImage('icons8-stop-sign-40', 'stop sign', 'Miscellaneous', 'red'),
    new UserImage('icons8-teddy-bear-40', 'teddy bear', 'Miscellaneous', ''),
    new UserImage('icons8-tricycle-40', 'tricycle', 'Miscellaneous', 'toy'),
    new UserImage('icons8-veterinarian-40', 'veterinarian', 'Miscellaneous', 'animal'),
    new UserImage('icons8-walker-40', 'walker', 'Miscellaneous', ''),
    new UserImage('icons8-walking-40', 'walking', 'Miscellaneous', ''),
    new UserImage('icons8-walking-stick-40', 'walking stick', 'Miscellaneous', ''),
    
    new UserImage('icons8-alps-40', 'alps', 'Nature & Weather', 'mountain, swiss'),
    new UserImage('icons8-autumn-40', 'autumn', 'Nature & Weather', 'season, weather'),
    new UserImage('icons8-bamboo-40', 'bamboo', 'Nature & Weather', 'tree'),
    new UserImage('icons8-beach-40', 'beach', 'Nature & Weather', ''),
    new UserImage('icons8-bear-footprint-40', 'bear footprint', 'Nature & Weather', ''),
    new UserImage('icons8-cactus-40', 'cactus', 'Nature & Weather', 'thorn'),
    new UserImage('icons8-campfire-40', 'campfire', 'Nature & Weather', ''),
    new UserImage('icons8-cat-footprint-40', 'cat footprint', 'Nature & Weather', ''),
    new UserImage('icons8-cloud-lightning-40', 'cloud lightning', 'Nature & Weather', 'weather'),
    new UserImage('icons8-clouds-40', 'clouds', 'Nature & Weather', 'sky'),
    new UserImage('icons8-clover-40', 'clover', 'Nature & Weather', 'leaf'),
    new UserImage('icons8-coco-tree-40', 'coco tree', 'Nature & Weather', ''),
    new UserImage('icons8-creek-40', 'creek', 'Nature & Weather', 'water'),
    new UserImage('icons8-dog-paw-40', 'dog paw', 'Nature & Weather', ''),
    new UserImage('icons8-grass-40', 'grass', 'Nature & Weather', ''),
    new UserImage('icons8-hive-40', 'hive', 'Nature & Weather', ''),
    new UserImage('icons8-leaf-40', 'leaf', 'Nature & Weather', ''),
    new UserImage('icons8-lightning-bolt-40', 'lightning bolt', 'Nature & Weather', 'weather'),
    new UserImage('icons8-moon-40', 'moon', 'Nature & Weather', 'space, sky'),
    new UserImage('icons8-mountain-40', 'mountain', 'Nature & Weather', ''),
    new UserImage('icons8-nature-40', 'nature', 'Nature & Weather', ''),
    new UserImage('icons8-orchid-40', 'orchid', 'Nature & Weather', ''),
    new UserImage('icons8-palm-tree-40', 'palm tree', 'Nature & Weather', ''),
    new UserImage('icons8-partly-cloudy-day-40', 'partly cloudy day', 'Nature & Weather', 'weather'),
    new UserImage('icons8-rainbow-40', 'rainbow', 'Nature & Weather', 'weather, pride'),
    new UserImage('icons8-rose-40', 'rose', 'Nature & Weather', 'flower'),
    new UserImage('icons8-spring-40', 'spring', 'Nature & Weather', 'weather, season'),
    new UserImage('icons8-storm-40', 'storm', 'Nature & Weather', 'weather'),
    new UserImage('icons8-summer-40', 'summer', 'Nature & Weather', 'weather, season'),
    new UserImage('icons8-sun-star-40', 'sun star', 'Nature & Weather', 'space, sky, yellow'),
    new UserImage('icons8-sunflower-40', 'sunflower', 'Nature & Weather', 'yellow'),
    new UserImage('icons8-tree-planting-40', 'tree planting', 'Nature & Weather', ''),
    new UserImage('icons8-volcano-40', 'volcano', 'Nature & Weather', 'eruption, lava'),
    new UserImage('icons8-wheat-40', 'wheat', 'Nature & Weather', ''),
    new UserImage('icons8-winter-40', 'winter', 'Nature & Weather', 'season, weather'),
    
    new UserImage('icons8-asian-character-40', 'asian character', 'People', 'chinese'),
    new UserImage('icons8-baby-40', 'baby', 'People', 'family'),
    new UserImage('icons8-boy-40', 'boy', 'People', 'family'),
    new UserImage('icons8-businessman-40', 'businessman', 'People', 'profession'),
    new UserImage('icons8-businesswoman-40', 'businesswoman', 'People', ''),
    new UserImage('icons8-caveman-40', 'caveman', 'People', ''),
    new UserImage('icons8-child-40', 'child', 'People', ''),
    new UserImage('icons8-einstein-40', 'Einstein', 'People', 'genius'),
    new UserImage('icons8-employee-40', 'employee', 'People', ''),
    new UserImage('icons8-engineer-40', 'engineer', 'People', ''),
    new UserImage('icons8-family-40', 'family', 'People', ''),
    new UserImage('icons8-farmer-40', 'farmer', 'People', 'profession'),
    new UserImage('icons8-female-worker-40', 'female worker', 'People', ''),
    new UserImage('icons8-firefighter-40', 'firefighter', 'People', 'profession, emergency'),
    new UserImage('icons8-fortune-teller-40', 'fortune teller', 'People', ''),
    new UserImage('icons8-gardener-40', 'gardener', 'People', 'profession'),
    new UserImage('icons8-girl-40', 'girl', 'People', 'family'),
    new UserImage('icons8-grandmother-40', 'grandmother', 'People', 'family'),
    new UserImage('icons8-grandpa-40', 'grandpa', 'People', 'family'),
    new UserImage('icons8-judge-40', 'judge', 'People', 'government, law, profession'),
    new UserImage('icons8-lady-40', 'lady', 'People', ''),
    new UserImage('icons8-man-40', 'man', 'People', ''),
    new UserImage('icons8-manager-40', 'manager', 'People', 'profession'),
    new UserImage('icons8-mechanic-40', 'mechanic', 'People', 'profession'),
    new UserImage('icons8-medical-doctor-40', 'medical doctor', 'People', 'profession, health'),
    new UserImage('icons8-nurse-40', 'nurse', 'People', 'profession, health'),
    new UserImage('icons8-romance-40', 'romance', 'People', 'love'),
    new UserImage('icons8-royal-40', 'royal', 'People', 'king, queen'),
    new UserImage('icons8-spy-40', 'spy', 'People', ''),
    new UserImage('icons8-technical-support-40', 'technical support', 'People', ''),
    new UserImage('icons8-user-male-40', 'user male', 'People', ''),
    new UserImage('icons8-waiter-40', 'waiter', 'People', 'restaurant, dining, profession'),
    new UserImage('icons8-worker-40', 'worker', 'People', 'profession'),
    
    new UserImage('icons8-address-40', 'address', 'Places', ''),
    new UserImage('icons8-address-book-40', 'address book', 'Places', ''),
    new UserImage('icons8-around-the-globe-40', 'around the globe', 'Places', 'world'),
    new UserImage('icons8-big-ben-40', 'big ben', 'Places', 'london'),
    new UserImage('icons8-cologne-cathedral-40', 'cologne cathedral', 'Places', 'germany'),
    new UserImage('icons8-colosseum-40', 'colosseum', 'Places', 'italy'),
    new UserImage('icons8-eiffel-tower-40', 'eiffel tower', 'Places', 'paris, france, tourism, travel'),
    new UserImage('icons8-farm-40', 'farm', 'Places', ''),
    new UserImage('icons8-gps-40', 'gps', 'Places', 'address'),
    new UserImage('icons8-kremlin-40', 'kremlin', 'Places', 'russia'),
    new UserImage('icons8-mailbox-40', 'mailbox', 'Places', ''),
    new UserImage('icons8-palace-40', 'palace', 'Places', 'building, royalty, king, queen'),
    new UserImage('icons8-ruins-40', 'ruins', 'Places', ''),
    new UserImage('icons8-statue-of-christ-the-redeemer-40', 'statue of christ the redeemer', 'Places', 'art'),
    new UserImage('icons8-statue-of-liberty-40', 'statue of liberty', 'Places', 'new york'),
    new UserImage('icons8-taipei-towers-40', 'taipei towers', 'Places', 'taiwan'),
    new UserImage('icons8-taj-mahal-40', 'taj mahal', 'Places', 'india, tourism, travel'),
    new UserImage('icons8-tower-of-pisa-40', 'tower of pisa', 'Places', 'italy, tourism, travel'),
    new UserImage('icons8-triumphal-arch-40', 'triumphal arch', 'Places', 'paris, france, tourism, travel'),
    new UserImage('icons8-us-capitol-40', 'US capitol', 'Places', 'USA, government, congress'),
    
    new UserImage('icons8-archeology-40', 'archeology', 'Science', ''),
    new UserImage('icons8-da-vinci-40', 'da vinci', 'Science', 'genius, renaissance'),
    new UserImage('icons8-dna-helix-40', 'dna helix', 'Science', 'genes'),
    new UserImage('icons8-earth-planet-40', 'earth planet', 'Science', 'space'),
    new UserImage('icons8-infinity-40', 'infinity', 'Science', ''),
    new UserImage('icons8-microscope-40', 'microscope', 'Science', ''),
    new UserImage('icons8-physics-40', 'physics', 'Science', ''),
    new UserImage('icons8-planetarium-40', 'planetarium', 'Science', ''),
    new UserImage('icons8-psychology-40', 'psychology', 'Science', ''),
    new UserImage('icons8-round-bottom-flask-40', 'round bottom flask', 'Science', 'chemistry'),
    new UserImage('icons8-scales-40', 'scales', 'Science', ''),
    new UserImage('icons8-temperature-40', 'temperature', 'Science', ''),
    new UserImage('icons8-test-tube-40', 'test tube', 'Science', 'chemistry'),
    new UserImage('icons8-test-tube-rack-40', 'test tube rack', 'Science', 'chemistry'),
    new UserImage('icons8-volume-40', 'volume', 'Science', ''),
    
    new UserImage('icons8-american-express-40', 'american express', 'Shopping', 'credit card, money'),
    new UserImage('icons8-barcode-40', 'barcode', 'Shopping', ''),
    new UserImage('icons8-basket-40', 'basket', 'Shopping', ''),
    new UserImage('icons8-closed-sign-40', 'closed sign', 'Shopping', ''),
    new UserImage('icons8-coupon-40', 'coupon', 'Shopping', 'discount'),
    new UserImage('icons8-gift-card-40', 'gift card', 'Shopping', ''),
    new UserImage('icons8-mastercard-40', 'mastercard', 'Shopping', 'credit card, money'),
    new UserImage('icons8-open-sign-40', 'open sign', 'Shopping', ''),
    new UserImage('icons8-packaging-40', 'packaging', 'Shopping', ''),
    new UserImage('icons8-price-tag-40', 'price tag', 'Shopping', ''),
    new UserImage('icons8-sale-40', 'sale', 'Shopping', ''),
    new UserImage('icons8-shop-40', 'shop', 'Shopping', ''),
    new UserImage('icons8-shopping-bag-40', 'shopping bag', 'Shopping', ''),
    new UserImage('icons8-shopping-cart-40', 'shopping cart', 'Shopping', ''),
    new UserImage('icons8-visa-40', 'visa', 'Shopping', 'credit card, money'),
    
    new UserImage('icons8-archery-40', 'archery', 'Sports', 'bow, arrow'),
    new UserImage('icons8-ballerina-40', 'ballerina', 'Sports', 'dance'),
    new UserImage('icons8-basketball-40', 'basketball', 'Sports', ''),
    new UserImage('icons8-bobsleigh-40', 'bobsleigh', 'Sports', 'snow, winter'),
    new UserImage('icons8-bowling-40', 'bowling', 'Sports', 'strike'),
    new UserImage('icons8-boxing-glove-40', 'boxing glove', 'Sports', ''),
    new UserImage('icons8-canoe-40', 'canoe', 'Sports', 'boat'),
    new UserImage('icons8-car-racing-40', 'car racing', 'Sports', ''),
    new UserImage('icons8-cricket-40', 'cricket', 'Sports', 'india'),
    new UserImage('icons8-curling-40', 'curling', 'Sports', 'snow, winter'),
    new UserImage('icons8-cycling-40', 'cycling', 'Sports', 'bike'),
    new UserImage('icons8-diving-40', 'diving', 'Sports', ''),
    new UserImage('icons8-dumbbell-40', 'dumbbell', 'Sports', 'muscle'),
    new UserImage('icons8-escrima-sticks-40', 'escrima sticks', 'Sports', ''),
    new UserImage('icons8-finish-flag-40', 'finish flag', 'Sports', ''),
    new UserImage('icons8-foul-40', 'foul', 'Sports', 'referree'),
    new UserImage('icons8-golf-40', 'golf', 'Sports', ''),
    new UserImage('icons8-gymnastics-40', 'gymnastics', 'Sports', ''),
    new UserImage('icons8-hockey-40', 'hockey', 'Sports', 'ice'),
    new UserImage('icons8-horseback-riding-40', 'horseback riding', 'Sports', ''),
    new UserImage('icons8-judo-40', 'judo', 'Sports', 'martial arts'),
    new UserImage('icons8-medal-first-place-40', 'medal first place', 'Sports', ''),
    new UserImage('icons8-olympic-rings-40', 'olympic rings', 'Sports', ''),
    new UserImage('icons8-parachute-40', 'parachute', 'Sports', ''),
    new UserImage('icons8-racket-40', 'racket', 'Sports', ''),
    new UserImage('icons8-roller-skates-40', 'roller skates', 'Sports', ''),
    new UserImage('icons8-rugby-40', 'rugby', 'Sports', ''),
    new UserImage('icons8-sailing-40', 'sailing', 'Sports', 'boat'),
    new UserImage('icons8-scuba-diving-40', 'scuba diving', 'Sports', ''),
    new UserImage('icons8-shooting-40', 'shooting', 'Sports', 'gun'),
    new UserImage('icons8-shuttercock-40', 'shuttercock', 'Sports', ''),
    new UserImage('icons8-skateboard-40', 'skateboard', 'Sports', ''),
    new UserImage('icons8-skateboarding-40', 'skateboarding', 'Sports', ''),
    new UserImage('icons8-skiing-40', 'skiing', 'Sports', 'snow, winter'),
    new UserImage('icons8-soccer-40', 'soccer', 'Sports', ''),
    new UserImage('icons8-soccer-ball-40', 'soccer ball', 'Sports', ''),
    new UserImage('icons8-speed-skating-40', 'speed skating', 'Sports', ''),
    new UserImage('icons8-surf-40', 'surf', 'Sports', 'beach'),
    new UserImage('icons8-swimming-40', 'swimming', 'Sports', ''),
    new UserImage('icons8-tennis-ball-40', 'tennis ball', 'Sports', ''),
    new UserImage('icons8-trophy-40', 'trophy', 'Sports', ''),
    new UserImage('icons8-weightlifting-40', 'weightlifting', 'Sports', ''),
    new UserImage('icons8-whistle-40', 'whistle', 'Sports', ''),
    new UserImage('icons8-world-cup-40', 'world cup', 'Sports', ''),
    new UserImage('icons8-wrestling-40', 'wrestling', 'Sports', ''),
    new UserImage('icons8-yoga-40', 'yoga', 'Sports', ''),
    new UserImage('icons8-yoga-mat-40', 'yoga mat', 'Sports', ''),
    
    new UserImage('icons8-android-40', 'android', 'Technology & Devices', 'phone'),
    new UserImage('icons8-android-tablet-40', 'android tablet', 'Technology & Devices', ''),
    new UserImage('icons8-apple-watch-40', 'apple watch', 'Technology & Devices', ''),
    new UserImage('icons8-biohazard-40', 'biohazard', 'Technology & Devices', ''),
    new UserImage('icons8-bot-40', 'bot', 'Technology & Devices', ''),
    new UserImage('icons8-camcorder-40', 'camcorder', 'Technology & Devices', ''),
    new UserImage('icons8-car-battery-40', 'car battery', 'Technology & Devices', ''),
    new UserImage('icons8-cell-phone-40', 'cell phone', 'Technology & Devices', ''),
    new UserImage('icons8-cloud-40', 'cloud', 'Technology & Devices', ''),
    new UserImage('icons8-coil-40', 'coil', 'Technology & Devices', ''),
    new UserImage('icons8-drone-40', 'drone', 'Technology & Devices', ''),
    new UserImage('icons8-elevator-40', 'elevator', 'Technology & Devices', ''),
    new UserImage('icons8-engine-40', 'engine', 'Technology & Devices', ''),
    new UserImage('icons8-film-roll-40', 'film roll', 'Technology & Devices', 'photo'),
    new UserImage('icons8-gps-signal-40', 'gps signal', 'Technology & Devices', ''),
    new UserImage('icons8-gyroscope-40', 'gyroscope', 'Technology & Devices', ''),
    new UserImage('icons8-internet-40', 'internet', 'Technology & Devices', ''),
    new UserImage('icons8-ipad-40', 'ipad', 'Technology & Devices', ''),
    new UserImage('icons8-ipad-mini-40', 'ipad mini', 'Technology & Devices', ''),
    new UserImage('icons8-iphone-40', 'iphone', 'Technology & Devices', ''),
    new UserImage('icons8-iphone-x-40', 'iphone x', 'Technology & Devices', ''),
    new UserImage('icons8-kindle-40', 'kindle', 'Technology & Devices', 'reading'),
    new UserImage('icons8-lighthouse-40', 'lighthouse', 'Technology & Devices', ''),
    new UserImage('icons8-magnetic-40', 'magnetic', 'Technology & Devices', ''),
    new UserImage('icons8-movie-projector-40', 'movie projector', 'Technology & Devices', ''),
    new UserImage('icons8-nokia-3310-40', 'nokia 3310', 'Technology & Devices', 'phone'),
    new UserImage('icons8-office-phone-40', 'office phone', 'Technology & Devices', ''),
    new UserImage('icons8-oil-pump-40', 'oil pump', 'Technology & Devices', 'gas'),
    new UserImage('icons8-printer-40', 'printer', 'Technology & Devices', ''),
    new UserImage('icons8-radar-40', 'radar', 'Technology & Devices', ''),
    new UserImage('icons8-radio-station-40', 'radio station', 'Technology & Devices', ''),
    new UserImage('icons8-radioactive-40', 'radioactive', 'Technology & Devices', 'nuclear, danger'),
    new UserImage('icons8-recycle-bin-40', 'recycle bin', 'Technology & Devices', ''),
    new UserImage('icons8-remote-control-40', 'remote control', 'Technology & Devices', 'tv'),
    new UserImage('icons8-rocket-40', 'rocket', 'Technology & Devices', ''),
    new UserImage('icons8-satellite-signal-40', 'satellite signal', 'Technology & Devices', ''),
    new UserImage('icons8-smart-watch-40', 'smart watch', 'Technology & Devices', ''),
    new UserImage('icons8-smartphone-tablet-40', 'smartphone tablet', 'Technology & Devices', ''),
    new UserImage('icons8-space-shuttle-40', 'space shuttle', 'Technology & Devices', ''),
    new UserImage('icons8-steam-engine-40', 'steam engine', 'Technology & Devices', ''),
    new UserImage('icons8-telephone-40', 'telephone', 'Technology & Devices', 'ring'),
    new UserImage('icons8-telescope-40', 'telescope', 'Technology & Devices', ''),
    new UserImage('icons8-video-camera-40', 'video camera', 'Technology & Devices', ''),
    new UserImage('icons8-weight-kg-40', 'weight kg', 'Technology & Devices', ''),
    new UserImage('icons8-windmill-40', 'windmill', 'Technology & Devices', 'energy'),
    
    new UserImage('icons8-caliper-40', 'caliper', 'Tools', 'measure'),
    new UserImage('icons8-chainsaw-40', 'chainsaw', 'Tools', ''),
    new UserImage('icons8-construction-trowel-40', 'construction trowel', 'Tools', ''),
    new UserImage('icons8-garden-shears-40', 'garden shears', 'Tools', ''),
    new UserImage('icons8-hammer-40', 'hammer', 'Tools', ''),
    new UserImage('icons8-hatchet-40', 'hatchet', 'Tools', ''),
    new UserImage('icons8-hot-glue-gun-40', 'hot glue gun', 'Tools', ''),
    new UserImage('icons8-level-tool-40', 'level tool', 'Tools', 'measure'),
    new UserImage('icons8-nail-40', 'nail', 'Tools', ''),
    new UserImage('icons8-paint-brush-40', 'paint brush', 'Tools', ''),
    new UserImage('icons8-paint-bucket-40', 'paint bucket', 'Tools', ''),
    new UserImage('icons8-pitchfork-40', 'pitchfork', 'Tools', ''),
    new UserImage('icons8-pliers-40', 'pliers', 'Tools', ''),
    new UserImage('icons8-saw-40', 'saw', 'Tools', ''),
    new UserImage('icons8-screw-40', 'screw', 'Tools', ''),
    new UserImage('icons8-screw-eye-40', 'screw eye', 'Tools', ''),
    new UserImage('icons8-screwdriver-40', 'screwdriver', 'Tools', ''),
    new UserImage('icons8-shpatel-40', 'shpatel', 'Tools', ''),
    new UserImage('icons8-sledgehammer-40', 'sledgehammer', 'Tools', ''),
    new UserImage('icons8-spade-40', 'spade', 'Tools', ''),
    new UserImage('icons8-swiss-army-knife-40', 'swiss army knife', 'Tools', ''),
    new UserImage('icons8-tape-measure-40', 'tape measure', 'Tools', ''),
    new UserImage('icons8-toolbox-40', 'toolbox', 'Tools', ''),
    
    new UserImage('icons8-aircraft-40', 'aircraft', 'Transportation & Vehicles', 'fly'),
    new UserImage('icons8-airplane-40', 'airplane', 'Transportation & Vehicles', 'fly'),
    new UserImage('icons8-airplane-landing-40', 'airplane landing', 'Transportation & Vehicles', ''),
    new UserImage('icons8-bulldozer-40', 'bulldozer', 'Transportation & Vehicles', ''),
    new UserImage('icons8-bus-40', 'bus', 'Transportation & Vehicles', 'car'),
    new UserImage('icons8-camper-40', 'camper', 'Transportation & Vehicles', ''),
    new UserImage('icons8-car-40', 'car', 'Transportation & Vehicles', ''),
    new UserImage('icons8-cargo-ship-40', 'cargo ship', 'Transportation & Vehicles', 'boat'),
    new UserImage('icons8-crane-40', 'crane', 'Transportation & Vehicles', ''),
    new UserImage('icons8-cruise-ship-40', 'cruise ship', 'Transportation & Vehicles', 'boat'),
    new UserImage('icons8-fire-truck-40', 'fire truck', 'Transportation & Vehicles', ''),
    new UserImage('icons8-fork-lift-40', 'fork lift', 'Transportation & Vehicles', ''),
    new UserImage('icons8-garbage-truck-40', 'garbage truck', 'Transportation & Vehicles', 'car'),
    new UserImage('icons8-go-kart-40', 'go kart', 'Transportation & Vehicles', 'racing'),
    new UserImage('icons8-helicopter-40', 'helicopter', 'Transportation & Vehicles', 'fly'),
    new UserImage('icons8-hot-air-balloon-40', 'hot air balloon', 'Transportation & Vehicles', 'fly'),
    new UserImage('icons8-motorcycle-40', 'motorcycle', 'Transportation & Vehicles', ''),
    new UserImage('icons8-race-car-40', 'race car', 'Transportation & Vehicles', ''),
    new UserImage('icons8-sail-boat-40', 'sail boat', 'Transportation & Vehicles', ''),
    new UserImage('icons8-scooter-40', 'scooter', 'Transportation & Vehicles', ''),
    new UserImage('icons8-segway-40', 'segway', 'Transportation & Vehicles', ''),
    new UserImage('icons8-ship-wheel-40', 'ship wheel', 'Transportation & Vehicles', ''),
    new UserImage('icons8-steering-wheel-40', 'steering wheel', 'Transportation & Vehicles', ''),
    new UserImage('icons8-taxi-40', 'taxi', 'Transportation & Vehicles', 'car'),
    new UserImage('icons8-tesla-model-x-40', 'tesla model x', 'Transportation & Vehicles', 'car'),
    new UserImage('icons8-tow-truck-40', 'tow truck', 'Transportation & Vehicles', 'car'),
    new UserImage('icons8-tractor-40', 'tractor', 'Transportation & Vehicles', 'farm'),
    new UserImage('icons8-train-40', 'train', 'Transportation & Vehicles', ''),
    new UserImage('icons8-unicycle-40', 'unicycle', 'Transportation & Vehicles', ''),
    new UserImage('icons8-van-40', 'van', 'Transportation & Vehicles', 'car'),
    
]


