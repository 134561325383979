export default {
    descriptionHeader   : 'Description',
    detailsHeader       : 'Details',
    dueDatesHeader      : 'Dates',
    labelsHeader        : 'Labels',
    nameLabel           : 'Name:',
    nameHint            : 'Enter info name',
    notesHint           : 'Enter additional details about this info',
    categoryLabel       : 'Category:',
    expertiseLabel      : 'Expertise:',
    importanceLabel     : 'Importance:',
    notesLabel          : 'Notes:',
    startDateLabel      : 'Start Date:',
    endDateLabel        : 'End Date:',
    tagLabel            : 'Labels & Tags:',

    // help
    categoryInfo        : {title:'Category',content:'The category allows you to group your info in a hierarchy. A category with >> symbol has more sub-categories.'},
    expertiseInfo        : {title:'Expertise',content:'Set your expertise for this info'},
    importanceInfo      : {title:'Importance',content:'Set the relative importance of this info. Importance indicates necessity and usefulness.'},
    notesInfo           : {title:'Notes',content:'Add details to this info, you can write text or links.'},
    startDateInfo       : {title:'Start Date',content:'Set the start date for this info. Together with end date, they determine the duration for this info.'},
    endDateInfo         : {title:'End Date',content:'Set the completion date for this info. Together with start date, they determine the duration for this info.'},
    labelInfo           : {title:'Labels',content:'Labels allow you to put tags on your info to group them cross-sectionally. If category is vertical grouping, label is horizontal grouping.'},

    // error messages
    emptyNameError      : 'You forgot to input info name, duh!',
    emptyCategoryError  : 'Hey, you forgot to choose a category! You can create one by tapping the list button on the side.',
    startDateError      : 'Oops, can\'t parse the start date!',
    endDateError        : 'Oops, can\'t parse the end date!',
    dateRangeError      : `Tsk-tsk, the end date is before the start date.`,

    saveError           : 'Error saving info: ', 
    deleteError         : 'Error deleting info: ', 

    deleteWarning       : {title:'Delete Info',content:'Hold it there, this will delete the info and cannot be undone. Would you like to continue?'},

    // popup messages
    validationError     : 'Uh oh, errors found!',
    save                : 'Saving info...',
    saveNew             : 'Saving & new...',
    delete              : 'Deleting info...'
}