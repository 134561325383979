import {InfoCell} from './InfoCell';
import {InfoCategory} from './InfoCategory'
import {InfoLabel} from './InfoLabel'

import {StateManager} from '../stateManager/StateManager'
import { isEmpty, Utils } from '../util/Utils';
import * as Constants from '../util/InfoConstants'

import * as InfoManager from './InfoManager'
import * as CategoryManager from '../data/CategoryManager'
import * as LabelManager from '../data/LabelManager'

InfoCell.toString(); // suppress warning
InfoCategory.toString(); // suppress warning
InfoLabel.toString(); // suppress warning

class AllData {
    /**@type {InfoCell[]} */ cells=[];
    /**@type {InfoCategory[]} */ categories=[];
    /**@type {InfoLabel[]} */ labels=[];
    // add others here
}

export const checkUserLogin = () => {
    try {
        let userEmail = Constants.ENVIRONMENT==='prod' ? getToken() : Constants.DEV_USER_ID;
        userEmail = Utils.isEmpty(userEmail) ? Constants.DEFAULT_USER_ID : '';
        if (Utils.isEmpty(userEmail)) return null;
        
        // update user id to use
        if (Utils.isEmpty(Constants.LOGIN_USER.USER_ID)) {
            // format: email_TASK
            Constants.LOGIN_USER.USER_ID = userEmail + Constants.ID.LINK_CHAR + Constants.ID.CELL_ID_PREFIX;
        }
        return userEmail;
            
    } catch (error) {
        Utils.error('Error in checking user login! ', error.message);
        return null;
    }
}

const getToken = () => {
    const token = unhash( document.cookie.split(';')[0].substring(6) );
    if (/^.+@.+\..+$/.test(token)) return token;
}

/**
 * 
 * @param {string} src 
 * @returns {string}
 * 
 * todo: move to shared component
 */
export const unhash = (src) => {

    if (Utils.isEmpty(src)) return '';

    src = atob(src);

    const max = src.lastIndexOf('@')-1;
    const rnd = parseInt(src[max]);
    let orig = '';

    for (let i=0; i<max; i++) {
        let code = src.charCodeAt(i) - i - rnd;
        orig += String.fromCharCode(code);
    }
    return orig;
}



/**
 * save all state as one key, this is to save I/O operation
 * 
 */
export const saveAll = () => {
    Utils.debug('saving all to local storage...');
    try {
        // save the entire state as one item to local storage
        const allData = buildAllData(
            StateManager.getData().cells.cells, 
            StateManager.getData().categories.categories,
            StateManager.getData().labels.labels
            );
        localStorage.setItem(Constants.LOGIN_USER.USER_ID, JSON.stringify(allData));        
    } catch (error) {
        Utils.error('Error in persisting all data: ', error.message);
        throw new Error('Error in saving data to local storage!');  // create a new error with user-friendly message
    }
}


export const retrieveAll = () => {
    try {
        const data = localStorage.getItem(Constants.LOGIN_USER.USER_ID);
        if (isEmpty(data)) return;
        
        /**@type {AllData} */
        const allData = JSON.parse(data);
        const cells = isEmpty(allData.cells) ? [] : allData.cells;
        const categories = isEmpty(allData.categories) ? [] : allData.categories;
        const labels = isEmpty(allData.labels) ? [] : allData.labels;
    
        InfoManager.insertAll(cells);
        CategoryManager.insertAll(categories);
        LabelManager.insertAll(labels);
            
    } catch (error) {
        Utils.error('Error in retrieving data from localStorage! ', error.message);
        throw new Error('Could not retrieve data from local storage!');
    }
}


/**
 * @param {InfoCell[]} cells
 * @param {InfoCategory[]} categories
 * @param {InfoLabel[]} labels
 * @returns {AllData}
 */
const buildAllData = (cells, categories, labels) => {
    const allData = new AllData();
    allData.cells = cells
    allData.categories = categories;
    allData.labels = labels;
    return allData;
}

/**
 * adapted from :  https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Feature-detecting_localStorage
 * 
 * @param {string} storageType can also pass 'sessionStorage'
 * 
 */
export const isStorageAvailable = (storageType='localStorage') => {
    let storage;
    try {
        storage = window[storageType];
        let x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    }
    catch(e) {
        return e instanceof DOMException && (
            // everything except Firefox
            e.code === 22 ||
            // Firefox
            e.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            e.name === 'QuotaExceededError' ||
            // Firefox
            e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            (storage && storage.length !== 0);
    }
}