
export class InfoLabel {

        // identification
        
        /**@type {string} */ cellType = 'INFO_LABEL';        // REQUIRED !
        /**@type {string} */ labelId = '';               // REQUIRED !
        /**@type {string} */ name = '';                         // REQUIRED !

        // styling

        /**@type {string} */ image = '';                // icon filename
        /**@type {string} */ backgroundColor = '';      // in #FFFFFF
        /**@type {string} */ fontColor = '';

    
}


