export default {

    title               : 'Settings',
    categoryLabel       : 'Default Category:',
    storageLabel        : 'Local Storage:',
    maxStorage          : 'Maximum Storage',
    currUsage           : 'Current Usage',
    percentUsage        : 'Percent Used',

    sectionHeader       : 'Settings & Configuration',

    saving              : 'Saving settings...',

    none                : '(None)',
    deleteCompletedLabel: 'Delete completed tasks?',
    deleteButton        : 'Delete',

    deleteTitle         : 'Delete Tasks',
    deleteTexts         : 'This will delete all tasks with status completed. Would you like to continue?'

}
