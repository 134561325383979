import {StateManager} from './StateManager';
import {ActionType} from './ActionTypes'

export class NotificationStoreState {

    /**@type {string} */ snackbarMsg = '';
    /**@type {string} */ snackbarType = '';

    /**@type {string} */ dialogTitle = '';
    /**@type {string} */ dialogContent = '';
    /**@type {string} */ dialogAnswer = '';
    /**@type {string} */ dialogType = '';

}

/** @type {NotificationStoreState} */
let initialState = new NotificationStoreState();


///////////////// actions

/**
 * @typedef {Object} Action 
 * @property {string} type
 * @property {string} [snackbarMsg]
 * @property {string} [snackbarType]
 * @property {string} [dialogTitle]
 * @property {string} [dialogContent]
 * @property {string} [dialogType]
 * @property {string} [dialogAnswer]
 */

  /**
  * @param {string} snackbarMsg 
  * @param {string} [snackbarType] 
  * @return {Action}
  */
 const updateSnackbarAction = (snackbarMsg, snackbarType) => {
    return {
        type: ActionType.UPDATE_SNACKBAR,
        snackbarMsg,
        snackbarType,
    }
 }

  /**
  * @param {string} dialogTitle 
  * @param {string} dialogContent
  * @param {string} [dialogType] 
  * @return {Action}
  */
 const updateDialogAction = (dialogTitle, dialogContent, dialogType) => {
    return {
        type: ActionType.UPDATE_DIALOG,
        dialogTitle,
        dialogContent,
        dialogType
    }
 }


 ///////////////// reducer

 /** 
  * @param {Action} action 
  * @return {NotificationStoreState}
  */
export let notificationReducer = (state = initialState, action) => {
    const newState = {...state}; //new NotificationStoreState();
    switch (action.type) {

        case ActionType.UPDATE_SNACKBAR:
            newState.snackbarMsg = action.snackbarMsg;
            newState.snackbarType = action.snackbarType;

            return newState;
            // return { 
            //     snackbarMsg: action.snackbarMsg,
            //     snackbarType: action.snackbarType
            // }
        case ActionType.UPDATE_DIALOG:
            newState.dialogTitle = action.dialogTitle;
            newState.dialogContent = action.dialogContent;
            newState.dialogType = action.dialogType
            return newState;
        case ActionType.UPDATE_DIALOG_ANSWER:
            newState.dialogAnswer = action.dialogAnswer;
            return newState;
        default:
            return state;
    }
}

/////////////////////////// helper public functions

/** @param {string} msg  */
export const setSnackbar = (msg, type='') => {
    StateManager.store.dispatch(updateSnackbarAction(msg, type));
}

/**
 * @typedef {Object}  Snackbar
 * @property {string} msg
 * @property {string} type
 * 
 * @returns {Snackbar}  */
export const getSnackbar = () => {
    return {
        msg: StateManager.getData().notification.snackbarMsg,
        type: StateManager.getData().notification.snackbarType,
    }
}


/**
 * 
 * @param {string} title 
 * @param {string} content 
 * @param {string} type 
 */
export const setDialog = (title, content, type='') => {
    StateManager.store.dispatch(updateDialogAction(title, content, type));
}

export const clearDialog = () => {
    StateManager.store.dispatch(updateDialogAction('', '', ''));
}

/**
 * @typedef {Object}  Dialog
 * @property {string} title
 * @property {string} content
 * @property {string} type
 * @property {string} answer
 * 
 * 
 * @returns {Dialog}  */
export const getDialog = () => {
    return {
        title: StateManager.getData().notification.dialogTitle,
        content: StateManager.getData().notification.dialogContent,
        type: StateManager.getData().notification.dialogType,
        answer: StateManager.getData().notification.dialogAnswer,
    }
}
