import React from 'react';
import * as InfoManager from '../../data/InfoManager'
import * as CategoryManager from '../../data/CategoryManager'
import * as LabelManager from '../../data/LabelManager'

import { ByLabelNavbar } from './ByLabelNavBar';
import { ByLabelView } from './ByLabelView'

import { goToPage, goBack } from '../../navigation/PageManager'
import * as PageManager from '../../navigation/PageManager'
import * as page from '../../util/PageConstants'
import { InfoCell } from '../../data/InfoCell';
import { isEmpty, logWarn, Utils } from '../../util/Utils';
import { StateManager } from '../../stateManager/StateManager';
import { GroupLabel, CategoryLevel, InfoLevel } from './ViewData';
import {sortLabels} from '../../util/ViewHelper'
import { InfoLabel } from '../../data/InfoLabel';
import * as ViewHelper from '../../util/ViewHelper'
import { Fields as FilterFields } from '../../components/filter/FilterState';
import { Filter } from '../../components/filter/Filter';
import * as FilterHelper from '../../util/FilterHelper'
import pStyles from './ByLabel.module.css';    // page-specific styles
import { SwitchView } from '../../components/switchView/SwitchView';
import {Notification} from '../../components/notification/Notification'
import gTexts from '../../resources/texts';

InfoCell.toString();    // suppress warning
InfoLabel.toString();    // suppress warning

/**
 * View by Label Page
 * - container component
 * 
 * by Ludwin on 2019/11/17
 */

/**
 * @typedef {Object} Props
 * 
 * @typedef {Object} State
 * @property {Map} allGroups
 * @property {string} lastUpdated
 * @property {boolean} isShowMenuOptions
 * @property {boolean} isPageVertical
 * @property {boolean} isExpandAll
 * @property {boolean} isShowFilter
 * @property {FilterFields} filterFields
 * @property {boolean} isShowViews
 * 
 */

/** @extends {React.Component<Props, State>} */
export default class ByLabel extends React.Component {

    /** @param {Props} props */
    constructor(props) {
        super(props);

        this.allGroups = new Map();

        this.buildDataModel();

        /**@type {State} */
        this.state = {
            allGroups: this.allGroups,
            lastUpdated: StateManager.getData().cells.lastUpdated,
            isShowMenuOptions: false,
            isPageVertical: true,
            isExpandAll: true,
            isShowFilter: false,
            filterFields: new FilterFields(),
            isShowViews: false,

        };
    }


    componentDidMount() {
        // log ('Importance did mount');
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.lastUpdated !== StateManager.getData().cells.lastUpdated) {
            this.buildDataModel();  // rebuild allGroups when info changed (add, delete, update) - their categories may have changed
            this.setState({
                lastUpdated: StateManager.getData().cells.lastUpdated,
                allGroups: this.allGroups,
            });
        }
    }

    render() {
        // log ('Importance, render...');
        return (
            <>
                <ByLabelNavbar
                    isExpandAll         ={this.state.isExpandAll}
                    isShowMenuOptions   ={this.state.isShowMenuOptions}
                    isPageVertical      ={this.state.isPageVertical}
                    hasFilter           ={FilterHelper.hasFilter(this.state.filterFields)}
                    onClickBack         ={this.onClickBack}
                    onClickCreate       ={this.onClickCreate}
                    onClickSearch       ={this.onClickSearch}
                    onClickMenuOptions  ={this.onClickMenuOptions}
                    onClickPaging       ={this.onClickPaging}
                    onClickFilter       ={this.onClickFilter}
                    onClickSettings     ={this.onClickSettings}
                    onExpandAll         ={this.onExpandAll}
                    onClickHome         ={this.onClickHome}
                    onSwitchView        ={this.onSwitchView}
                />

                {/* filter */}
                <div className={pStyles.filterContainer}>
                    {this.state.isShowFilter &&
                        <Filter
                            currFilter={this.state.filterFields}
                            onSetFilter={this.onSetFilter}
                        />
                    }
                </div>

                {/* switch views */}
                {this.state.isShowViews &&
                    <SwitchView
                        currPage={page.VIEW_BY_LABEL}
                        onClose={this.onSwitchView}
                    />
                }

                <ByLabelView
                    isShowMenuOptions       ={this.state.isShowMenuOptions}
                    isPageVertical          ={this.state.isPageVertical}
                    allGroups               ={this.allGroups}
                    onToggleGroup           ={this.onToggleGroup}
                    onToggleCategory        ={this.onToggleCategory}
                    onCreateWithCategory    ={this.onCreateWithCategory}
                    onSelect                ={this.onSelect}
                />


            </>
        )
    }

    // *************** handle navbar events

    onClickBack = () => {
        goBack();
    }

    onClickCreate = () => {
        PageManager.clearCellId();
        PageManager.goToPage(page.EDIT_INFO);
    }

    onClickSearch = () => {
        goToPage(page.SEARCH);
    }

    onClickMenuOptions = () => {
        this.setState({isShowMenuOptions: !this.state.isShowMenuOptions})
    }

    onClickHome = () => {
        goToPage(page.HOME);
    }

    onClickPaging = () => {
        Notification.snackBarInfo(this.state.isPageVertical ? gTexts.snackbarScrollLeftRight : gTexts.snackbarScrollUpDown);
        this.setState({isPageVertical: !this.state.isPageVertical});
    }

    onClickSettings = () => {
        // PageManager.goToPage(page.SYSTEM);
    }

    onExpandAll = () => {
        Notification.snackBarInfo(this.state.isExpandAll ? gTexts.snackbarCollapseAll : gTexts.snackbarExpandAll);
        this.setState({isExpandAll: !this.state.isExpandAll}, this.udpateExpandAll)
    }

    onSwitchView = () => {
        this.setState({ isShowViews: !this.state.isShowViews})
    }

    // ****************** handle view events

    /**
     * @param {string} groupId 
     */
    onToggleGroup = (groupId) => {
        /**@type {GroupLabel} */ const group = this.allGroups.get(groupId);
        group.isExpanded = !group.isExpanded; // toggle
        this.setState({ allGroups: this.allGroups });    
    }

    /**
     * @param {string} groupId 
     * @param {string} categoryId 
     */
    onToggleCategory = (groupId, categoryId) => {
        /**@type {GroupLabel} */ const group = this.allGroups.get(groupId);
        /**@type {CategoryLevel} */ const category = group.categoryMap.get(categoryId);
        category.isExpanded = !category.isExpanded; // toggle
        this.setState({ allGroups: this.allGroups});
    }
    
    /**
     * when click the create button per category
     * @param {import('react').SyntheticEvent<HTMLButtonElement>} e
     * @param {string} categoryId 
     */
    onCreateWithCategory = (e, categoryId) => {
        e.stopPropagation(); // prevent propagating event to other elements
        PageManager.clearCellId();
        PageManager.setCategoryId(categoryId);
        PageManager.goToPage(page.EDIT_INFO);
    }

    /**
     * on clicking the info name
     * @param {string} id 
     */
    onSelect = (id) => {
        PageManager.setCellId(id);
        PageManager.goToPage(page.EDIT_INFO);
    }

    // ********************* build data model for the view to display

    buildDataModel = () => {
        this.buildGroups();

        const allCells = InfoManager.getAll();
        allCells.forEach((cell) => {
            if (FilterHelper.isPassFilter(cell, Utils.isEmpty(this.state) ? null : this.state.filterFields)) {

                if (!isEmpty(cell.labels)) {
                    cell.labels.forEach( (l) => {
                        const label = LabelManager.getLabel(l);
                        this.buildInfo(cell, label.name);
                    })    
                }
            }
        })
    }

    buildGroups = () => {
        const labels = LabelManager.getAll();
        if (isEmpty(labels)) return;

        sortLabels(labels);
        labels.forEach( (l) => {
            this.createGroup(l);
        })
    }

    /** @param {InfoLabel} label */
    createGroup = (label) => {
        const groupId = this.toId(label.name);
        const group = new GroupLabel(groupId, label);
        // group.groupDesc = label.name;
        this.allGroups.set(groupId, group);
    }

    /**@param {string} labelName */
    toId = (labelName)  => {
        return labelName.replace(/ /g, '_');  // replace all spaces with underscore
    }

    /**
     * @param {InfoCell}  cell 
     * @param {string} labelName
     */
    buildInfo = (cell, labelName) => {
        const categoryId = cell.categoryId;
        const category = CategoryManager.getCategory(categoryId);
        if (isEmpty(category)) {
            logWarn('Cant find category! Missing categoryId', categoryId);
            return;
        }

        // group level
        const groupId = this.toId(labelName);
        /**@type {GroupLabel} */ const groupLevel = this.allGroups.get(groupId);
        if (isEmpty(groupLevel)) {
            logWarn('Cant find group level! Missing categoryId', groupId);
            return;
        }

        // category level
        const categoryMap = groupLevel.categoryMap;
        if (!categoryMap.has(categoryId)) {  // save category only once
            const categoryLevel = new CategoryLevel(category);
            categoryLevel.displayText = ViewHelper.getCategoryDisplayText(category);
            categoryMap.set(categoryId, categoryLevel);
        }
        /**@type {CategoryLevel} */ const categoryLevel = categoryMap.get(categoryId);
        if (isEmpty(categoryLevel)) {
            logWarn('Cant find category level! Missing categoryId', categoryId);
            return;
        }

        // info level
        const infoMap = categoryLevel.infoMap;
        infoMap.set(cell.cellId, new InfoLevel(cell));  // always add info to its own group:category

    }

    updateDataModel = () => {
        this.buildDataModel();  // rebuild allGroups when toggling to show info preview
        this.setState({ allGroups: this.allGroups});
    }

    udpateExpandAll = () => {
        
        this.allGroups.forEach( /**@param {GroupLabel} g */ (g) => {
            g.isExpanded = this.state.isExpandAll;
            g.categoryMap.forEach( /**@param {GroupLabel} c */ (c) => {
                c.isExpanded = this.state.isExpandAll;
            })
        });
        this.setState({allGroups: this.allGroups});
    }

    // ******************************** filter handlers

    /** show filter dialog */
    onClickFilter = () => {
        this.setState({isShowFilter: true});
    }

    /**
     * the handler when filters are set
     * valid values:
     *      null = filter dialog was closed, 
     *      empty fields = filters are cleared
     *      non-empty fields = filters are set
     * 
     * @param {FilterFields}  inFilterFields // 
     */
    onSetFilter = (inFilterFields) => {
        this.setState({isShowFilter: false});  // close the filter
        
        if (Utils.isEmpty(inFilterFields)) return; // do nothing

        // set filter only if there's difference from current state
        if (FilterHelper.isDiff(this.state.filterFields, inFilterFields)) {
            this.setState({filterFields: inFilterFields}, this.updateDataModel);  // rebuild data model 
        }
    }

}

