import React from 'react';

import { SetupCategoryNavbar } from './SetupCategoryNavbar';
import { SetupCategoryView } from './SetupCategoryView'

import { goToPage, goBack } from '../../navigation/PageManager'
import * as PageManager from '../../navigation/PageManager'
import * as page from '../../util/PageConstants'
import * as DataUtil from '../../data/DataUtil';
import { StateManager } from '../../stateManager/StateManager';
import {InfoCategory} from '../../data/InfoCategory'

InfoCategory.toString(); // suppress warning

/**
 * Setup Category Page
 * - container component
 */

/**
 * @typedef {Object} Props
 * 
 * @typedef {Object} State
 * @property { InfoCategory[]} State.allCategories
 * @property {string} lastUpdated
 * 
 */

/** @extends {React.Component<Props, State>} */
export default class SetupCategory extends React.Component {

    /** @param {Props} props */
    constructor(props) {
        super(props);

        /**@type {State} */
        this.state = {
            allCategories: DataUtil.getCategoriesHierarchy(),
            lastUpdated: '',
        };
    }

    componentDidMount() {
        // log ('Setup category did mount');
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.lastUpdated !== StateManager.getData().categories.lastUpdated) {
            /**@type {State} */ const newState = {
                allCategories: DataUtil.getCategoriesHierarchy(),
                lastUpdated: StateManager.getData().categories.lastUpdated,
            }
            this.setState(newState);
        }
    }

    render() {
        // log ('Setup category, render...');
        return (
            <>
                <SetupCategoryNavbar
                    onClickBack={this.onClickBack}
                    onClickCreate={this.onClickCreate}
                    onClickHome={this.onClickHome}
                />
                <SetupCategoryView
                    allCategories={this.state.allCategories}  
                    onSelect={this.onSelect}
                />


            </>
        )
    }

    /**@param {string} id */
    onSelect = (id) => {
        PageManager.setCategoryId(id);
        PageManager.clearImageId();
        PageManager.goToPage(page.EDIT_CATEGORY);
    }

    onClickBack = (e) => {
        goBack();
    }

    onClickCreate = (e) => {
        PageManager.clearCategoryId();
        PageManager.clearImageId();
        PageManager.goToCategoryPage(page.EDIT_CATEGORY);
    }

    onClickHome = (e) => {
        goToPage(page.HOME);
    }


}

