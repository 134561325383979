

export const XSMALL  = '9px';
export const SMALL  = '12px';
export const NORMAL = '16px';
export const LARGE  = '20px';
export const XLARGE = '26px';
export const XXLARGE= '33px';

export const XSMALL_SPACER     = '5px';
export const SMALL_SPACER      = '10px';
export const NORMAL_SPACER     = '20px';
export const WIDE_SPACER       = '25px';
export const XWIDE_SPACER      = '35px';

export const MAX_TEXT   = 5000; // 5,000 characters, 2 pages of text

// media queries

export const SMARTPHONE_SMALL       ='(max-width: 319px)';
export const SMARTPHONE_MEDIUM      ='(min-width: 320px) and (max-width: 479px)';
export const SMARTPHONE_LARGE       ='(min-width: 480px) and (max-width: 767px)';

export const TABLET_SMALL           ='(min-width: 768px) and (max-width: 991px)';
export const TABLET_MEDIUM          ='(min-width: 992px) and (max-width: 1223px)';

export const DESKTOP_LAPTOP         ='(min-width : 1224px)  and (max-width: 1823px)';
export const DESKTOP_LARGE          ='(min-width : 1824px)';

