import React, { useState, useEffect } from 'react'
import styles from './importExport.module.css'
import gStyles from '../../App.module.css'
import { isEmpty } from '../../util/Utils'
import texts from './importExportTexts'

import * as Constants from '../../util/InfoConstants'
import * as PersistenceManager from '../../data/PersistenceManager'
import { Notification } from '../notification/Notification'

function ImportExport() {
  let importRef;
  let exportRef;

  const [exportData, setExportData] = useState('');
  const [exportFileUrl, setExportFileUrl] = useState('');
  const [importSuccess, setImportSuccess] = useState('');
  const [exportSuccess, setExportSuccess] = useState('');
  const [importError, setImportError] = useState('');
  const [exportError, setExportError] = useState('');

  useEffect(() => {
    getExportData(); // for export later

    return () => {
      URL.revokeObjectURL(exportFileUrl);  // release after download
    }
  }, [])

  const uploadFile = (e) => {
    clearMessages();
    Notification.snackBarInfo('Uploading...');
    try {
      // todo: prompt user
      const fileName = e.target.files[0];
      const reader = new FileReader()
      reader.onload = event => {
        const data = event.target.result+""; // convert to string

        const error = validate(data);
        console.log("^^^ | uploadFile | error", error);
        if (error) {
          return;
        }

        // write to localstorage
        localStorage.setItem(Constants.LOGIN_USER.USER_ID, data);
        PersistenceManager.retrieveAll(); // update redux

        setImportSuccess(texts.importSuccess);

      }
      reader.onerror = error => { setImportError(error+"") };
      reader.readAsText(fileName);
    } catch (error) {
      console.log("^^^ | uploadFile | error=", error);
      setImportError(error);
    }
  };

  const validate = (data) => {
    try {
      if (isEmpty(data)) {
        throw Error("Empty data!")
      }
      if (typeof data !== 'string') {
        throw Error("Invalid data type! Data should be string!")
      }
      console.log("^^^ | validate | data", data);
      const parsedData = JSON.parse(data);
      if (parsedData['cells'] && parsedData['categories'] && parsedData['labels']) {
        // todo: further check
      } else {
        throw Error("Invalid data format! Must include fields: cells, categories, labels")
      }
      return '';
    } catch (error) {
      console.log("^^^ | validate | error=", error);
      setImportError(error)
      return error;
    }
  }


  const downloadFile = () => {
    clearMessages();
    Notification.snackBarInfo('Downloading...');
    try {
      if (isEmpty(exportData)) {
        throw Error('Data is empty, nothing to export!')
      }

      exportRef.click(); // trigger download

      setExportSuccess(texts.exportSuccess);
    } catch (error) {
      console.log("^^^ | downloadFile | error=", error)
      setExportError(error);
    }
  };

  const getExportData = () => {
    const data = localStorage.getItem(Constants.LOGIN_USER.USER_ID);
    const file = new Blob([data], { type: "text/plain" });
    const exportFileUrl = URL.createObjectURL(file);
    setExportFileUrl(exportFileUrl);
    setExportData(data);
  }

  const clearMessages = () => {
    setImportError('');
    setExportError('');
    setImportSuccess('');
    setExportSuccess('');
  }

  return (
    <section className={[gStyles.colFlex, gStyles.formSection].join(' ')} >

      <div className={[gStyles.rowFlex, gStyles.formSectionHeader].join(' ')}>
        <span>{texts.importExportHeader}</span>
      </div>

      <div className={[gStyles.colFlex, gStyles.formContent].join(' ')} >
        {/* import */}
        <div className={gStyles.formField}>

          <div className={[gStyles.rowFlex, gStyles.fieldLabel].join(' ')} >
            <span className={styles.titleText}>{texts.importTitle}</span>
          </div>

          <div className={styles.descText}>{texts.importDesc}</div>

          <div className={[gStyles.rowFlex].join(' ')} >
            {/* hidden input to display custom button */}
            <input type="file" onChange={uploadFile} ref={e => importRef = e} style={{ display: 'none' }} />
            {/* trigger click on hidden input to choose file to import */}
            <div onClick={() => importRef.click()} className={gStyles.btnDefault}>{texts.importButton}</div>
          </div>
        </div>
        {importError && <span className={styles.errorText}>{`${texts.importFail} Error: ${importError}`}</span>}
        {importSuccess && <span className={styles.successText}>{importSuccess}</span>}


        {/* export */}
        <div className={gStyles.formField}>
          <div className={[gStyles.rowFlex, gStyles.fieldLabel].join(' ')} >
            <span className={styles.titleText}>{texts.exportTitle}</span>
          </div>

          <div className={styles.descText}>{texts.exportDesc}</div>

          <div className={[gStyles.rowFlex].join(' ')} >
            {/* hidden anchor to display custom button */}
            <a download={Constants.EXPORT_FILENAME} href={exportFileUrl} ref={e => exportRef = e} style={{ display: 'none' }} ></a>
            {/* trigger click on hidden anchor to start download */}
            <div onClick={downloadFile} className={gStyles.btnDefault}>{texts.exportButton}</div>
          </div>

        </div>
        {exportError && <span className={styles.errorText}>{`${texts.exportFail} Error: ${exportError}`}</span>}
        {exportSuccess && <span className={styles.successText}>{exportSuccess}</span>}


      </div>
    </section >
  )
}

export default ImportExport;