

export class Fields {
    /**@type {string} */ defaultCategoryId = '';


}


export class Category {
    /**@type {string} */ id = '';
    /**@type {string} */ name = '';
}



export class ShowDropdown {
    /**@type {boolean} */ category = false;
}


/**
 * alias for the State object to use as type for the component
 */
export class State {
    /**@type {ShowDropdown}*/   showDropdown = new ShowDropdown();
    /**@type {Fields}*/         fields = new Fields();
    /**@type {Category[]}*/     categoryList = [];
}

