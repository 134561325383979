import {StateManager} from '../stateManager/StateManager';
import {ActionType} from '../stateManager/ActionTypes'

import {InfoLabel} from './InfoLabel';
import { logError } from '../util/Utils';

InfoLabel.toString() // suppress warning

export class LabelStoreState {
    /**@type {InfoLabel[]} */ labels = [];
    /**@type {string} */ lastUpdated = '';
}

/** @type {LabelStoreState} */
let initialState = new LabelStoreState();


///////////////// actions

/**
 * @typedef {Object} Action 
 * @property {string} type
 * @property {string} [labelId]
 * @property {InfoLabel} [label]
 * @property {InfoLabel[]} [labels]
 */

  /**
  * 
  * @param {InfoLabel} label 
  * @return {Action}
  */
 const upsertAction = (label) => {
    return {
        type: ActionType.UPSERT_LABEL,
        label: label,
    }
 }

/**
* 
* @param {string} labelId 
* @return {Action}
*/
const deleteAction = (labelId) => {
    return {
        type: ActionType.DELETE_LABEL,
        labelId,
    }
}

 
  /**
  * 
  * @param {InfoLabel[]} labels 
  * @return {Action}
  */
 const insertAllAction = (labels) => {
    return {
        type: ActionType.INSERT_ALL_LABELS,
        labels,
    }
 }

 ///////////////// reducer

 /** 
  * @param {Action} action 
  * @return {LabelStoreState}
  */
export let labelReducer = (state = initialState, action) => {
    switch (action.type) {

        case ActionType.UPSERT_LABEL:
            return {
                labels: upsert(state.labels, action.label),
                lastUpdated: new Date().getTime().toString(),
            }
        case ActionType.DELETE_LABEL:
            return {
                labels: remove(state.labels, action.labelId),
                lastUpdated: new Date().getTime().toString(),
            }
        case ActionType.INSERT_ALL_LABELS:
            return {
                labels: action.labels,   // return all the cells to replace the state
                lastUpdated: new Date().getTime().toString(),
            }
        default:
            return state;
    }
}

/**
 * @param {InfoLabel[]} state - contains the original labels
 * @param {InfoLabel} label
 * @return {InfoLabel[]}
 */
const upsert = (state, label) => {
    /**@type {InfoLabel[]} */
    const allLabels = JSON.parse(JSON.stringify(state)) // deep clone 
    const idx = allLabels.findIndex( (l) => {
        return label.labelId === l.labelId
    });
    if (idx >= 0) {  
        allLabels.splice(idx,1, label); // delete and insert
    } else {
        allLabels.push(label);  // insert
    }
    return allLabels;
}

/**
 * @param {InfoLabel[]} state - contains the original cells
 * @param {string} labelId
 * @return {InfoLabel[]}
 */
const remove = (state, labelId) => {
    /**@type {InfoLabel[]} */
    const allLabels = JSON.parse(JSON.stringify(state)) // deep clone 
    const idx = allLabels.findIndex( (l) => {
        return labelId === l.labelId
    })
    if (idx >= 0) {  
        allLabels.splice(idx,1); // delete
    } else {
        logError(`Error deleting label in state! ${labelId} not found!`);
    }
    return allLabels;
}
/////////////////////////// helper public functions

/**
 * @param {InfoLabel} label
 */
export const upsertLabel = (label) => {
    StateManager.store.dispatch(upsertAction(label));
}

/**
 * @param {string} labelId
 */
export const deleteLabel = (labelId) => {
    StateManager.store.dispatch(deleteAction(labelId));
}

/**
 * @param {InfoLabel[]} labels
 */
export const insertAll = (labels) => {
    StateManager.store.dispatch(insertAllAction(labels));
}