import React from 'react';

import { SearchNavbar } from './ImageSearchNavBar';
import { SearchBar } from './ImageSearchBar';
import { SearchView } from './ImageSearchView'
import { SearchResult } from './ImageSearchResult'

import { goToPage, goBack, goToInfoPage } from '../../navigation/PageManager'
import * as PageManager from '../../navigation/PageManager'
import * as page from '../../util/PageConstants'
import { isEmpty } from '../../util/Utils';

import UserImages, { UserImage } from '../../util/ImageConstants'
// import '../../resources/user-sprites.css'

UserImage.toString();    // suppress warning

/**
 * Search Image Page
 * - container component
 */

/**
 * @typedef {Object} Props
 * 
 * @typedef {Object} State
 * @property {UserImage[]} State.matchImages
 * @property {UserImage[]} State.relatedImages
 * @property {string} State.searchText
 * 
 */

/** @extends {React.Component<Props, State>} */
export default class ImageSearch extends React.Component {

    /** @param {Props} props */
    constructor(props) {
        super(props);

        sortImagesByCategory(UserImages);
        /**@type {UserImage[]} */ this.allImages = UserImages
        /**@type {Map} */ this.groupImages = groupImagesByCategory(UserImages);

        /**@type {State} */
        this.state = {
            matchImages: [],
            relatedImages: [],
            searchText: '',
        };
    }

    componentDidMount() {
        // log ('Image search did mount');
    }
    
    render() {
        return (
            <>
                <SearchNavbar
                    onClickBack={this.onClickBack}
                />
                <SearchBar
                    searchText={this.state.searchText}
                    onInputChange={this.onInputChange}
                    onClear={this.onClear}
                />
                {this.state.searchText ?
                    <SearchResult
                        matchImages={this.state.matchImages}
                        relatedImages = {this.state.relatedImages}
                        searchText={this.state.searchText}
                        onSelect={this.onSelect}
                    />
                    :
                    <SearchView
                        groupImages={this.groupImages}
                        onSelect={this.onSelect}
                    />

                }
            </>
        )
    }

    /**@param {string} id */
    onSelect = (id) => {
        PageManager.setImageId(id);
        PageManager.goBack();
    }

    onClickBack = (e) => {
        goBack();
    }

    onClickCreate = (e) => {
        goToInfoPage(page.EDIT_INFO, page.SEARCH);
    }

    onClickHome = (e) => {
        goToPage(page.HOME);
    }

    /** @param {import('react').SyntheticEvent<HTMLInputElement>} e */
    onInputChange = (e) => {
        const searchText = e.currentTarget.value;
        const matchImages = this.findImages(searchText);
        sortImages(matchImages);
        const relatedImages = this.findRelatedImages(searchText, matchImages);
        sortImages(relatedImages);
        this.setState({ matchImages, relatedImages, searchText })
    }

    onClear = () => {
        this.setState({ searchText: '' })
    }


    /**
     * @param {string} searchText
     * @returns {UserImage[]}
     */
    findImages = (searchText) => {
        if (isEmpty(searchText)) return [];
        const matchImages = UserImages.filter((c) => {
            return (
                c.name.toLowerCase().includes(searchText.toLowerCase())   // .includes() return true for '' string !!!
            )
        });
        return matchImages;

    }

    /**
     * @param {string} searchText
     * @param {UserImage[]} matchImages
     * @returns {UserImage[]}
     */
    findRelatedImages = (searchText, matchImages) => {
        if (isEmpty(searchText)) return [];
        let relatedImages = UserImages.filter((c) => {
            return (
                c.category.toLowerCase().includes(searchText.toLowerCase()) ||
                c.tags.toLowerCase().includes(searchText.toLowerCase())   // .includes() return true for '' string !!!
            )
        });
        // don't include images already matched in the search
        if (!isEmpty(matchImages)) {
            relatedImages = relatedImages.filter((r) => {
                return !matchImages.includes(r);
            })
        }
        return relatedImages;
    }
}

/**@param {UserImage[]} images */
const sortImages = (images) => {
    images.sort( (a,b) => {
        const name1 = a.name.toLowerCase();
        const name2 = b.name.toLowerCase();
        if (name1 < name2) return -1;
        if (name1 > name2) return 1;
        return 0;
    });
}

/**@param {UserImage[]} images */
const sortImagesByCategory = (images) => {
    images.sort( (a,b) => {
        const cat1 = a.category.toLowerCase();
        const cat2 = b.category.toLowerCase();
        if (cat1 < cat2) return -1;
        if (cat1 > cat2) return 1;
        return 0;
    });
}

/**
 * 
 * @param {UserImage[]} images 
 * @returns {Map<string,UserImage[]>}
 */
const groupImagesByCategory = (images) => {
    sortImagesByCategory(images);
    const groupMap = new Map();
    const groups = [];
    let items = [];
    let prevGroup = '-1';
    let isFirst = true;
    images.forEach( (img) => {
        if (prevGroup !== img.category) {
            groups.push(prevGroup);
            if (isFirst) {
                isFirst = false;
            } else {
                groupMap.set(prevGroup, items);
                groups[groups.length-1] = items;  // push the previous items to the previous group
            }
            prevGroup = img.category;
            items = [];
        }
        items.push(img);
    });
    // store the last group of items
    groups.push(prevGroup);
    groups[groups.length-1] = items;
    groupMap.set(prevGroup, items);

    return groupMap;
}

