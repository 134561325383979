import React from 'react'
import gStyles from '../../App.module.css'
import styles from './Exception.module.css'
import texts from './ExceptionText'
import * as icon from '../../util/IconConstants'

/**
 * Global exception handler using error boundaries
 * 
 */
export default class ExceptionHandler extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  // static getDerivedStateFromError(error) {
  //   // Update state so the next render will show the fallback UI.
  //   return { hasError: true };
  // }

  /** error boundary */
  componentDidCatch(error, errorInfo) {

    console.error('MyBrainMate Info - Global Component Error: ', error);
    console.error('MyBrainMate Info - Stacktrace: ', errorInfo);

    this.setState({ error, errorInfo });
  }

  onBack = () => {
    window.history.back();
  }

  onRefresh = () => {
    window.location.reload();
  }

  onHome = () => {
    window.location.pathname = '/';
  }

  onEmail = () => {
    window.open('mailto:info@lunaresoft.com');
  }

  render() {

    // show error page
    if (this.state.error) {
      return (
        <main>

          {/* header bar */}
          <nav className={[gStyles.rowFlex, gStyles.navBar].join(' ')}>
            <span style={{ paddingLeft: '20px' }}>{texts.pageTitle}</span>
          </nav>

          <div className={styles.exceptionContainer}>

            <div className={[styles.exceptionHeader].join(' ')}>
              <div className={[gStyles.rowNowrapFlex].join(' ')} >
                <span className={[icon.ERROR_EXPLOSION, gStyles.btnIcon].join(' ')}  style={{minWidth:'48px', marginRight:'10px'}}></span>
                <span className={styles.exceptionTitle}> {texts.exceptionTitle} </span>
              </div>
            </div>
            <div className={styles.exceptionContent}>{texts.exceptionContent}</div>

            {/* error details */}
            <details>
              <summary className={styles.exceptionDetails}>{texts.exceptionDetails}</summary>
              <div className={styles.errorDetails}>
                {this.state.error.toString()}
              </div>
            </details>

            {/* recovery */}
            <div className={styles.recoveryHeader}>{texts.recoveryHeader}</div>

            <div className={styles.recoverySection}>

              <div className={styles.recoveryLine}>
                {`${texts.recoveryBack}  - `}
                <span className={gStyles.btnText} onClick={this.onBack}>{texts.linkBack}</span>
              </div>

              <div className={styles.recoveryLine}>
                {`${texts.recoveryRefresh}  - `}
                <span className={gStyles.btnText} onClick={this.onRefresh}>{texts.linkRefresh}</span>
              </div>

              <div className={styles.recoveryLine}>
                {`${texts.recoveryHome}  - `} 
                <span className={gStyles.btnText} onClick={this.onHome}>{texts.linkHome}</span>
              </div>

              <div className={styles.recoveryLine}>
                {`${texts.recoveryEmail}  - `} 
                <span className={gStyles.btnText} onClick={this.onEmail}>{texts.linkEmail}</span>
              </div>

            </div>

          </div>
        </main>
      )
    }

    return this.props.children;  // return children components if there's no error
  }
}