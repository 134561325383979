export default {

    searchHint: 'Search...',
    searchTotal: 'Total: ',
    noCategories: `No categories, you can click the '+' button to create...`,
    noMatchTitle: `Oops, no images found!`,
    noMatchSubtexts1: `Try these search tips:`,
    noMatchSubtexts2: `Check your spelling, broaden your search by fewer or general words`,
    noExactMatch: 'Argh! no exact match found!',
    noExactMatchSubtexts: 'But you can choose from the related images below...',
    relatedImages: 'Related Images',
}
